/* eslint-disable react/style-prop-object */
import React, { useMemo } from "react";
import { Spin, Flex, Divider } from "antd";
import { useQuery, useReadQuery } from "@apollo/client";
import { openErrorNotification } from "../../../utils/Notification";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import moment from "moment";
import { ReportQueries } from "../../../graphql";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";

const { GET_PROFIT_AND_LOSS_REPORT } = ReportQueries;

const ProfitAndLoss = () => {
  const { notiApi, business, allBranchesQueryRef } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const reportBasisParam = searchParams.get("reportBasis");
  const periodParam = searchParams.get("period");
  const accountIdParam = searchParams.get("accountId");

  const fromDate = fromDateParam
    ? moment(fromDateParam).utc()
    : REPORT_DEFAULTS.fromDate;
  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const branchId = branchIdParam ? parseInt(branchIdParam) : 0;
  const reportBasis = reportBasisParam || "Accrual";
  const period = periodParam || "thisMonth";
  const accountIds = accountIdParam
    ? accountIdParam.split(",").map((id) => parseInt(id, 10))
    : [];

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_PROFIT_AND_LOSS_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      fromDate: fromDate.format(DATE_STRING_FORMAT),
      toDate: toDate.format(DATE_STRING_FORMAT),
      branchId,
      reportType: "Accrual",
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const queryData = useMemo(() => data, [data]);

  // !queryLoading && console.log(queryData);

  const operatingIncome =
    queryData?.getProfitAndLossReport[0]?.plAccountGroups.find(
      (group) => group.groupType === "Operating Income"
    );
  const costOfGoodsSold =
    queryData?.getProfitAndLossReport[0]?.plAccountGroups.find(
      (group) => group.groupType === "Cost Of Goods Sold"
    );
  const operatingExpense =
    queryData?.getProfitAndLossReport[0]?.plAccountGroups.find(
      (group) => group.groupType === "Operating Expense"
    );
  const noneOperatingIncome =
    queryData?.getProfitAndLossReport[0]?.plAccountGroups.find(
      (group) => group.groupType === "Non Operating Income"
    );
  const noneOperatingExpense =
    queryData?.getProfitAndLossReport[0]?.plAccountGroups.find(
      (group) => group.groupType === "Non Operating Expense"
    );

  const { data: branchData } = useReadQuery(allBranchesQueryRef);

  const branch = useMemo(() => {
    return branchData?.listAllBranch?.find((w) => w.id === branchId);
  }, [branchData, branchId]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          filterConfig={{
            branch: true,
          }}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>Profit and Loss</h3>
            {/* <span>Basis: Accrual</span> */}
            {branch && <span>Branch: {branch?.name}</span>}
            <h5>
              From {fromDate.format(REPORT_DATE_FORMAT)} To{" "}
              {toDate.format(REPORT_DATE_FORMAT)}
            </h5>
          </div>
        </div>
        {queryLoading ? (
          <Flex justify="center" align="center" style={{ height: "40vh" }}>
            <Spin size="large" />
          </Flex>
        ) : (
          <div className="fill-container table-container">
            <table className="financial-comparison rep-table tb-comparison-table table-no-border ">
              <thead>
                <tr>
                  <th className="text-align-left" style={{ width: "420px" }}>
                    <span>
                      <FormattedMessage
                        id="report.account"
                        defaultMessage="Account"
                      />
                    </span>
                  </th>
                  <th></th>
                  <th className="text-align-right" style={{ width: "176px" }}>
                    <FormattedMessage
                      id="report.total"
                      defaultMessage="Total"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="mute-hover">
                  <td colSpan="2">
                    <b>Operating Income</b>
                  </td>
                </tr>
                {operatingIncome?.accounts?.map((acc) => (
                  <tr key={acc.accountName}>
                    <td style={{ paddingLeft: "4.5rem" }}>
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {acc.accountName}
                      </Link>
                    </td>
                    <td></td>
                    <td className="text-align-right">
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedNumber
                          value={acc.amount}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr className="mute-hover">
                  <td>
                    <b>Total for Operating Income</b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    {operatingIncome?.total ? (
                      <FormattedNumber
                        value={operatingIncome?.total}
                        style="decimal"
                        minimumFractionDigits={
                          business.baseCurrency.decimalPlaces
                        }
                      />
                    ) : (
                      "0"
                    )}
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="2">
                    <b>Cost of Goods Sold</b>
                  </td>
                </tr>
                {costOfGoodsSold?.accounts?.map((acc) => (
                  <tr key={acc.accountName}>
                    <td style={{ paddingLeft: "4.5rem" }}>
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {acc.accountName}
                      </Link>
                    </td>
                    <td></td>
                    <td className="text-align-right">
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedNumber
                          value={acc.amount}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr className="mute-hover">
                  <td>
                    <b>Total for Cost Of Goods Sold</b>
                  </td>
                  <td></td>
                  <td className="text-align-right">0</td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td></td>
                  <td>
                    <b>
                      <FormattedMessage
                        id="report.grossProfit"
                        defaultMessage="Gross Profit"
                      />
                    </b>
                  </td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={queryData?.getProfitAndLossReport[0]?.grossProfit}
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>

                <tr className="mute-hover">
                  <td colSpan="2">
                    <b>Operating Expense</b>
                  </td>
                </tr>
                {operatingExpense?.accounts?.map((acc) => (
                  <tr key={acc.accountName}>
                    <td style={{ paddingLeft: "4.5rem" }}>
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {acc.accountName}
                      </Link>
                    </td>
                    <td></td>
                    <td className="text-align-right">
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&accountId=${acc.accountId}`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedNumber
                          value={acc.amount}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr className="mute-hover">
                  <td>
                    <b>Total for Operating Expense</b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={operatingExpense?.total || 0}
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td></td>
                  <td>
                    <b>
                      <FormattedMessage
                        id="report.operatingProfit"
                        defaultMessage="Operating Profit"
                      />
                    </b>
                  </td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={
                        queryData?.getProfitAndLossReport[0]?.operatingProfit
                      }
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>

                <tr className="mute-hover">
                  <td colSpan="2">
                    <b>Non Operating Income</b>
                  </td>
                </tr>
                {noneOperatingIncome?.accounts?.map((acc) => (
                  <tr key={acc.accountName}>
                    <td style={{ paddingLeft: "4.5rem" }}>
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {acc.accountName}
                      </Link>
                    </td>
                    <td></td>
                    <td className="text-align-right">
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&accountId=${acc.accountId}`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedNumber
                          value={acc.amount}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr className="mute-hover">
                  <td>
                    <b>Total for Non Operating Income</b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={noneOperatingIncome || 0}
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>

                <tr className="mute-hover">
                  <td colSpan="2">
                    <b>Non Operating Expense</b>
                  </td>
                </tr>
                {noneOperatingExpense?.accounts?.map((acc) => (
                  <tr key={acc.accountName}>
                    <td style={{ paddingLeft: "4.5rem" }}>
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&searchCriteria=account%3D${
                            acc.accountId
                          }`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {acc.accountName}
                      </Link>
                    </td>
                    <td></td>
                    <td className="text-align-right">
                      <Link
                        to={{
                          pathname: "/reports/accountTransactions",
                          search: `?reportBasis=${reportBasis}&period=${period}&fromDate=${
                            fromDateParam
                              ? fromDateParam
                              : REPORT_DEFAULTS.fromDate
                          }&toDate=${
                            toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                          }&branchId=${branchId}&accountId=${acc.accountId}`,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedNumber
                          value={acc.amount}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </Link>
                    </td>
                  </tr>
                ))}
                <tr className="mute-hover">
                  <td>
                    <b>Total for Non Operating Expense</b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={noneOperatingExpense?.total || 0}
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>

                <tr className="mute-hover">
                  <td></td>
                  <td>
                    <b>
                      <FormattedMessage
                        id="report.netProfitLoss"
                        defaultMessage="Net Profit/Loss"
                      />
                    </b>
                  </td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={queryData?.getProfitAndLossReport[0]?.netProfit}
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div style={{ paddingLeft: "1.5rem" }}>
          <FormattedMessage
            values={{ currency: business.baseCurrency.symbol }}
            id="label.displayedBaseCurrency"
            defaultMessage="**Amount is displayed in {currency}"
          />
        </div>
      </div>
    </ReportLayout>
  );
};

export default ProfitAndLoss;
