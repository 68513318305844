/* eslint-disable react/style-prop-object */
import React, { useCallback, useMemo, useState } from "react";
import {
  Spin,
  Flex,
  Divider,
  Select,
  Space,
  Button,
  Tooltip,
  Row,
  Col,
  Form,
} from "antd";
import {
  FilterOutlined,
  PlusCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import { useQuery, useReadQuery } from "@apollo/client";
import { openErrorNotification } from "../../../utils/Notification";
import { useOutletContext, Link, useSearchParams } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import moment from "moment";
import { ReportQueries } from "../../../graphql";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { PeriodDropdown, ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";

const { GET_BALANCE_SHEET_REPORT } = ReportQueries;

const filterFields = [
  {
    label: "Branches",
    options: [{ label: "Branch", value: "branch" }],
  },
  {
    label: "Reports",
    options: [{ label: "Account", value: "account" }],
  },
];

const BalanceSheet = () => {
  const { notiApi, business } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { allBranchesQueryRef, allWarehousesQueryRef, allAccountsQueryRef } =
    useOutletContext();
  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const warehouseIdParam = searchParams.get("warehouseId");
  const reportBasisParam = searchParams.get("reportBasis");
  const periodParam = searchParams.get("period");

  const [filters, setFilters] = useState([{ key: Date.now() }]);
  const [moreFilterOpen, setMoreFilterOpen] = useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(
    REPORT_DEFAULTS.branchId
  );
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(
    REPORT_DEFAULTS.warehouseId
  );
  const [selectedFromDate, setSelectedFromDate] = useState(
    REPORT_DEFAULTS.fromDate
  );
  const [selectedToDate, setSelectedToDate] = useState(REPORT_DEFAULTS.toDate);
  const [selectedReportBasis, setSelectedReportBasis] = useState(
    REPORT_DEFAULTS.reportBasis
  );
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(REPORT_DEFAULTS.period);

  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const branchId = branchIdParam
    ? parseInt(branchIdParam)
    : REPORT_DEFAULTS.branchId;
  const reportBasis = reportBasisParam
    ? reportBasisParam
    : REPORT_DEFAULTS.reportBasis;
  const period = periodParam || REPORT_DEFAULTS.period;

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_BALANCE_SHEET_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      toDate: toDate.format(DATE_STRING_FORMAT),
      branchId,
      reportType: reportBasis,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const queryData = useMemo(() => data, [data]);

  // Queries
  const { data: branchData } = useReadQuery(allBranchesQueryRef);
  const { data: warehouseData } = useReadQuery(allWarehousesQueryRef);
  const { data: accountData } = useReadQuery(allAccountsQueryRef);
  const branches = useMemo(() => {
    const activeBranches =
      branchData?.listAllBranch?.filter((branch) => branch.isActive === true) ||
      [];

    return [{ id: 0, name: "All Branches" }, ...activeBranches];
  }, [branchData]);

  const accounts = useMemo(() => {
    return accountData?.listAllAccount?.filter(
      (account) => account.isActive === true
    );
  }, [accountData]);

  const warehouses = useMemo(() => {
    const activeWarehouses =
      warehouseData?.listAllWarehouse?.filter((warehouse) => {
        if (selectedBranchId === 0) {
          return warehouse.isActive === true;
        }
        return (
          warehouse.isActive === true &&
          warehouse.selectedBranchId === selectedBranchId
        );
      }) || [];

    return [{ id: 0, name: "All Warehouses" }, ...activeWarehouses];
  }, [warehouseData, selectedBranchId]);

  const handleGenerateReport = useCallback(() => {
    const newParams = {
      reportBasis: selectedReportBasis,
      period: selectedPeriod,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      branchId: selectedBranchId,
      warehouseId: selectedWarehouseId,
    };

    setSearchParams(new URLSearchParams(newParams));
    setIsButtonDisabled(true);
  }, [
    selectedReportBasis,
    selectedPeriod,
    selectedFromDate,
    selectedToDate,
    selectedBranchId,
    selectedWarehouseId,
    setSearchParams,
  ]);

  const handleBranchChange = useCallback((value) => {
    setSelectedBranchId(value);
    setIsButtonDisabled(false);
    setSelectedWarehouseId(0);
  }, []);

  const handleWarehouseChange = useCallback((value) => {
    setSelectedWarehouseId(value);
    setIsButtonDisabled(false);
  }, []);

  useMemo(() => {
    setSelectedBranchId(
      branchIdParam ? parseInt(branchIdParam, 10) : REPORT_DEFAULTS.branchId
    );
    setSelectedWarehouseId(
      warehouseIdParam
        ? parseInt(warehouseIdParam, 10)
        : REPORT_DEFAULTS.warehouseId
    );
    setSelectedFromDate(
      fromDateParam ? fromDateParam : REPORT_DEFAULTS.fromDate
    );
    setSelectedToDate(toDateParam ? toDateParam : REPORT_DEFAULTS.toDate);
    setSelectedReportBasis(reportBasisParam || REPORT_DEFAULTS.reportBasis);
    setSelectedPeriod(periodParam || REPORT_DEFAULTS.period);
  }, [
    branchIdParam,
    warehouseIdParam,
    fromDateParam,
    toDateParam,
    reportBasisParam,
    periodParam,
  ]);

  const addFilter = () => {
    setFilters([...filters, { key: Date.now() }]);
  };

  const removeFilter = (key) => {
    setFilters(filters.filter((filter) => filter.key !== key));
  };

  const filterConfig = {
    branch: true,
    more: {
      enabled: false,
      filterFields: [
        {
          label: "Accounts",
          options: [{ label: "Account", value: "account" }],
          data: accounts,
          multiSelect: true,
        },
      ],
    },
  };

  const branch = useMemo(() => {
    return branchData?.listAllBranch?.find((w) => w.id === branchId);
  }, [branchData, branchId]);

  return (
    <ReportLayout>
      <div className="report">
        {/* <div className="report-filter-bar">
          <Space size="large">
            <Space>
              <span>
                <FilterOutlined />
              </span>
              <span>
                <FormattedMessage
                  id="label.filters"
                  defaultMessage="Filters: "
                />
              </span>
            </Space>
            <Space>
              <PeriodDropdown
                refetch={refetch}
                hasFromDate={false}
                setFromDate={setSelectedFromDate}
                setToDate={setSelectedToDate}
                setIsButtonDisabled={setIsButtonDisabled}
                setSelectedPeriod={setSelectedPeriod}
                selectedPeriod={selectedPeriod}
                period={period}
                fromDate={selectedFromDate}
                toDate={selectedToDate}
              />

              <Select
                className="report-filter-select"
                style={{ height: "2rem" }}
                optionFilterProp="label"
                value={selectedBranchId}
                onChange={handleBranchChange}
              >
                {branches?.map((branch) => (
                  <Select.Option
                    key={branch.id}
                    value={branch.id}
                    label={branch.name}
                  >
                    {branch.name}
                  </Select.Option>
                ))}
              </Select>

              <Select
                className="report-filter-select"
                style={{ height: "2rem" }}
                optionFilterProp="label"
                value={selectedWarehouseId}
                onChange={handleWarehouseChange}
              >
                {warehouses?.map((warehouse) => (
                  <Select.Option
                    key={warehouse.id}
                    value={warehouse.id}
                    label={warehouse.name}
                  >
                    {warehouse.name}
                  </Select.Option>
                ))}
              </Select>
              <Button
                onClick={() => setMoreFilterOpen(!moreFilterOpen)}
                icon={
                  <span>
                    <PlusCircleFilled
                      style={{ color: "var(--primary-color)" }}
                    />
                  </span>
                }
              >
                <span>More Filters</span>
              </Button>
              {!moreFilterOpen && (
                <Tooltip
                  title={
                    isButtonDisabled ? (
                      <FormattedMessage
                        id="button.modifyFiltersToGenerateReport"
                        defaultMessage="Modify filters to generate report"
                      />
                    ) : (
                      <FormattedMessage
                        id="button.generateReportToApplyFilters"
                        defaultMessage="Generate report to apply filters"
                      />
                    )
                  }
                >
                  <Button
                    type="primary"
                    onClick={() => handleGenerateReport()}
                    disabled={isButtonDisabled}
                    loading={queryLoading}
                  >
                    <FormattedMessage
                      id="button.generateReport"
                      defaultMessage="Generate Report"
                    />
                  </Button>
                </Tooltip>
              )}
            </Space>
          </Space>
          <div className={`more-filters ${moreFilterOpen ? "open" : ""}`}>
            <div className="more-filters-body">
              <div style={{ width: "80%" }}>
                <Form>
                  {filters.map((filter, index) => (
                    <Row gutter={16} key={filter.key}>
                      <div className="more-filters-select-addon">
                        {index + 1}
                      </div>
                      <Col lg={4} style={{ paddingLeft: 0 }}>
                        <Form.Item>
                          <Select
                            allowClear
                            className="report-filter-select custom-filter-select"
                            placeholder="Select a field"
                            options={filterFields}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={4}>
                        <Form.Item>
                          <Select
                            allowClear
                            className="report-filter-select"
                            placeholder="Select a comparator"
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col lg={6}>
                        <Form.Item>
                          <Select
                            className="report-filter-select"
                            allowClear
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col lg={1} className="more-filters-delete">
                        <DeleteOutlined
                          className="delete-icon"
                          onClick={() => removeFilter(filter.key)}
                        />
                      </Col>
                    </Row>
                  ))}
                </Form>
                <Button
                  type="link"
                  icon={<PlusCircleFilled />}
                  style={{ padding: 0 }}
                  onClick={addFilter}
                >
                  Add Filter
                </Button>
              </div>
            </div>
            <div className="more-filters-footer">
              <Space>
                <Button
                  className={moreFilterOpen ? "generate-report-btn" : ""}
                  type="primary"
                  onClick={() => {
                    handleGenerateReport();
                    setMoreFilterOpen(false);
                  }}
                  loading={queryLoading}
                >
                  <FormattedMessage
                    id="button.generateReport"
                    defaultMessage="Generate Report"
                  />
                </Button>
                <Button onClick={() => setMoreFilterOpen(false)}>
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                </Button>
              </Space>
            </div>
          </div>
        </div> */}
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          loading={queryLoading}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          filterConfig={filterConfig}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>Balance Sheet</h3>
            {/* <span>Basis: Accrual</span> */}
            {branch && <span>Branch: {branch?.name}</span>}
            <h5>As of {toDate.format(REPORT_DATE_FORMAT)}</h5>
          </div>
        </div>
        {queryLoading ? (
          <Flex justify="center" align="center" style={{ height: "40vh" }}>
            <Spin size="large" />
          </Flex>
        ) : (
          <div className="fill-container table-container">
            <table className="financial-comparison rep-table tb-comparison-table table-no-border ">
              <thead>
                <tr>
                  <th className="text-align-left" style={{ width: "420px" }}>
                    <span>
                      <FormattedMessage
                        id="report.account"
                        defaultMessage="Account"
                      />
                    </span>
                  </th>
                  <th className="text-align-right" style={{ width: "176px" }}>
                    <FormattedMessage
                      id="report.total"
                      defaultMessage="Total"
                    />
                  </th>
                </tr>
              </thead>
              {queryData?.getBalanceSheetReport?.map((data) => (
                <tbody key={data.mainType}>
                  <tr className="mute-hover">
                    <td colSpan="2">
                      <b>{data.mainType}</b>
                    </td>
                  </tr>
                  {data.accounts.map((groupAcc) => (
                    <React.Fragment key={groupAcc.groupType}>
                      <tr className="mute-hover">
                        <td colSpan="2" style={{ paddingLeft: "3.5rem" }}>
                          <b>{groupAcc.groupType}</b>
                        </td>
                      </tr>
                      {groupAcc.accounts.map((subAcc) => (
                        <React.Fragment key={subAcc.subType}>
                          <tr className="mute-hover">
                            <td colSpan="2" style={{ paddingLeft: "4.5rem" }}>
                              <b>{subAcc.subType}</b>
                            </td>
                          </tr>
                          {subAcc.accounts.map((acc) => {
                            return (
                              <React.Fragment key={acc.accountName}>
                                <tr
                                  className={
                                    acc.accountName === "Retained Earnings"
                                      ? "mute-hover"
                                      : ""
                                  }
                                >
                                  <td style={{ paddingLeft: "5.5rem" }}>
                                    {acc.accountName !== "Retained Earnings" ? (
                                      <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={{
                                          pathname:
                                            acc.accountName ===
                                            "Current Year  Earnings"
                                              ? "/reports/profitAndLoss"
                                              : "/reports/accountTransactions",
                                          search: `?reportBasis=${reportBasis}&period=${
                                            acc.accountName ===
                                            "Current Year  Earnings"
                                              ? "thisYear"
                                              : "custom"
                                          }&fromDate=${moment()
                                            .startOf("year")
                                            .utc(true)}&toDate=${
                                            acc.accountName ===
                                            "Current Year  Earnings"
                                              ? moment().endOf("year").utc(true)
                                              : toDateParam
                                              ? toDateParam
                                              : REPORT_DEFAULTS.toDate
                                          }&branchId=${branchId}&searchCriteria=account%3D${
                                            acc.accountId
                                          }`,
                                        }}
                                      >
                                        {acc.accountName}
                                      </Link>
                                    ) : (
                                      acc.accountName
                                    )}
                                  </td>
                                  <td className="text-align-right">
                                    {acc.accountName !== "Retained Earnings" ? (
                                      <Link
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={{
                                          pathname:
                                            acc.accountName ===
                                            "Current Year Earnings"
                                              ? "/reports/profitAndLoss"
                                              : "/reports/accountTransactions",
                                          search: `?reportBasis=${reportBasis}&period=custom&fromDate=${moment(
                                            toDate
                                          )
                                            .startOf("year")
                                            .utc(true)}&toDate=${
                                            toDateParam
                                              ? toDateParam
                                              : REPORT_DEFAULTS.toDate
                                          }&branchId=${branchId}&searchCriteria=account%3D${
                                            acc.accountId
                                          }`,
                                        }}
                                      >
                                        <FormattedNumber
                                          value={acc.amount}
                                          style="decimal"
                                          minimumFractionDigits={
                                            business.baseCurrency.decimalPlaces
                                          }
                                        />
                                      </Link>
                                    ) : (
                                      <FormattedNumber
                                        value={acc.amount}
                                        style="decimal"
                                        minimumFractionDigits={
                                          business.baseCurrency.decimalPlaces
                                        }
                                      />
                                    )}
                                  </td>
                                </tr>
                              </React.Fragment>
                            );
                          })}
                          <tr className="mute-hover">
                            <td style={{ paddingLeft: "4.5rem" }}>
                              <b>Total for {subAcc.subType}</b>
                            </td>
                            <td className="text-align-right">
                              <FormattedNumber
                                value={subAcc.total}
                                style="decimal"
                                minimumFractionDigits={
                                  business.baseCurrency.decimalPlaces
                                }
                              />
                            </td>
                          </tr>
                          <tr className="mute-hover">
                            <td colSpan="2" style={{ padding: 0 }}>
                              <Divider style={{ margin: 0 }} />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                      <tr className="mute-hover">
                        <td style={{ paddingLeft: "3.5rem" }}>
                          <b>Total for {groupAcc.groupType}</b>
                        </td>
                        <td className="text-align-right">
                          <FormattedNumber
                            value={groupAcc.total}
                            style="decimal"
                            minimumFractionDigits={
                              business.baseCurrency.decimalPlaces
                            }
                          />
                        </td>
                      </tr>
                      <tr className="mute-hover">
                        <td colSpan="2" style={{ padding: 0 }}>
                          <Divider style={{ margin: 0 }} />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr className="mute-hover">
                    <td>
                      <b>Total for {data.mainType}</b>
                    </td>
                    <td className="text-align-right">
                      <FormattedNumber
                        value={data.total}
                        style="decimal"
                        minimumFractionDigits={
                          business.baseCurrency.decimalPlaces
                        }
                      />
                    </td>
                  </tr>
                  <tr className="mute-hover">
                    <td colSpan="2" style={{ padding: 0 }}>
                      <Divider style={{ margin: 0 }} />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        )}
        <div style={{ paddingLeft: "1.5rem" }}>
          <FormattedMessage
            values={{ currency: business.baseCurrency.symbol }}
            id="label.displayedBaseCurrency"
            defaultMessage="**Amount is displayed in {currency}"
          />
        </div>
      </div>
    </ReportLayout>
  );
};

export default BalanceSheet;
