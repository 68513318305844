import React, { useState, useMemo } from "react";
import { Button, Table, Dropdown, Tag, Modal, Form, Input, Select } from "antd";
import { PlusOutlined, DownCircleFilled } from "@ant-design/icons";
import { useQuery, useMutation, useReadQuery } from "@apollo/client";
import {
  openErrorNotification,
  openSuccessMessage,
} from "../../utils/Notification";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { RoleMutations, RoleQueries } from "../../graphql";

const { GET_ROLES } = RoleQueries;
const { DELETE_ROLE, TOGGLE_ACTIVE_ROLE } = RoleMutations;

const Roles = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [deleteModal, contextHolder] = Modal.useModal();
  const { notiApi, msgApi } = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  // Queries and mutations
  const { data, loading: queryLoading } = useQuery(GET_ROLES, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const [deleteRole, { loading: deleteLoading }] = useMutation(DELETE_ROLE, {
    onCompleted() {
      openSuccessMessage(
        msgApi,
        <FormattedMessage id="role.deleted" defaultMessage="Role Deleted" />
      );
    },
    refetchQueries: [GET_ROLES],
  });

  // const [toggleActiveRole, { loading: toggleActiveLoading }] = useMutation(
  //   TOGGLE_ACTIVE_ROLE,
  //   {
  //     onCompleted() {
  //       openSuccessMessage(
  //         msgApi,
  //         <FormattedMessage
  //           id="role.updated.status"
  //           defaultMessage="Role Status Updated"
  //         />
  //       );
  //     },
  //     // refetchQueries: [GET_ROLE_ACCOUNTS],
  //   }
  // );

  // Derived data
  const parsedData = useMemo(() => {
    return data?.listRole?.map((item) => ({
      ...item,
    }));
  }, [data]);

  const loading = queryLoading || deleteLoading;

  const handleDelete = async (record) => {
    // console.log("delete", record.id);
    const confirmed = await deleteModal.confirm({
      content: (
        <FormattedMessage
          id="confirm.delete"
          defaultMessage="Are you sure to delete?"
        />
      ),
    });
    if (confirmed) {
      try {
        await deleteRole({
          variables: {
            id: record.id,
          },
        });
      } catch (err) {
        openErrorNotification(notiApi, err.message);
      }
    }
  };

  const handleEdit = (record) => {
    navigate("edit", {
      state: {
        ...location.state,
        from: { pathname: location.pathname },
        record: record,
      },
    });
  };

  // const handleToggleActive = async (record) => {
  //   try {
  //     await toggleActiveRole({
  //       variables: { id: record.id, isActive: !record.isActive },
  //     });
  //   } catch (err) {
  //     openErrorNotification(notiApi, err.message);
  //   }
  // };

  const columns = [
    {
      title: (
        <FormattedMessage id="label.roleName" defaultMessage="Role Name" />
      ),
      dataIndex: "name",
      key: "name",
      width: "23%",
      render: (text, record) => (
        <>
          {text}
          {/* {!record.isActive ? (
            <Tag className="active-status">
              <FormattedMessage id="label.inactive" defaultMessage="inactive" />
            </Tag>
          ) : (
            <></>
          )} */}
        </>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      width: "5%",
      render: (_, record) =>
        hoveredRow === record.id ? (
          <Dropdown
            loading={loading}
            trigger="click"
            key={record.key}
            menu={{
              onClick: ({ key }) => {
                if (key === "1") handleEdit(record);
                // else if (key === "2") handleToggleActive(record);
                else if (key === "3") handleDelete(record);
              },
              items: [
                {
                  label: (
                    <FormattedMessage id="button.edit" defaultMessage="Edit" />
                  ),
                  key: "1",
                },
                // {
                //   label: !record.isActive ? (
                //     <FormattedMessage
                //       id="button.markActive"
                //       defaultMessage="Mark As Active"
                //     />
                //   ) : (
                //     <FormattedMessage
                //       id="button.markInactive"
                //       defaultMessage="Mark As Inactive"
                //     />
                //   ),
                //   key: "2",
                // },
                {
                  label: (
                    <FormattedMessage
                      id="button.delete"
                      defaultMessage="Delete"
                    />
                  ),
                  key: "3",
                },
              ],
            }}
          >
            <DownCircleFilled className="action-icon" />
          </Dropdown>
        ) : (
          <div className="action-placeholder"></div>
        ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div className="page-header page-header-with-button">
        <p className="page-header-text">
          <FormattedMessage id="menu.roles" defaultMessage="Roles" />
        </p>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() =>
            navigate("new", {
              state: {
                ...location.state,
                from: { pathname: location.pathname },
              },
            })
          }
        >
          <span>
            <FormattedMessage id="role.new" defaultMessage="New Role" />
          </span>
        </Button>
      </div>
      <div className="page-content">
        <Table
          className="main-table"
          loading={loading}
          columns={columns}
          dataSource={parsedData?.map((item) => ({ ...item, key: item.id }))}
          pagination={false}
          onRow={(record) => ({
            key: record.id,
            onMouseEnter: () => setHoveredRow(record.id),
            onMouseLeave: () => setHoveredRow(null),
          })}
        ></Table>
      </div>
    </>
  );
};

export default Roles;
