import { gql } from "@apollo/client";

const GET_OPENING_BALANCE = gql`
  query GetOpeningBalance($branchId: Int!) {
    getOpeningBalance(branchId: $branchId) {
      id
      businessId
      migrationDate
      branch {
        id
        name
      }
      details {
        id
        openingBalanceId
        debit
        credit
        account {
          id
          name
          detailType
          mainType
          isActive
        }
      }
    }
  }
`;

const OpeningBalanceQueries = {
  GET_OPENING_BALANCE,
};

export default OpeningBalanceQueries;
