import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Form,
  Input,
  Spin,
  Table,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { RoleQueries, RoleMutations } from "../../graphql";
import {
  openErrorNotification,
  openSuccessMessage,
} from "../../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import "./Roles.css";
const { GET_MODULES } = RoleQueries;
const { CREATE_ROLE } = RoleMutations;

const RolesNew = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const { notiApi, msgApi } = useOutletContext();
  const [moduleLoading, setModuleLoading] = useState(false);

  //Queries
  const { data: moduleData, loading } = useQuery(GET_MODULES, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  // Mutations
  const [createRole, { loading: createLoading }] = useMutation(CREATE_ROLE, {
    onCompleted() {
      openSuccessMessage(
        msgApi,
        <FormattedMessage id="role.created" defaultMessage="New Role Created" />
      );
      navigate(from, { state: location.state, replace: true });
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const modules = useMemo(() => {
    const reformattedData = [];
    setModuleLoading(true);
    moduleData?.listModule?.forEach((baseModule) => {
      if (baseModule.baseModuleName.startsWith("Reports:")) {
        const reportGroupName = baseModule.baseModuleName.split(":")[1].trim();

        let reportGroup = reformattedData.find(
          (group) => group.baseModuleName === "Reports"
        );

        if (!reportGroup) {
          reportGroup = {
            baseModuleName: "Reports",
            reports: [],
          };
          reformattedData.push(reportGroup);
        }

        let specificReportGroup = reportGroup.reports.find(
          (report) => report.baseModuleName === reportGroupName
        );

        if (!specificReportGroup) {
          specificReportGroup = {
            baseModuleName: reportGroupName,
            modules: [],
          };
          reportGroup.reports.push(specificReportGroup);
        }
        if (baseModule.modules && baseModule.modules.length > 0) {
          specificReportGroup.modules.push(...baseModule.modules);
        }
        setModuleLoading(false);
      } else {
        reformattedData.push(baseModule);
        setModuleLoading(false);
      }
    });

    return reformattedData;
  }, [moduleData]);

  console.log("role modules", modules);

  const formatModuleName = (name) => {
    return name
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();

      const allowedModules = modules.flatMap((group) => {
        // check if the group is the "Reports" group
        if (group.baseModuleName === "Reports") {
          // map through the reports within the "Reports" group
          return group.reports.flatMap((report) =>
            report.modules
              ?.map((module) => {
                const read = values[`read-${module.id}`];
                const create = values[`create-${module.id}`];
                const update = values[`update-${module.id}`];
                const remove = values[`delete-${module.id}`]; // "remove" because "delete" is not allowed as a variable declaration name

                const allowedActions = [
                  read && "read",
                  create && "create",
                  update && "update",
                  remove && "delete",
                ]
                  .filter(Boolean)
                  .join(";");

                return read || create || update || remove
                  ? {
                      moduleId: module.id,
                      allowedActions,
                    }
                  : null;
              })
              .filter((item) => item !== null)
          );
        }

        // for non-"Reports" groups
        return group.modules
          ?.map((module) => {
            const read = values[`read-${module.id}`];
            const create = values[`create-${module.id}`];
            const update = values[`update-${module.id}`];
            const remove = values[`delete-${module.id}`]; // "remove" because "delete" is not allowed as a variable declaration name

            const allowedActions = [
              read && "read",
              create && "create",
              update && "update",
              remove && "delete",
            ]
              .filter(Boolean)
              .join(";");

            return read || create || update || remove
              ? {
                  moduleId: module.id,
                  allowedActions,
                }
              : null;
          })
          .filter((item) => item !== null);
      });

      const input = {
        name: values.name,
        allowedModules,
      };

      console.log("Form values:", input);
      await createRole({ variables: { input: input } });
    } catch (err) {
      openErrorNotification(notiApi, err.message);
    }
  };

  const handleClickAll = useCallback(
    (permissionType, baseModuleName, groupBaseModuleName) => {
      const newValues = {};
      let allSelected = true;
      console.log("base module name", groupBaseModuleName);

      if (groupBaseModuleName && groupBaseModuleName === "Reports") {
        modules?.forEach((group) => {
          if (group.baseModuleName === "Reports") {
            group.reports?.forEach((report) => {
              if (report.baseModuleName === baseModuleName) {
                report.modules?.forEach((module) => {
                  const key = `${permissionType}-${module.id}`;
                  const currentValue = form.getFieldValue(key);
                  if (currentValue !== true) {
                    allSelected = false;
                  }
                });

                report.modules?.forEach((module) => {
                  newValues[`${permissionType}-${module.id}`] = allSelected
                    ? false
                    : true;
                });
              }
            });
          }
        });
      } else {
        modules?.forEach((group) => {
          if (group.baseModuleName === baseModuleName) {
            group.modules?.forEach((module) => {
              const key = `${permissionType}-${module.id}`;
              const currentValue = form.getFieldValue(key);
              if (currentValue !== true) {
                allSelected = false;
              }
            });

            group.modules?.forEach((module) => {
              newValues[`${permissionType}-${module.id}`] = allSelected
                ? false
                : true;
            });
          }
        });
      }

      form.setFieldsValue(newValues);
    },
    [form, modules]
  );

  const isActionAllowed = (actions, action) => {
    return actions.split(";").includes(action);
  };

  const moduleColumns = (baseModuleName, groupBaseModuleName) => [
    {
      title: <FormattedMessage id="label.module" defaultMessage="Module" />,
      dataIndex: "name",
      key: "name",
      width: "25%",
      render: (name) => formatModuleName(name),
    },
    {
      title: (
        <Flex align="center" justify="center" vertical>
          <FormattedMessage id="label.read" defaultMessage="Read" />
          <Button
            type="link"
            style={{ padding: 0, height: "auto" }}
            onClick={() =>
              handleClickAll("read", baseModuleName, groupBaseModuleName)
            }
          >
            Click All
          </Button>
        </Flex>
      ),
      dataIndex: "read",
      key: "read",
      align: "center",
      render: (_, record) => {
        const canRead = isActionAllowed(record.actions, "READ");
        return canRead ? (
          <Form.Item noStyle name={`read-${record.id}`} valuePropName="checked">
            <Checkbox />
          </Form.Item>
        ) : null;
      },
    },
    {
      title: (
        <Flex align="center" justify="center" vertical>
          <FormattedMessage id="label.create" defaultMessage="Create" />
          <Button
            type="link"
            style={{ padding: 0, height: "auto" }}
            onClick={() =>
              handleClickAll("create", baseModuleName, groupBaseModuleName)
            }
          >
            Click All
          </Button>
        </Flex>
      ),
      dataIndex: "create",
      key: "create",
      align: "center",
      render: (_, record) => {
        const canCreate = isActionAllowed(record.actions, "Create");
        return canCreate ? (
          <Form.Item
            noStyle
            name={`create-${record.id}`}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        ) : null;
      },
    },
    {
      title: (
        <Flex align="center" justify="center" vertical>
          <FormattedMessage id="label.update" defaultMessage="Update" />
          <Button
            type="link"
            style={{ padding: 0, height: "auto" }}
            onClick={() =>
              handleClickAll("update", baseModuleName, groupBaseModuleName)
            }
          >
            Click All
          </Button>
        </Flex>
      ),
      dataIndex: "update",
      key: "update",
      align: "center",
      render: (_, record) => {
        const canUpdate = isActionAllowed(record.actions, "Update");
        return canUpdate ? (
          <Form.Item
            noStyle
            name={`update-${record.id}`}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        ) : null;
      },
    },
    {
      title: (
        <Flex align="center" justify="center" vertical>
          <FormattedMessage id="label.delete" defaultMessage="Delete" />
          <Button
            type="link"
            style={{ padding: 0, height: "auto" }}
            onClick={() =>
              handleClickAll("delete", baseModuleName, groupBaseModuleName)
            }
          >
            Click All
          </Button>
        </Flex>
      ),
      dataIndex: "delete",
      key: "delete",
      align: "center",
      render: (_, record) => {
        const canDelete = isActionAllowed(record.actions, "Delete");
        return canDelete ? (
          <Form.Item
            noStyle
            name={`delete-${record.id}`}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        ) : null;
      },
    },
  ];

  console.log("grouped modules", modules);

  return (
    <>
      <div className="page-header">
        <p className="page-header-text">
          <FormattedMessage id="role.new" defaultMessage="New Role" />
        </p>
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={() =>
            navigate(from, { state: location.state, replace: true })
          }
        />
      </div>
      <div className="page-content page-content-with-padding">
        <div className="page-form-wrapper">
          <Form
            form={form}
            onFinish={handleSubmit}
            style={{ maxWidth: "1100px" }}
          >
            <Form.Item
              label={
                <FormattedMessage
                  id="label.roleName"
                  defaultMessage="Role Name"
                />
              }
              name="name"
              labelAlign="left"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 8 }}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="label.roleName.required"
                      defaultMessage="Enter the Role Name"
                    />
                  ),
                },
              ]}
            >
              <Input maxLength={100} />
            </Form.Item>
            <Divider />
            {moduleLoading ? (
              <Flex justify="center" align="center" style={{ height: "5rem" }}>
                <Spin />
              </Flex>
            ) : (
              modules?.map((group) => (
                <Card
                  className="role-module-card"
                  title={group.baseModuleName}
                  key={group.baseModuleName}
                >
                  {group.baseModuleName !== "Reports" ? (
                    <Table
                      loading={loading}
                      className="role-module-table"
                      columns={moduleColumns(group.baseModuleName)}
                      dataSource={group.modules}
                      pagination={false}
                      rowKey={(record) => record.id}
                    />
                  ) : (
                    <>
                      <Collapse
                        key={group.baseModuleName}
                        className="reports-collapse"
                      >
                        {group.reports?.map((reportGroup) => (
                          <Collapse.Panel
                            header={reportGroup.baseModuleName}
                            key={reportGroup.baseModuleName}
                          >
                            <Table
                              loading={loading}
                              className="role-module-table"
                              columns={moduleColumns(
                                reportGroup.baseModuleName,
                                group.baseModuleName
                              )}
                              dataSource={reportGroup.modules}
                              pagination={false}
                              rowKey={(record) => record.id}
                            />
                          </Collapse.Panel>
                        ))}
                      </Collapse>
                    </>
                  )}
                </Card>
              ))
            )}
            <div className="page-actions-bar page-actions-bar-margin">
              <Button
                type="primary"
                htmlType="submit"
                className="page-actions-btn"
                loading={createLoading}
              >
                Save
              </Button>
              <Button
                className="page-actions-btn"
                onClick={() =>
                  navigate(from, { state: location.state, replace: true })
                }
              >
                {
                  <FormattedMessage
                    id="button.cancel"
                    defaultMessage="Cancel"
                  />
                }
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default RolesNew;
