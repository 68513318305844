import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Flex,
  Form,
  Row,
  Select,
  Space,
  theme,
  Spin,
  Empty,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useQuery, useReadQuery } from "@apollo/client";
import { ReactComponent as DiamondOutlined } from "../../assets/icons/DiamondOutlined.svg";
import { ReactComponent as ArrowDownwardOutlined } from "../../assets/icons/ArrowDownwardOutlined.svg";
import { ReactComponent as ArrowUpwardOutlined } from "../../assets/icons/ArrowUpwardOutlined.svg";
import { ReactComponent as SavingsOutlined } from "../../assets/icons/SavingsOutlined.svg";
import { ReactComponent as ReceiptOutlined } from "../../assets/icons/ReceiptOutlined.svg";
import { ReactComponent as WalletOutlined } from "../../assets/icons/WalletOutlined.svg";
import { ReactComponent as PoliceOutlined } from "../../assets/icons/PoliceOutlined.svg";
import { OpeningBalanceQueries } from "../../graphql";
import { openErrorNotification } from "../../utils/Notification";
import dayjs from "dayjs";
import { REPORT_DATE_FORMAT } from "../../config/Constants";
const { GET_OPENING_BALANCE } = OpeningBalanceQueries;

const OpeningBalances = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = theme.useToken();
  const {
    allAccountsQueryRef,
    allBranchesQueryRef,
    business,
    notiApi,
    msgApi,
  } = useOutletContext();
  const [selectedBranchId, setSelectedBranchId] = useState(
    business?.primaryBranch?.id || null
  );

  // Queries
  const { data: accountData } = useReadQuery(allAccountsQueryRef);
  const { data: branchData } = useReadQuery(allBranchesQueryRef);

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_OPENING_BALANCE, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      branchId: selectedBranchId,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
    skip: !selectedBranchId,
  });

  useEffect(() => {
    if (selectedBranchId) {
      refetch();
    }
  }, [selectedBranchId, refetch]);

  const queryData = useMemo(() => data?.getOpeningBalance, [data]);

  const branches = useMemo(() => {
    return branchData?.listAllBranch?.filter((b) => b.isActive === true);
  }, [branchData]);

  const accounts = useMemo(() => {
    return accountData?.listAllAccount?.filter((a) => a.isActive === true);
  }, [accountData]);

  const getAccountIcon = (mainType) => {
    switch (mainType) {
      case "Asset":
        return <DiamondOutlined />;
      case "Receivable":
        return <ArrowDownwardOutlined />;
      case "Payable":
        return <ArrowUpwardOutlined />;
      case "Expense":
        return <ReceiptOutlined />;
      case "Liability":
        return <PoliceOutlined />;
      case "Equity":
        return <SavingsOutlined />;
      case "Income":
        return <WalletOutlined />;
      default:
        return null;
    }
  };

  const groupedAccounts = useMemo(() => {
    if (!queryData?.details) return [];

    const groups = {};
    const accountsPayable = [];
    const accountsReceivable = [];

    queryData.details.forEach((detail) => {
      const { mainType, name } = detail.account;
      if (name === "Accounts Payable") {
        accountsPayable.push(detail);
      } else if (name === "Accounts Receivable") {
        accountsReceivable.push(detail);
      } else {
        if (!groups[mainType]) {
          groups[mainType] = [];
        }
        groups[mainType].push(detail);
      }
    });

    const mainTypeGroups = Object.keys(groups).map((key) => ({
      mainType: key,
      icon: getAccountIcon(key),
      accounts: groups[key],
    }));

    const result = [];

    if (accountsReceivable.length) {
      result.push({
        mainType: "Accounts Receivable",
        icon: getAccountIcon("Receivable"),
        accounts: accountsReceivable,
      });
    }

    if (accountsPayable.length) {
      result.push({
        mainType: "Accounts Payable",
        icon: getAccountIcon("Payable"),
        accounts: accountsPayable,
      });
    }

    return result.concat(mainTypeGroups);
  }, [queryData?.details]);

  console.log("grouped accounts", groupedAccounts);

  // Calculate totals
  const totalDebit = useMemo(() => {
    return groupedAccounts.reduce((sum, group) => {
      return (
        sum +
        group.accounts.reduce(
          (acc, accDetail) => acc + (accDetail.debit || 0),
          0
        )
      );
    }, 0);
  }, [groupedAccounts]);

  const totalCredit = useMemo(() => {
    return groupedAccounts.reduce((sum, group) => {
      return (
        sum +
        group.accounts.reduce(
          (acc, accDetail) => acc + (accDetail.credit || 0),
          0
        )
      );
    }, 0);
  }, [groupedAccounts]);

  return (
    <>
      <div className="page-header">
        <p className="page-header-text">Opening Balances</p>
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() =>
              navigate("edit", {
                state: {
                  ...location.state,
                  from: { pathname: location.pathname },
                  record: queryData,
                  selectedBranchId,
                },
              })
            }
            style={{ background: "var(--main-bg-color)" }}
          >
            <span>
              <FormattedMessage id="button.edit" defaultMessage="Edit" />
            </span>
          </Button>
        </Space>
      </div>
      <div className="page-content page-content-with-padding">
        <div className="ob-page-form-wrapper">
          <Row>
            <Col span={9}>
              <Form.Item
                label={
                  <FormattedMessage id="label.branch" defaultMessage="Branch" />
                }
                name="branch"
                labelAlign="left"
                labelCol={{ span: 8 }}
                initialValue={business?.primaryBranch?.id}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  defaultValue={business?.primaryBranch?.id}
                  onChange={(value) => setSelectedBranchId(value)}
                >
                  {branches?.map((branch) => (
                    <Select.Option
                      key={branch.id}
                      value={branch.id}
                      label={branch.name}
                    >
                      {branch.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10} style={{ paddingLeft: "1.5rem" }}>
              <p style={{ fontSize: 13, fontWeight: 500 }}>
                Migration Date:{" "}
                {dayjs(
                  queryData?.migrationDate || business?.migrationDate
                ).format(REPORT_DATE_FORMAT)}
              </p>
            </Col>
            {/* <Col span={5}>
              <Flex justify="end" align="center">
                <Button icon={<DeleteOutlined />} />
              </Flex>
            </Col> */}
          </Row>
          <Divider />
          {queryLoading ? (
            <Flex justify="center" align="center" style={{ height: "124px" }}>
              <Spin></Spin>
            </Flex>
          ) : groupedAccounts?.length > 0 ? (
            <Collapse
              // size="large"
              style={{
                background: token.colorBgContainer,
              }}
              className="custom-collapse"
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition="end"
            >
              {groupedAccounts.map((group, index) => (
                <Collapse.Panel
                  header={
                    <Space>
                      <Flex align="center">{group.icon}</Flex>
                      <h4 style={{ margin: 0 }}>{group.mainType}</h4>
                    </Space>
                  }
                  key={index + 1}
                >
                  {queryLoading ? (
                    <Flex
                      align="center"
                      justify="center"
                      style={{ height: "5rem" }}
                    >
                      <Spin />
                    </Flex>
                  ) : (
                    <>
                      <Row
                        className="account-value-row"
                        style={{ marginBottom: "1.5rem" }}
                      >
                        <Col span={9} className="account-value-box">
                          <label>ACCOUNTS</label>
                        </Col>
                        <Col span={4} className="account-value-box">
                          {/* <label>Branch</label> */}
                        </Col>
                        <Col
                          span={5}
                          className="account-value-box text-align-right"
                        >
                          <label>
                            DEBIT ({business?.baseCurrency?.symbol})
                          </label>
                        </Col>
                        <Col
                          span={5}
                          className="account-value-box text-align-right"
                        >
                          <label>
                            CREDIT ({business?.baseCurrency?.symbol})
                          </label>
                        </Col>
                      </Row>

                      {group.accounts.map((acc) => (
                        <Row key={acc.id}>
                          <Col span={9} className="account-value-box">
                            <p style={{ marginTop: 0 }} key={acc.id}>
                              {acc.account.name}
                            </p>
                            {/* <div style={{ opacity: "70%", marginBottom: "1rem" }}>
                            <span>Name: Shampoo(shampoo)</span>
                            <Divider type="vertical" />
                            <span>Stock: 123</span>
                            <Divider type="vertical" />
                            <span>Rate: 2000</span>
                          </div> */}
                          </Col>
                          <Col span={4} className="account-value-box">
                            {/* {queryData?.branch?.name} */}
                          </Col>
                          <Col
                            span={5}
                            className="account-value-box text-align-right"
                          >
                            <FormattedNumber
                              value={acc.debit || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business?.currency?.decimalPlaces
                              }
                            />
                          </Col>
                          <Col
                            span={5}
                            className="account-value-box text-align-right"
                          >
                            <FormattedNumber
                              value={acc.credit || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business?.currency?.decimalPlaces
                              }
                            />
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </Collapse.Panel>
              ))}
            </Collapse>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}

          <div className="ob-total-section">
            <Row>
              <Col span={9} className="account-value-box"></Col>
              <Col span={4} className="account-value-box ">
                <b>TOTAL:</b>
              </Col>
              <Col span={5} className="account-value-box text-align-right">
                <b>
                  <FormattedNumber
                    value={totalDebit || 0}
                    style="decimal"
                    minimumFractionDigits={business?.currency?.decimalPlaces}
                  />
                </b>
              </Col>
              <Col span={5} className="account-value-box text-align-right">
                <b>
                  <FormattedNumber
                    value={totalCredit || 0}
                    style="decimal"
                    minimumFractionDigits={business?.currency?.decimalPlaces}
                  />
                </b>
              </Col>
            </Row>
          </div>
          {/* </Col>
          </Row> */}
        </div>
      </div>
    </>
  );
};

export default OpeningBalances;
