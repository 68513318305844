import React, { useEffect, useMemo } from "react";
import { LeftOutlined, RightOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Row, Space, Table, Modal, Tooltip } from "antd";
import { useQuery, useLazyQuery } from "@apollo/client";
import { FormattedMessage, useIntl } from "react-intl";
import { openErrorNotification } from "../utils/Notification";
import { paginateArray } from "../utils/HelperFunctions";
import { DATE_STRING_FORMAT, QUERY_DATA_LIMIT } from "../config/Constants";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

const PaginatedSelectionTable = ({
  api,
  columns = [],
  compactColumns = [],
  gqlQuery,
  parseData,
  parsePageInfo,
  searchForm,
  searchFormRef,
  searchQqlQuery,
  selectedRecord,
  selectedRowIndex,
  setSelectedRecord,
  setSelectedRowIndex,
  searchCriteria,
  setSearchCriteria,
  searchModalOpen,
  setSearchModalOpen,
  searchTitle = "Search",
  setCurrentPage,
  currentPage,
  compactTableHeader = false,
}) => {
  const intl = useIntl();

  const handleRefetch = async () => {
    try {
      if (searchCriteria) {
        const searchVariables = Object.fromEntries(
          Object.entries(searchCriteria).filter(
            ([_, value]) => value !== undefined
          )
        );
        await searchRefetch(searchVariables);
      } else {
        await dataRefetch();
      }
      setCurrentPage(1);
    } catch (err) {
      openErrorNotification(api, err.message);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = async () => {
    if (currentPage === totalPages) {
      try {
        if (searchCriteria) {
          const searchVariables = Object.fromEntries(
            Object.entries(searchCriteria).filter(
              ([_, value]) => value !== undefined
            )
          );
          await fetchMore({
            variables: {
              limit: QUERY_DATA_LIMIT,
              after: parsePageInfo(searchData).endCursor,
              ...searchVariables,
            },
          });
        } else {
          await dataFetchMore({
            variables: {
              limit: QUERY_DATA_LIMIT,
              after: parsePageInfo(data).endCursor,
            },
          });
        }

        setCurrentPage(currentPage + 1);
      } catch (err) {
        openErrorNotification(api, err.message);
      }
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleModalSearch = async () => {
    try {
      const searchValues = searchFormRef.getFieldsValue();

      // convert date objects to strings
      const convertedSearchValues = Object.keys(searchValues).reduce(
        (acc, key) => {
          const value = searchValues[key];
          if (value instanceof Date || typeof value === "object") {
            acc[key] = dayjs(value).utc(true).format(DATE_STRING_FORMAT);
          } else {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );

      console.log("search values", convertedSearchValues);

      const hasValues = Object.values(convertedSearchValues).some(
        (value) => value !== undefined && value !== "" && value !== null
      );

      if (!hasValues) {
        openErrorNotification(
          api,
          intl.formatMessage({
            id: "error.atLeastOneSearchCriteria",
            defaultMessage: "Please fill in at least one search criteria",
          })
        );
        return;
      }

      await search({
        variables: convertedSearchValues,
      });

      setCurrentPage(1);
      setSearchCriteria(convertedSearchValues);
      setSearchModalOpen(false);
    } catch (err) {
      openErrorNotification(api, err.message);
    }
  };

  const handleModalCancel = () => {
    setSearchModalOpen(false);
  };

  const [
    search,
    {
      loading: searchLoading,
      data: searchData,
      fetchMore,
      refetch: searchRefetch,
    },
  ] = useLazyQuery(searchQqlQuery, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: QUERY_DATA_LIMIT,
    },
    onError(err) {
      openErrorNotification(api, err.message);
    },
  });

  const {
    data,
    loading: queryLoading,
    fetchMore: dataFetchMore,
    refetch: dataRefetch,
  } = useQuery(gqlQuery, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: QUERY_DATA_LIMIT,
    },
    onError(err) {
      openErrorNotification(api, err.message);
    },
  });

  // useEffect(() => {
  //   if (!searchCriteria) {
  //     dataRefetch();
  //   }
  // }, [searchCriteria, dataRefetch]);

  const allData = useMemo(() => parseData(data), [data, parseData]);
  const searchResults = useMemo(
    () => parseData(searchData),
    [parseData, searchData]
  );
  const pageInfo = useMemo(() => parsePageInfo(data), [data, parsePageInfo]);
  const searchPageInfo = useMemo(
    () => parsePageInfo(searchData),
    [searchData, parsePageInfo]
  );

  const totalPages = searchCriteria
    ? Math.ceil(searchResults.length / QUERY_DATA_LIMIT)
    : Math.ceil(allData.length / QUERY_DATA_LIMIT);
  let hasPreviousPage = currentPage > 1 ? true : false;
  let hasNextPage = false;
  let refetchEnabled = true;
  if (currentPage === totalPages) {
    hasNextPage = searchCriteria
      ? searchPageInfo.hasNextPage
      : pageInfo.hasNextPage;
  } else if (currentPage < totalPages) {
    hasNextPage = true;
  }

  const pageData = paginateArray(allData, QUERY_DATA_LIMIT, currentPage);

  const searchPageData = paginateArray(
    searchResults,
    QUERY_DATA_LIMIT,
    currentPage
  );

  // React.useEffect(() => {
  //   if (currentPage < 1) {
  //     setCurrentPage(1);
  //   } else if (currentPage > totalPages) {
  //     setCurrentPage(totalPages);
  //   }
  // }, [currentPage, totalPages, setCurrentPage]);

  const loading = queryLoading || searchLoading;

  console.log("data", data);

  return (
    <>
      <Modal
        width="65.5rem"
        title={searchTitle}
        okText={<FormattedMessage id="button.search" defaultMessage="Search" />}
        cancelText={
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        }
        open={searchModalOpen}
        onOk={handleModalSearch}
        onCancel={handleModalCancel}
        okButtonProps={loading}
      >
        {searchForm}
      </Modal>
      <Table
        className={
          selectedRecord
            ? compactTableHeader
              ? ""
              : "header-less-table"
            : "main-table"
        }
        rowKey={(record) => record.id}
        loading={loading}
        columns={selectedRecord ? compactColumns : columns}
        dataSource={searchCriteria ? searchPageData : pageData}
        pagination={false}
        rowSelection={{ selectedRowKeys: [selectedRowIndex] }}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedRecord(record);
              setSelectedRowIndex(record.id);
            },
          };
        }}
      />
      <Row style={{ justifyContent: "space-between", marginBottom: 5 }}>
        <div></div>
        <Space style={{ padding: "0.5rem 1.5rem 0 0" }}>
          <Tooltip
            title={
              <FormattedMessage id="button.refetch" defaultMessage="Refetch" />
            }
          >
            <Button
              icon={<SyncOutlined />}
              loading={loading}
              disabled={!refetchEnabled}
              onClick={handleRefetch}
            />
          </Tooltip>
          <Tooltip
            title={
              <FormattedMessage
                id="button.previous"
                defaultMessage="Previous"
              />
            }
          >
            <Button
              icon={<LeftOutlined />}
              loading={loading}
              disabled={!hasPreviousPage}
              onClick={handlePrevious}
            />
          </Tooltip>
          <Tooltip
            title={<FormattedMessage id="button.next" defaultMessage="Next" />}
          >
            <Button
              icon={<RightOutlined />}
              loading={loading}
              disabled={!hasNextPage}
              onClick={handleNext}
            />
          </Tooltip>
        </Space>
      </Row>
    </>
  );
};

export default PaginatedSelectionTable;
