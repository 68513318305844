import React, { useMemo, useState } from "react";
import { Button, Col, Dropdown, Flex, Row, Space, Spin } from "antd";
import "./Dashboard.css";
import {
  PlusCircleFilled,
  DownOutlined,
  PlusCircleTwoTone,
  CaretDownFilled,
} from "@ant-design/icons";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ReportQueries } from "../graphql";
import { openErrorNotification } from "../utils/Notification";
import { useQuery } from "@apollo/client";
const {
  GET_TOTAL_PAYABLE_RECEIVABLE,
  GET_TOTAL_INCOME_EXPENSE,
  GET_TOTAL_CASH_FLOW,
  GET_TOP_EXPENSE,
} = ReportQueries;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const filterCFOptions = [
  {
    key: "thisFiscalYear",
    label: "This Fiscal Year",
  },
  {
    key: "previousFiscalYear",
    label: "Previous Fiscal Year",
  },
  {
    key: "last12months",
    label: "Last 12 Months",
  },
];

const filterIEOptions = [
  {
    key: "thisFiscalYear",
    label: "This Fiscal Year",
  },
  {
    key: "previousFiscalYear",
    label: "Previous Fiscal Year",
  },
  {
    key: "last12months",
    label: "Last 12 Months",
  },
  {
    key: "last6months",
    label: "Last 6 Months",
  },
];

const filterEOptions = [
  {
    key: "thisFiscalYear",
    label: "This Fiscal Year",
  },
  {
    key: "thisQuarter",
    label: "This Quarter",
  },
  {
    key: "thisMonth",
    label: "This Month",
  },
  {
    key: "previousFiscalYear",
    label: "Previous Fiscal Year",
  },
  {
    key: "previousQuarter",
    label: "Previous Quarter",
  },
  {
    key: "previousMonth",
    label: "Previous Month",
  },
  {
    key: "last6months",
    label: "Last 6 Months",
  },
  {
    key: "last12months",
    label: "Last 12 Months",
  },
];

const transformDateFormat = (date) => {
  const [year, month] = date.split("-");
  return `${month}-${year}`;
};

const initialFilter = {
  key: "thisFiscalYear",
  label: "This Fiscal Year",
};

const formatLabelNumber = (value) => {
  const absValue = Math.abs(value);
  const sign = value < 0 ? "-" : "";

  if (absValue >= 1_000_000_000) {
    return `${sign}${absValue / 1_000_000_000} B`;
  } else if (absValue >= 1_000_000) {
    return `${sign}${absValue / 1_000_000} M`;
  } else if (absValue >= 1_000) {
    return `${sign}${absValue / 1_000} K`;
  } else {
    return `${sign}${absValue}`;
  }
};

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { notiApi, business } = useOutletContext();
  const [filterCF, setFilterCF] = useState(initialFilter);
  const [filterIE, setFilterIE] = useState(initialFilter);
  const [filterE, setFilterE] = useState(initialFilter);

  //Queries
  const { data: payReceiveData, loading: payReceiveLoading } = useQuery(
    GET_TOTAL_PAYABLE_RECEIVABLE,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      onError(err) {
        openErrorNotification(notiApi, err.message);
      },
    }
  );

  const { data: incomeExpenseData, loading: incomeExpenseLoading } = useQuery(
    GET_TOTAL_INCOME_EXPENSE,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        filterType: filterIE.key,
      },
      onError(err) {
        openErrorNotification(notiApi, err.message);
      },
    }
  );

  const { data: cashFlowData, loading: cashFlowLoading } = useQuery(
    GET_TOTAL_CASH_FLOW,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        filterType: filterCF.key,
      },
      onError(err) {
        openErrorNotification(notiApi, err.message);
      },
    }
  );

  const { data: topExpenseData, loading: topExpenseLoading } = useQuery(
    GET_TOP_EXPENSE,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        filterType: filterE.key,
      },
      onError(err) {
        openErrorNotification(notiApi, err.message);
      },
    }
  );

  const totalPayable = useMemo(() => {
    return payReceiveData?.getTotalPayableReceivable?.[0]?.totalPayable;
  }, [payReceiveData]);

  const totalReceivable = useMemo(() => {
    return payReceiveData?.getTotalPayableReceivable?.[0]?.totalReceivable;
  }, [payReceiveData]);

  const totalIncomeExpense = useMemo(() => {
    return incomeExpenseData?.getTotalIncomeExpense;
  }, [incomeExpenseData]);

  const totalCashFlow = useMemo(() => {
    return cashFlowData?.getTotalCashFlow;
  }, [cashFlowData]);

  const cashFlowDetails = useMemo(() => {
    return cashFlowData?.getTotalCashFlow?.cashFlowDetails?.map((detail) => ({
      ...detail,
      month: transformDateFormat(detail.month),
    }));
  }, [cashFlowData]);

  const firstMonth = useMemo(() => {
    return cashFlowDetails?.[0]?.month;
  }, [cashFlowDetails]);

  const lastMonth = useMemo(() => {
    return cashFlowDetails?.[cashFlowDetails?.length - 1]?.month;
  }, [cashFlowDetails]);

  const incomeExpenseLabels = useMemo(() => {
    return totalIncomeExpense?.incomeExpenseDetails?.map((detail) =>
      transformDateFormat(detail.month)
    );
  }, [totalIncomeExpense]);

  const incomeData = useMemo(() => {
    return totalIncomeExpense?.incomeExpenseDetails?.map(
      (detail) => detail.incomeAmount
    );
  }, [totalIncomeExpense]);

  const expenseData = useMemo(() => {
    return totalIncomeExpense?.incomeExpenseDetails?.map(
      (detail) => detail.expenseAmount
    );
  }, [totalIncomeExpense]);

  const topExpenses = useMemo(() => {
    return topExpenseData?.getTopExpense;
  }, [topExpenseData]);

  const topExpenseAmounts = useMemo(() => {
    return topExpenses?.map((e) => e.amount) || [];
  }, [topExpenses]);

  const topExpenseLabels = useMemo(() => {
    return topExpenses?.map((e) => e.accountName) || [];
  }, [topExpenses]);

  const formatNumber = (value, options = {}) => {
    return new Intl.NumberFormat({
      style: "decimal",
      decimalPlaces: business?.baseCurrency?.decimalPlaces,
      ...options,
    }).format(value);
  };

  const lineData = {
    datasets: [
      {
        label: "Cash Flow",
        data: cashFlowDetails,
        parsing: {
          xAxisKey: "month",
          yAxisKey: "endingBalance",
        },
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        tension: 0.3,
        pointHoverBorderWidth: 7,
        pointHoverBackgroundColor: "white",
      },
    ],
  };

  const lineOptions = {
    parsing: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        borderColor: "#d7d5e2",
        borderWidth: 1,
        titleColor: "#484848",
        bodyColor: "#484848",
        displayColors: false,
        footerColor: "rgba(75,192,192,1)",
        bodySpacing: 5,
        footerSpacing: 5,
        titleAlign: "center",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        footerFont: {
          size: 14,
          weight: 500,
        },
        padding: {
          top: 15,
          right: 15,
          bottom: 15,
          left: 15,
        },
        callbacks: {
          title: (tooltipItems) => `${tooltipItems[0].label}`,
          label: (tooltipItem) => {
            const label1 = `Opening Balance: ${
              business?.baseCurrency?.symbol
            } ${formatNumber(tooltipItem.raw.openingBalance)}`;
            const label2 = `Incoming: ${
              business?.baseCurrency?.symbol
            } ${formatNumber(tooltipItem.raw.incomingAmount)}`;
            const label3 = `Outgoing: ${
              business?.baseCurrency?.symbol
            } ${formatNumber(tooltipItem.raw.outgoingAmount)}`;
            return [label1, label2, label3];
          },
          footer: (tooltipItems) => {
            const tooltipItem = tooltipItems[0];
            return `Ending Balance: ${
              business?.baseCurrency?.symbol
            } ${formatNumber(tooltipItem.raw.endingBalance)}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10.5,
          },
          callback: function (value, index, values) {
            return this.getLabelForValue(value).split("-");
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value) => `${formatLabelNumber(value)}`,
        },
      },
    },
  };

  const barData = {
    labels: incomeExpenseLabels,
    datasets: [
      {
        label: "Income",
        data: incomeData,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        barPercentage: 0.8,
      },
      {
        label: "Expense",
        data: expenseData,
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        barPercentage: 0.8,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        backgroundColor: "white",
        borderColor: "#d7d5e2",
        borderWidth: 1,
        titleColor: "#484848",
        bodyColor: "#484848",
        footerColor: "#484848",
        bodySpacing: 3,
        footerSpacing: 3,
        titleAlign: "center",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        footerFont: {
          size: 14,
          weight: 400,
        },
        padding: {
          top: 10,
          right: 15,
          bottom: 10,
          left: 15,
        },
        callbacks: {
          label: (tooltipItem) => {
            const formattedValue = formatNumber(tooltipItem.raw);
            return `${tooltipItem.dataset.label}: ${business?.baseCurrency?.symbol} ${formattedValue}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10.5,
          },
          callback: function (value, index, values) {
            return this.getLabelForValue(value).split("-");
          },
        },
      },
      y: {
        stacked: false,
        beginAtZero: true,
        ticks: {
          callback: (value) => `${formatLabelNumber(value)}`,
        },
      },
    },
  };

  const pieData = {
    labels: topExpenseLabels,
    datasets: [
      {
        data: topExpenseAmounts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(128, 0, 128, 0.6)",
        ],
        hoverBackgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "rgba(128, 0, 128, 0.8)",
        ],
        derWidth: 1,
        hoverOffset: 6,
        hoverBorderWidth: 2,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      tooltip: {
        backgroundColor: "white",
        borderColor: "#d7d5e2",
        borderWidth: 1,
        titleColor: "#484848",
        bodyColor: "#484848",
        footerColor: "#484848",
        bodySpacing: 3,
        footerSpacing: 3,
        titleAlign: "center",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        footerFont: {
          size: 14,
          weight: 400,
        },
        padding: {
          top: 10,
          right: 15,
          bottom: 10,
          left: 15,
        },
        callbacks: {
          label: (tooltipItem) => {
            const dataset = tooltipItem.dataset;
            const total = dataset.data.reduce((acc, value) => acc + value, 0);
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const percentage = ((currentValue / total) * 100).toFixed(2);
            return `${business?.baseCurrency?.symbol} ${formatNumber(
              currentValue
            )} (${percentage}%)`;
          },
        },
      },
    },
  };

  const receivableDropdownItems = [
    {
      key: "1",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>1-15 Days</span>
          <span>
            {totalReceivable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalReceivable?.int1to15}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
    {
      key: "2",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>16-30 Days</span>
          <span>
            {totalReceivable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalReceivable?.int16to30}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
    {
      key: "3",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>31-45 Days</span>
          <span>
            {totalReceivable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalReceivable?.int31to45}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
    {
      key: "4",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>Above 45 Days</span>
          <span>
            {totalReceivable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalReceivable?.int46plus}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
  ];

  const payableDropdownItems = [
    {
      key: "1",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>1-15 Days</span>
          <span>
            {totalPayable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalPayable?.int1to15}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
    {
      key: "2",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>16-30 Days</span>
          <span>
            {totalPayable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalPayable?.int16to30}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
    {
      key: "3",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>31-45 Days</span>
          <span>
            {totalPayable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalPayable?.int31to45}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
    {
      key: "4",
      label: (
        <Flex
          justify="space-between"
          align="center"
          style={{ height: "2.5rem" }}
        >
          <span>Above 45 Days</span>
          <span>
            {totalPayable?.currencySymbol}{" "}
            <FormattedNumber
              value={totalPayable?.int46plus}
              style="decimal"
              // minimumFractionDigits={
              //   business?.currency?.decimalPlaces
              // }
            />
          </span>
        </Flex>
      ),
    },
  ];

  const handleFilterCFChange = (key) => {
    const selectedFilter = filterCFOptions.find((option) => option.key === key);
    setFilterCF(selectedFilter);
  };

  const handleFilterIEChange = (key) => {
    const selectedFilter = filterIEOptions.find((option) => option.key === key);
    setFilterIE(selectedFilter);
  };

  const handleFilterEChange = (key) => {
    const selectedFilter = filterEOptions.find((option) => option.key === key);
    setFilterE(selectedFilter);
  };

  return (
    <div className="db-wrapper">
      <div
        className="page-header-text"
        style={{
          cursor: "pointer",
          fontSize: "20px",
          marginTop: "-0.5rem",
          marginBottom: "1.5rem",
          maxWidth: "14rem",
        }}
      >
        Dashboard
      </div>
      <Row className="db-row">
        <Col span={12} style={{ paddingRight: "1rem" }}>
          <div className="db-card">
            <div className="db-card-header">
              <span className="title">Total Receivables</span>
              <Dropdown
                trigger="click"
                menu={{
                  onClick: ({ key }) => {
                    if (key === "0")
                      navigate("invoices/new", {
                        state: {
                          ...location.state,
                          from: { pathname: location.pathname },
                        },
                      });
                    else if (key === "1")
                      navigate("paymentsReceived/new", {
                        state: {
                          ...location.state,
                          from: { pathname: location.pathname },
                        },
                      });
                  },
                  items: [
                    {
                      label: (
                        <Space>
                          <PlusCircleTwoTone twoToneColor="rgb(75, 192, 192)" />
                          <FormattedMessage
                            id="button.newInvoice"
                            defaultMessage="New Invoice"
                          />
                        </Space>
                      ),
                      key: "0",
                    },
                    {
                      label: (
                        <Space>
                          <PlusCircleTwoTone twoToneColor="rgb(75, 192, 192)" />
                          <FormattedMessage
                            id="button.newCustomerPayment"
                            defaultMessage="New Customer Payment"
                          />
                        </Space>
                      ),
                      key: "1",
                    },
                  ],
                }}
              >
                <Button
                  type="link"
                  icon={<PlusCircleFilled />}
                  style={{ padding: 0 }}
                >
                  <span>
                    <FormattedMessage id="button.new" defaultMessage="New" />
                  </span>
                </Button>
              </Dropdown>
            </div>
            <div className="db-card-body">
              {payReceiveLoading ? (
                <Flex
                  justify="center"
                  align="center"
                  style={{ minHeight: "3.537rem" }}
                >
                  <Spin />
                </Flex>
              ) : (
                <>
                  {/* <div className="db-unpaid">
                    Total Unpaid Invoices MMK 1009090090
                  </div> */}
                  <Flex justify="space-between" style={{ paddingTop: "10px" }}>
                    <div
                      className="db-current"
                      onClick={() => {
                        navigate("/reports/arAgingDetails", {
                          state: {
                            ...location.state,
                            from: { pathname: location.pathname },
                            initialPeriod: "today",
                          },
                        });
                      }}
                    >
                      <div
                        style={{
                          fontSize: "var(--small-text)",
                          color: "var(--primary-color)",
                        }}
                      >
                        CURRENT
                      </div>
                      <div
                        style={{
                          fontSize: "1.188rem",
                          color: "var(--primary-color)",
                        }}
                      >
                        {totalReceivable?.currencySymbol}{" "}
                        <FormattedNumber
                          value={totalReceivable?.current}
                          style="decimal"
                          // minimumFractionDigits={
                          //   business?.currency?.decimalPlaces
                          // }
                        />
                      </div>
                    </div>
                    <Dropdown
                      trigger="click"
                      arrow={{ pointAtCenter: true }}
                      placement="bottom"
                      overlayClassName="db-dropdown"
                      menu={{
                        items: receivableDropdownItems?.map((item) => ({
                          ...item,
                          onClick: ({ key }) => {
                            navigate("/reports/apAgingDetails", {
                              //temp
                              state: {
                                ...location.state,
                                from: { pathname: location.pathname },
                              },
                            });
                          },
                        })),
                      }}
                    >
                      <div className="db-overdue">
                        <div
                          style={{
                            fontSize: "var(--small-text)",
                            color: "var(--orange)",
                          }}
                        >
                          OVERDUE
                        </div>
                        <Space>
                          <div
                            style={{
                              fontSize: "1.188rem",
                            }}
                          >
                            {totalReceivable?.currencySymbol}{" "}
                            <FormattedNumber
                              value={totalReceivable?.total}
                              style="decimal"
                              // minimumFractionDigits={
                              //   business?.currency?.decimalPlaces
                              // }
                            />
                          </div>
                          <CaretDownFilled />
                        </Space>
                      </div>
                    </Dropdown>
                  </Flex>
                </>
              )}
            </div>
          </div>
        </Col>
        <Col span={12} style={{ paddingLeft: "1rem" }}>
          <div className="db-card">
            <div className="db-card-header">
              <span className="title">Total Payables</span>
              <Dropdown
                trigger="click"
                menu={{
                  onClick: ({ key }) => {
                    if (key === "0")
                      navigate("bills/new", {
                        state: {
                          ...location.state,
                          from: { pathname: location.pathname },
                        },
                      });
                    else if (key === "1")
                      navigate("paymentsMade/new", {
                        state: {
                          ...location.state,
                          from: { pathname: location.pathname },
                        },
                      });
                  },
                  items: [
                    {
                      label: (
                        <Space>
                          <PlusCircleTwoTone twoToneColor="rgb(75, 192, 192)" />
                          <FormattedMessage
                            id="button.newBill"
                            defaultMessage="New Bill"
                          />
                        </Space>
                      ),
                      key: "0",
                    },
                    {
                      label: (
                        <Space>
                          <PlusCircleTwoTone twoToneColor="rgb(75, 192, 192)" />
                          <FormattedMessage
                            id="button.newSupplierPayment"
                            defaultMessage="New Supplier Payment"
                          />
                        </Space>
                      ),
                      key: "1",
                    },
                  ],
                }}
              >
                <Button
                  type="link"
                  icon={<PlusCircleFilled />}
                  style={{ padding: 0 }}
                >
                  <span>
                    <FormattedMessage id="button.new" defaultMessage="New" />
                  </span>
                </Button>
              </Dropdown>
            </div>
            <div className="db-card-body">
              {payReceiveLoading ? (
                <Flex
                  justify="center"
                  align="center"
                  style={{ minHeight: "3.537rem" }}
                >
                  <Spin />
                </Flex>
              ) : (
                <>
                  {/* <div className="db-unpaid">
                    Total Unpaid Bills MMK 1009090090
                  </div> */}
                  <Flex justify="space-between" style={{ paddingTop: "10px" }}>
                    <div
                      className="db-current"
                      onClick={() => {
                        navigate("/reports/apAgingDetails", {
                          state: {
                            ...location.state,
                            from: { pathname: location.pathname },
                            initialPeriod: "today",
                          },
                        });
                      }}
                    >
                      <div
                        style={{
                          fontSize: "var(--small-text)",
                          color: "var(--primary-color)",
                        }}
                      >
                        CURRENT
                      </div>
                      <div style={{ fontSize: "1.188rem" }}>
                        {totalPayable?.currencySymbol}{" "}
                        <FormattedNumber
                          value={totalPayable?.current}
                          style="decimal"
                          // minimumFractionDigits={
                          //   business?.currency?.decimalPlaces
                          // }
                        />
                      </div>
                    </div>
                    <Dropdown
                      trigger="click"
                      arrow={{ pointAtCenter: true }}
                      placement="bottom"
                      overlayClassName="db-dropdown"
                      menu={{
                        items: payableDropdownItems?.map((item) => ({
                          ...item,
                          onClick: ({ key }) => {
                            navigate("/reports/apAgingDetails", {
                              //temp
                              state: {
                                ...location.state,
                                from: { pathname: location.pathname },
                              },
                            });
                          },
                        })),
                      }}
                    >
                      <div className="db-overdue">
                        <div
                          style={{
                            fontSize: "var(--small-text)",
                            color: "var(--orange)",
                          }}
                        >
                          OVERDUE
                        </div>
                        <Space>
                          <div style={{ fontSize: "1.188rem" }}>
                            {totalPayable?.currencySymbol}{" "}
                            <FormattedNumber
                              value={totalPayable?.total}
                              style="decimal"
                              // minimumFractionDigits={
                              //   business?.currency?.decimalPlaces
                              // }
                            />
                          </div>
                          <CaretDownFilled />
                        </Space>
                      </div>
                    </Dropdown>
                  </Flex>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="db-row">
        <div className="db-card">
          <div className="db-card-header">
            <span className="title">Cash Flow</span>
            <Dropdown
              trigger="click"
              menu={{
                items: filterCFOptions.map((item) => ({
                  ...item,
                  onClick: ({ key }) => handleFilterCFChange(key),
                })),
                selectable: true,
                selectedKeys: [filterCF.key],
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <Space>
                  {filterCF.label}
                  <DownOutlined
                    style={{
                      fontSize: "0.9rem",
                      color: "var(--primary-color)",
                    }}
                  />
                </Space>
              </div>
            </Dropdown>
          </div>
          <div className="db-card-body" style={{ padding: "5px" }}>
            <Row className="line-chart-row">
              <Col span={18} className="line-chart-container">
                {cashFlowLoading ? (
                  <Flex
                    justify="center"
                    align="center"
                    style={{ height: "257px" }}
                  >
                    <Spin />
                  </Flex>
                ) : (
                  <Line data={lineData} options={lineOptions} />
                )}
              </Col>
              <Col span={8} className="balance-container text-align-right">
                {cashFlowLoading ? (
                  <Flex
                    align="center"
                    justify="center"
                    style={{ height: "100%" }}
                  >
                    <Spin />
                  </Flex>
                ) : (
                  <>
                    <div className="balance-row">
                      <div style={{ opacity: "70%" }}>
                        Cash as on {firstMonth}
                      </div>
                      <div className="balance">
                        {business?.baseCurrency?.symbol}{" "}
                        <FormattedNumber
                          value={totalCashFlow?.totalOpeningBalance}
                          style="decimal"
                          minimumFractionDigits={
                            business?.currency?.decimalPlaces
                          }
                        />
                      </div>
                    </div>
                    <div className="balance-row">
                      <div style={{ color: "var(--dark-green)" }}>Incoming</div>
                      <div className="balance">
                        {business?.baseCurrency?.symbol}{" "}
                        <FormattedNumber
                          value={totalCashFlow?.totalIncomingAmount}
                          style="decimal"
                          minimumFractionDigits={
                            business?.currency?.decimalPlaces
                          }
                        />
                      </div>
                    </div>
                    <div className="balance-row">
                      <div style={{ color: "var(--red)" }}>Outgoing</div>
                      <div className="balance">
                        {business?.baseCurrency?.symbol}{" "}
                        <FormattedNumber
                          value={totalCashFlow?.totalOutgoingAmount}
                          style="decimal"
                          minimumFractionDigits={
                            business?.currency?.decimalPlaces
                          }
                        />
                      </div>
                    </div>
                    <div className="balance-row">
                      <div style={{ color: "rgba(75,192,192,1)" }}>
                        Cash as on {lastMonth}
                      </div>
                      <div className="balance">
                        {business?.baseCurrency?.symbol}{" "}
                        <FormattedNumber
                          value={totalCashFlow?.totalEndingBalance}
                          style="decimal"
                          minimumFractionDigits={
                            business?.currency?.decimalPlaces
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Row>
      <Row>
        <Col span={12} style={{ paddingRight: "1rem" }}>
          <div className="db-card">
            <div className="db-card-header">
              <span className="title">Income and Expense</span>
              <span>
                <Dropdown
                  trigger="click"
                  menu={{
                    items: filterIEOptions.map((item) => ({
                      ...item,
                      onClick: ({ key }) => handleFilterIEChange(key),
                    })),
                    selectable: true,
                    selectedKeys: [filterIE.key],
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Space>
                      {filterIE.label}
                      <DownOutlined
                        style={{
                          fontSize: "0.9rem",
                          color: "var(--primary-color)",
                        }}
                      />
                    </Space>
                  </div>
                </Dropdown>
              </span>
            </div>

            <div className="db-card-body bar-chart-container">
              <Flex
                align="center"
                justify="center"
                style={{ width: "100%", height: "230px" }}
              >
                {incomeExpenseLoading ? (
                  <Spin />
                ) : (
                  <Bar data={barData} options={barOptions} />
                )}
              </Flex>
              <Row className="total-section" style={{ minHeight: "59px" }}>
                <Col
                  span={12}
                  style={{
                    padding: "10px",
                    borderRight: "1px solid var(--border-color)",
                  }}
                >
                  {incomeExpenseLoading ? (
                    <Flex
                      justify="center"
                      align="center"
                      style={{ height: "100%" }}
                    >
                      <Spin />
                    </Flex>
                  ) : (
                    <>
                      <div style={{ color: "var(--light-green)" }}>
                        Total Income
                      </div>
                      <div style={{ fontSize: "1.188rem" }}>
                        {business?.baseCurrency?.symbol}{" "}
                        <FormattedNumber
                          value={totalIncomeExpense?.totalIncome}
                          style="decimal"
                          minimumFractionDigits={
                            business?.currency?.decimalPlaces
                          }
                        />
                      </div>
                    </>
                  )}
                </Col>
                <Col span={12} style={{ padding: "10px" }}>
                  {incomeExpenseLoading ? (
                    <Flex
                      justify="center"
                      align="center"
                      style={{ height: "100%" }}
                    >
                      <Spin />
                    </Flex>
                  ) : (
                    <>
                      <div style={{ color: "var(--red)" }}>Total Expense</div>
                      <div style={{ fontSize: "1.188rem" }}>
                        {business?.baseCurrency?.symbol}{" "}
                        <FormattedNumber
                          value={totalIncomeExpense?.totalExpense}
                          style="decimal"
                          minimumFractionDigits={
                            business?.currency?.decimalPlaces
                          }
                        />
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={12} style={{ paddingLeft: "1rem" }}>
          <div className="db-card">
            <div className="db-card-header">
              <span className="title">Top Expenses</span>
              <span>
                <Dropdown
                  trigger="click"
                  menu={{
                    items: filterEOptions.map((item) => ({
                      ...item,
                      onClick: ({ key }) => handleFilterEChange(key),
                    })),
                    selectable: true,
                    selectedKeys: [filterE.key],
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Space>
                      {filterE.label}
                      <DownOutlined
                        style={{
                          fontSize: "0.9rem",
                          color: "var(--primary-color)",
                        }}
                      />
                    </Space>
                  </div>
                </Dropdown>
              </span>
            </div>
            <div className="db-card-body pie-chart-container">
              {topExpenseLoading ? (
                <Flex>
                  <Spin />
                </Flex>
              ) : topExpenses?.length > 0 ? (
                <Pie data={pieData} options={pieOptions}></Pie>
              ) : (
                <span>No data</span>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
