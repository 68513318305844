import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export function useHistoryState(key, initialValue) {
  const navigate = useNavigate();
  const location = useLocation();
  const [rawState, rawSetState] = useState(() => {
    const value = location.state?.[key];
    return value ?? initialValue;
  });
  function setState(value) {
    navigate(location.pathname, {
      state: {
        ...location.state,
        [key]: value,
      },
      replace: true,
    });
    rawSetState(value);
  }
  return [rawState, setState];
}

export function paginateArray(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function convertTransactionType(type) {
  switch (type) {
    case "JN":
      return "Journal";
    case "IV":
      return "Invoice";
    case "CP":
      return "CustomerPayment";
    case "CN":
      return "CreditNote";
    case "CNR":
      return "CreditNoteRefund";
    case "EP":
      return "Expense";
    case "BL":
      return "Bill";
    case "SP":
      return "SupplierPayment";
    case "BP":
      return "BillPayment";
    case "IVT":
      return "Inventory";
    case "IVAQ":
      return "InventoryAdjustmentQuantity";
    case "IVAV":
      return "InventoryAdjustmentValue";
    case "IWO":
      return "InvoiceWriteOff";
    case "ACP":
      return "AdvanceCustomerPayment";
    case "ASP":
      return "AdvanceSupplierPayment";
    case "COB":
      return "CustomerOpeningBalance";
    case "SOB":
      return "SupplierOpeningBalance";
    case "OB":
      return "OpeningBalance";
    case "SC":
      return "SupplierCredit";
    case "AC":
      return "AccountTransfer";
    case "AD":
      return "AccountDeposit";
    case "OD":
      return "OwnerDrawing";
    case "OC":
      return "OwnerContribution";
    case "OI":
      return "Income";
    case "SAA":
      return "SupplierAdvanceApplied";
    case "SAR":
      return "SupplierAdvanceRefund";
    case "SCR":
      return "SupplierCreditRefund";
    case "CAA":
      return "CustomerAdvanceApplied";
    case "CAR":
      return "CustomerAdvanceRefund";
    case "POS":
      return "ProductOpeningStock";
    case "PGOS":
      return "ProductGroupOpeningStock";
    case "PCOS":
      return "ProductCompositeOpeningStock";
    case "TO":
      return "TransferOrder";
    default:
      return "";
  }
}

export function reverseConvertTransactionType(description) {
  switch (description) {
    case "Journal":
      return "JN";
    case "Invoice":
      return "IV";
    case "CustomerPayment":
      return "CP";
    case "CreditNote":
      return "CN";
    case "CreditNoteRefund":
      return "CNR";
    case "Expense":
      return "EP";
    case "Bill":
      return "BL";
    case "SupplierPayment":
      return "SP";
    case "BillPayment":
      return "BP";
    case "Inventory":
      return "IVT";
    case "InventoryAdjustment":
      return "IVA";
    case "InvoiceWriteOff":
      return "IWO";
    case "AdvanceCustomerPayment":
      return "ACP";
    case "AdvanceSupplierPayment":
      return "ASP";
    case "CustomerOpeningBalance":
      return "COB";
    case "SupplierOpeningBalance":
      return "SOB";
    case "OpeningBalance":
      return "OB";
    case "SupplierCredit":
      return "SC";
    case "TransferFromAnotherAccounts":
      return "AC";
    case "TransferToAnotherAccount":
      return "AC";
    case "DepositFromAnotherAccounts":
      return "AD";
    case "DepositToAnotherAccounts":
      return "AD";
    case "AccountDeposit":
      return "AD";
    case "OwnerDrawings":
      return "OD";
    case "OwnerContribution":
      return "OC";
    case "OtherIncome":
      return "OI";
    case "InterestIncome":
      return "OI";
    case "SupplierAdvance":
      return "ASP";
    case "CustomerAdvance":
      return "ACP";
    case "SupplierAdvanceApplied":
      return "SAA";
    case "SupplierAdvanceRefund":
      return "SAR";
    case "SupplierCreditRefund":
      return "SCR";
    case "CustomerAdvanceApplied":
      return "CAA";
    case "CustomerAdvanceRefund":
      return "CAR";
    case "ProductOpeningStock":
      return "POS";
    case "ProductGroupOpeningStock":
      return "PGOS";
    case "ProductCompositeOpeningStock":
      return "PCOS";

    default:
      return "";
  }
}

export function calculateTaxAmount(
  subTotal,
  taxRate,
  isTaxInclusive,
  decimalPlaces = 2
) {
  let taxAmount = 0;
  if (isTaxInclusive) {
    taxAmount = (subTotal / (taxRate + 100)) * taxRate;
  } else {
    taxAmount = (subTotal / 100) * taxRate;
  }
  return parseFloat(taxAmount.toFixed(decimalPlaces));
}

export function calculateDiscountAmount(
  subTotal,
  discount,
  discountType,
  decimalPlaces = 2
) {
  let discountAmount = 0;
  if (discount > 0 && discountType != null) {
    if (discountType === "P") {
      discountAmount = (subTotal / 100) * discount;
    } else {
      discountAmount = discount || 0;
    }
  }
  return parseFloat(discountAmount.toFixed(decimalPlaces));
}

export function calculateItemDiscountAndTax(
  qty,
  unitRate,
  discount,
  discountType,
  taxRate,
  isTaxInclusive,
  decimalPlaces = 2
) {
  // calculate detail subtotal
  let detailAmount = qty * unitRate;
  // calculate discount amount
  let discountAmount = 0;
  if (discount > 0 && discountType != null) {
    discountAmount = calculateDiscountAmount(
      detailAmount,
      discount,
      discountType,
      decimalPlaces
    );
  }
  // calculate subtotal amount
  let totalAmount = qty * unitRate - discountAmount;
  // calculate tax amount
  let taxAmount = 0;
  if (taxRate > 0) {
    taxAmount = calculateTaxAmount(
      totalAmount,
      taxRate,
      isTaxInclusive,
      decimalPlaces
    );
  }
  return [
    parseFloat(totalAmount.toFixed(decimalPlaces)),
    discountAmount,
    taxAmount,
  ];
}
