import { gql } from "@apollo/client";

const CREATE_ROLE = gql`
  mutation CreateRole($input: NewRole!) {
    createRole(input: $input) {
      id
      businessId
      name
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation UpdateRole($id: ID!, $input: NewRole!) {
    updateRole(id: $id, input: $input) {
      id
      businessId
      name
      createdAt
      updatedAt
    }
  }
`;

const DELETE_ROLE = gql`
  mutation DeleteRole($id: ID!) {
    deleteRole(id: $id) {
      id
      businessId
      name
      createdAt
      updatedAt
    }
  }
`;

const RoleMutations = {
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
};

export default RoleMutations;
