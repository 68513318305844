import moment from "moment";

export const QUERY_DATA_LIMIT = 10;

export const MODAL_QUERY_DATA_LIMIT = 5;

export const DETAILED_GENERAL_LEDGER_LIMIT = 2;

export const REPORT_DATE_FORMAT = "DD MMM YYYY";

export const DATE_STRING_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const SYSTEM_ACCOUNT_CODE_INVENTORY_ASSET = "106";
export const SYSTEM_ACCOUNT_CODE_SALES = "401";
export const SYSTEM_ACCOUNT_CODE_COGS = "506";

export const REPORT_DEFAULTS = {
  branchId: 0,
  warehouseId: 0,
  reportBasis: "Accrual",
  period: "thisMonth",
  fromDate: moment().startOf("month").utc(true),
  toDate: moment().endOf("month").utc(true),
};
