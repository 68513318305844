/* eslint-disable react/style-prop-object */
import React, { useMemo } from "react";
import { Spin, Flex, Empty } from "antd";
import { ReportQueries } from "../../../graphql";
import { useQuery, useReadQuery } from "@apollo/client";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { openErrorNotification } from "../../../utils/Notification";
import { useOutletContext, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";
import { useNavigate } from "react-router-dom";

const { GET_INVENTORY_VALUATION_SUMMARY_REPORT } = ReportQueries;

const InventoryValuationSummaryReport = () => {
  const { notiApi, business, allWarehousesQueryRef } = useOutletContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const toDateParam = searchParams.get("toDate");
  const reportBasisParam = searchParams.get("reportBasis");
  const warehouseIdParam = searchParams.get("warehouseId");

  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const reportBasis = reportBasisParam ? reportBasisParam : "Accrual";
  const warehouseId = warehouseIdParam ? parseInt(warehouseIdParam) : 0;

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_INVENTORY_VALUATION_SUMMARY_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      currentDate: toDate.format(DATE_STRING_FORMAT),
      warehouseId,
      reportType: reportBasis,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const queryData = useMemo(
    () => data?.getInventoryValuationSummaryReport,
    [data]
  );

  const totals = useMemo(() => {
    return queryData?.reduce(
      (acc, curr) => {
        acc.assetValue += curr.assetValue || 0;
        return acc;
      },
      {
        assetValue: 0,
      }
    );
  }, [queryData]);

  const handleRowClick = (data) => {
    const params = new URLSearchParams({
      period: "custom",
      reportBasis,
      fromDate: moment(toDate).startOf("year").utc(true),
      toDate: toDateParam ? toDateParam : REPORT_DEFAULTS.toDate,
      warehouseId,
      productId: data.productId,
      productType: data.productType,
      productName: data.productName,
      sku: data.sku,
    });

    navigate(`/reports/inventoryValuationByItem?${params.toString()}`);
  };

  const { data: warehouseData } = useReadQuery(allWarehousesQueryRef);

  const warehouse = useMemo(() => {
    return warehouseData?.listAllWarehouse?.find((w) => w.id === warehouseId);
  }, [warehouseData, warehouseId]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          hasFromDate={false}
          filterConfig={{ warehouse: true }}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>
              Inventory Valuation Summary Report
            </h3>
            {/* <span>Basis: {reportBasis}</span> */}
            {warehouse && <span>Warehouse: {warehouse?.name}</span>}
            <h5>As of {toDate.format(REPORT_DATE_FORMAT)}</h5>
          </div>
          {queryLoading ? (
            <Flex justify="center" align="center" style={{ height: "40vh" }}>
              <Spin size="large" />
            </Flex>
          ) : (
            <div className="fill-container table-container">
              <table className="rep-table">
                <thead>
                  <tr>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.productName"
                        defaultMessage="productName"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage id="label.sku" defaultMessage="SKU" />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.stockOnHand"
                        defaultMessage="Stock On Hand"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.inventoryAssetValue"
                        defaultMessage="Inventory Asset Value"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {queryData?.length > 0 ? (
                    queryData?.map((data, index) => {
                      return (
                        <tr key={index} onClick={() => handleRowClick(data)}>
                          <td>{data.productName}</td>
                          <td>{data.sku}</td>

                          <td className="text-align-right">
                            <FormattedNumber
                              value={data.stockOnHand}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />{" "}
                            {data.unit}
                          </td>
                          <td className="text-align-right">
                            <a>
                              <FormattedNumber
                                value={data.assetValue}
                                style="decimal"
                                minimumFractionDigits={
                                  business.baseCurrency.decimalPlaces
                                }
                              />
                            </a>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="empty-row">
                      <td
                        colSpan={4}
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--border-color)",
                        }}
                      >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <b>
                        <FormattedMessage
                          id="label.total"
                          defaultMessage="Total"
                        ></FormattedMessage>
                      </b>
                    </td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.assetValue || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div style={{ paddingLeft: "1.5rem" }}>
          <FormattedMessage
            values={{ currency: business.baseCurrency.symbol }}
            id="label.displayedBaseCurrency"
            defaultMessage="**Amount is displayed in {currency}"
          />
        </div>
      </div>
    </ReportLayout>
  );
};

export default InventoryValuationSummaryReport;
