import { gql } from "@apollo/client";

const UPDATE_OPENING_BALANCE = gql`
  mutation UpdateOpeningBalance($input: NewOpeningBalance!) {
    updateOpeningBalance(input: $input) {
      id
      businessId
      migrationDate
      details {
        id
        openingBalanceId
        debit
        credit
        account {
          id
          name
          detailType
          mainType
        }
      }
      branch {
        id
        name
      }
    }
  }
`;
const OpeningBalanceMutations = {
  UPDATE_OPENING_BALANCE,
};

export default OpeningBalanceMutations;
