import React, { useState, useEffect, useMemo } from "react";
import { Dropdown, Form, Divider, Space, DatePicker, Button } from "antd";
import { CalendarOutlined, DownOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import dayjs from "dayjs";
const items = [
  {
    key: "today",
    label: <FormattedMessage id="period.today" defaultMessage="Today" />,
  },
  {
    key: "thisWeek",
    label: <FormattedMessage id="period.thisWeek" defaultMessage="This Week" />,
  },
  {
    key: "thisMonth",
    label: (
      <FormattedMessage id="period.thisMonth" defaultMessage="This Month" />
    ),
  },
  {
    key: "thisQuarter",
    label: (
      <FormattedMessage id="period.thisQuarter" defaultMessage="This Quarter" />
    ),
  },
  {
    key: "thisYear",
    label: <FormattedMessage id="period.thisYear" defaultMessage="This Year" />,
  },
  {
    key: "yesterday",
    label: (
      <FormattedMessage id="period.yesterday" defaultMessage="Yesterday" />
    ),
  },
  {
    key: "previousWeek",
    label: (
      <FormattedMessage
        id="period.perviousWeek"
        defaultMessage="Previous Week"
      />
    ),
  },
  {
    key: "previousMonth",
    label: (
      <FormattedMessage
        id="period.previousMonth"
        defaultMessage="Previous Month"
      />
    ),
  },
  {
    key: "previousQuarter",
    label: (
      <FormattedMessage
        id="period.previousQuarter"
        defaultMessage="Previous Quarter"
      />
    ),
  },
  {
    key: "previousYear",
    label: (
      <FormattedMessage
        id="period.previousYear"
        defaultMessage="Previous Year"
      />
    ),
  },
  {
    key: "custom",
    label: <FormattedMessage id="period.custom" defaultMessage="Custom" />,
  },
];

const PeriodDropdown = ({
  refetch,
  hasFromDate = true,
  setFromDate,
  setToDate,
  setIsButtonDisabled,
  setSelectedPeriod,
  selectedPeriod,
  fromDate,
  period,
  toDate,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [form] = Form.useForm();
  const [userInteracted, setUserInteracted] = useState(false);
  // console.log("TO DATE", toDate);

  const getPeriod = (period) => {
    const validPeriods = [
      "today",
      "thisWeek",
      "thisMonth",
      "thisQuarter",
      "thisYear",
      "yesterday",
      "previousWeek",
      "previousMonth",
      "previousQuarter",
      "previousYear",
      "custom",
    ];

    if (validPeriods.includes(period)) {
      return items.find((item) => item.key === period);
    }

    return items.find((item) => item.key === "thisMonth");
  };

  const handlePeriodChange = (key) => {
    const selectedFilter = items.find((option) => option.key === key);
    setSelectedPeriod(selectedFilter.key);
    setUserInteracted(true);
    if (key !== "custom") {
      setDropdownOpen(false);
      setIsButtonDisabled(false);
    }
  };

  const handleDateApply = () => {
    if (hasFromDate) {
      const dateRange = form.getFieldValue("dateRange");
      if (dateRange && dateRange.length === 2) {
        const [fromDate, toDate] = dateRange;
        setFromDate(fromDate);
        setToDate(toDate);
        // refetch({
        //   fromDate: fromDate,
        //   toDate: toDate,
        // });
        // isPaginated && setCurrentPage(1);
        setDropdownOpen(false);
      }
    } else {
      const toDate = form.getFieldValue("endDate");
      if (toDate) {
        setToDate(toDate);
        // refetch({
        //   toDate: toDate,
        // });
        // isPaginated && setCurrentPage(1);
        setDropdownOpen(false);
      }
    }
    setIsButtonDisabled(false);
  };

  // const handleGenerateReport = () => {
  //   onGenerateReport(selectedPeriod);
  //   setDropdownOpen(false);
  // };

  useMemo(() => {
    if (period && period === "custom") {
      const initialValue = hasFromDate
        ? { dateRange: [dayjs(fromDate), dayjs(toDate)] }
        : { endDate: dayjs(toDate) };
      form.setFieldsValue(initialValue);
    } else {
      form.resetFields();
    }
  }, [form, fromDate, toDate, hasFromDate, period]);

  useEffect(() => {
    const updateDates = () => {
      let fiscalYear = "Jan";
      let currentMonth = moment().month();
      let fiscalStartMonth = moment().month(fiscalYear.substring(0, 3)).month();
      let quarter = Math.floor(
        ((currentMonth < fiscalStartMonth ? currentMonth + 12 : currentMonth) -
          fiscalStartMonth) /
          3
      );
      let quarterStartMonth = (fiscalStartMonth + quarter * 3) % 12;
      let quarterEndMonth = (fiscalStartMonth + 2 + quarter * 3) % 12;

      let fromDate, toDate;
      switch (selectedPeriod) {
        case "today":
          fromDate = moment().startOf("day").utc(true);
          toDate = moment().endOf("day").utc(true);
          break;
        case "thisWeek":
          fromDate = moment().startOf("week").startOf("day").utc(true);
          toDate = moment().endOf("week").endOf("day").utc(true);
          break;
        case "thisMonth":
          fromDate = moment().startOf("month").startOf("day").utc(true);
          toDate = moment().endOf("month").endOf("day").utc(true);
          break;
        case "thisQuarter":
          fromDate = moment()
            .subtract(currentMonth < quarterStartMonth ? 1 : 0, "year")
            .month(quarterStartMonth)
            .startOf("month")
            .startOf("day")
            .utc(true);
          toDate = moment()
            .add(quarterEndMonth < currentMonth ? 1 : 0, "year")
            .month(quarterEndMonth)
            .endOf("month")
            .endOf("day")
            .utc(true);
          break;
        case "thisYear":
          if (fiscalStartMonth > currentMonth) {
            fromDate = moment()
              .subtract(1, "year")
              .month(fiscalStartMonth)
              .startOf("month")
              .startOf("day")
              .utc(true);
            toDate = moment()
              .month(fiscalStartMonth - 1)
              .endOf("month")
              .endOf("day")
              .utc(true);
          } else {
            fromDate = moment()
              .month(fiscalStartMonth)
              .startOf("month")
              .startOf("day")
              .utc(true);
            toDate = moment()
              .add(1, "year")
              .month(fiscalStartMonth - 1)
              .endOf("month")
              .endOf("day")
              .utc(true);
          }
          break;
        case "yesterday":
          fromDate = moment().subtract(1, "day").startOf("day").utc(true);
          toDate = moment().subtract(1, "day").endOf("day").utc(true);
          break;
        case "previousWeek":
          fromDate = moment()
            .subtract(1, "week")
            .startOf("week")
            .startOf("day")
            .utc(true);
          toDate = moment()
            .subtract(1, "week")
            .endOf("week")
            .endOf("day")
            .utc(true);
          break;
        case "previousMonth":
          fromDate = moment()
            .subtract(1, "month")
            .startOf("month")
            .startOf("day")
            .utc(true);
          toDate = moment()
            .subtract(1, "month")
            .endOf("month")
            .endOf("day")
            .utc(true);
          break;
        case "previousQuarter":
          fromDate = moment()
            .subtract(currentMonth < quarterStartMonth ? 1 : 0, "year")
            .month(quarterStartMonth - 3)
            .startOf("month")
            .startOf("day")
            .utc(true);
          toDate = moment()
            .add(quarterEndMonth < currentMonth ? 1 : 0, "year")
            .month(quarterEndMonth - 3)
            .endOf("month")
            .endOf("day")
            .utc(true);
          break;
        case "previousYear":
          if (fiscalStartMonth > currentMonth) {
            fromDate = moment()
              .subtract(2, "year")
              .month(fiscalStartMonth)
              .startOf("month")
              .startOf("day")
              .utc(true);
            toDate = moment()
              .subtract(1, "year")
              .month(fiscalStartMonth - 1)
              .endOf("month")
              .utc(true);
          } else {
            fromDate = moment()
              .subtract(1, "year")
              .month(fiscalStartMonth)
              .startOf("month")
              .startOf("day")
              .utc(true);
            toDate = moment()
              .month(fiscalStartMonth - 1)
              .endOf("month")
              .endOf("day")
              .utc(true);
          }
          break;
        default:
          fromDate = moment().startOf("month").startOf("day").utc(true);
          toDate = moment().endOf("month").endOf("day").utc(true);
          break;
      }

      if (hasFromDate) {
        setFromDate(fromDate);
        setToDate(toDate);
      } else {
        setToDate(toDate);
      }
    };

    if (selectedPeriod === "custom") {
      setShowDateRange(true);
      if (userInteracted) {
        setDropdownOpen(true);
      }
    } else {
      setShowDateRange(false);
      updateDates();
    }
  }, [
    selectedPeriod,
    setFromDate,
    setToDate,
    refetch,
    hasFromDate,
    userInteracted,
  ]);

  return (
    <Dropdown
      trigger="click"
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
      menu={{
        items: items?.map((item) => ({
          ...item,
          onClick: ({ key }) => handlePeriodChange(key),
        })),
        selectable: true,
        selectedKeys: [selectedPeriod],
      }}
      dropdownRender={(menu) => (
        <div
          style={{
            minWidth: "11.686rem",
            maxWidth: "21rem",
            borderRadius: "8px",
            boxShadow:
              "0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)",
          }}
        >
          {React.cloneElement(menu, {
            style: { boxShadow: "none" },
          })}

          {showDateRange && (
            <Form form={form}>
              <Divider
                style={{
                  margin: 0,
                }}
              />
              <Space
                style={{
                  padding: 8,
                }}
              >
                <Form.Item
                  className="report-datepicker"
                  name={hasFromDate ? "dateRange" : "endDate"}
                  style={{ margin: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  {hasFromDate ? (
                    <DatePicker.RangePicker />
                  ) : (
                    <DatePicker placeholder="Date" />
                  )}
                </Form.Item>
                <Button
                  type="primary"
                  onClick={() => {
                    form.submit();
                    handleDateApply();
                  }}
                >
                  <FormattedMessage id="button.apply" defaultMessage="Apply" />
                </Button>
              </Space>
            </Form>
          )}
        </div>
      )}
    >
      <div
        style={{
          display: "flex",
          gap: "8px",
          height: "2rem",
          alignItems: "center",
          border: "1px solid var(--border-color)",
          paddingInline: "1rem",
          cursor: "pointer",
          borderRadius: "0.3rem",
          justifyContent: "space-between",
          minWidth: "10rem",
        }}
      >
        <Space>
          <CalendarOutlined />
          {getPeriod(selectedPeriod).label}
        </Space>
        <DownOutlined style={{ width: "0.7rem", height: "0.7rem" }} />
      </div>
    </Dropdown>
  );
};

export default PeriodDropdown;
