/* eslint-disable react/style-prop-object */
import React, { useMemo, useState } from "react";
import { Spin, Flex, Empty, Divider } from "antd";
import { ReportQueries } from "../../../graphql";
import { useQuery, useReadQuery } from "@apollo/client";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { openErrorNotification } from "../../../utils/Notification";
import { useOutletContext, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";
import dayjs from "dayjs";

const { GET_INVOICE_DETAIL_REPORT } = ReportQueries;

const InvoiceDetails = () => {
  const { notiApi, business, allWarehousesQueryRef, allBranchesQueryRef } =
    useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const warehouseIdParam = searchParams.get("warehouseId");
  const reportBasisParam = searchParams.get("reportBasis");

  const fromDate = fromDateParam
    ? moment(fromDateParam).utc()
    : REPORT_DEFAULTS.fromDate;
  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const branchId = branchIdParam ? parseInt(branchIdParam) : 0;
  const warehouseId = warehouseIdParam ? parseInt(warehouseIdParam) : 0;
  const reportBasis = reportBasisParam ? reportBasisParam : "Accrual";

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_INVOICE_DETAIL_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      fromDate: fromDate.format(DATE_STRING_FORMAT),
      toDate: toDate.format(DATE_STRING_FORMAT),
      branchId,
      reportType: reportBasis,
      warehouseId,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });
  const queryData = useMemo(() => data?.getSalesInvoiceDetailReport, [data]);

  const totals = useMemo(() => {
    return queryData?.reduce(
      (acc, curr) => {
        acc.totalAmount += curr.totalAmount || 0;
        acc.remainingBalance += curr.remainingBalance || 0;
        return acc;
      },
      { totalAmount: 0, remainingBalance: 0 }
    );
  }, [queryData]);

  const getStatusColor = (status) => {
    let color = "";

    if (status === "Paid") {
      color = "var(--dark-green)";
    } else if (status === "Confirmed") {
      color = "var(--blue)";
    } else if (status === "Overdue") {
      color = "var(--orange)";
    }

    return color;
  };

  const { data: warehouseData } = useReadQuery(allWarehousesQueryRef);
  const { data: branchData } = useReadQuery(allBranchesQueryRef);

  const warehouse = useMemo(() => {
    return warehouseData?.listAllWarehouse?.find((w) => w.id === warehouseId);
  }, [warehouseData, warehouseId]);

  const branch = useMemo(() => {
    return branchData?.listAllBranch?.find((w) => w.id === branchId);
  }, [branchData, branchId]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          filterConfig={{
            branch: true,
            warehouse: true,
          }}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>
              <FormattedMessage
                id="report.invoiceDetails"
                defaultMessage="Invoice Details"
              />
            </h3>
            {/* <span>Basis: {reportBasis}</span> */}
            {branch && <p>Branch: {branch?.name}</p>}
            {warehouse && <p>Warehouse: {warehouse?.name}</p>}
            <h5>
              From {fromDate.format(REPORT_DATE_FORMAT)} To{" "}
              {toDate.format(REPORT_DATE_FORMAT)}
            </h5>
          </div>
          {queryLoading ? (
            <Flex justify="center" align="center" style={{ height: "40vh" }}>
              <Spin size="large" />
            </Flex>
          ) : (
            <div className="fill-container table-container">
              <table className="rep-table ">
                <thead>
                  <tr>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.status"
                        defaultMessage="Status"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.invoiceDate"
                        defaultMessage="Invoice Date"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.dueDate"
                        defaultMessage="Due Date"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.invoiceNumber"
                        defaultMessage="Invoice Number"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.customerName"
                        defaultMessage="Customer Name"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.invoiceAmountFcy"
                        defaultMessage="Invoice Amount (FCY)"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.balanceAmount"
                        defaultMessage="Balance Amount (FCY)"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.invoiceAmount"
                        defaultMessage="Invoice Amount"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.balanceAmount"
                        defaultMessage="Balance Amount"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {queryData?.length > 0 ? (
                    queryData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              color: getStatusColor(data.invoiceStatus),
                            }}
                          >
                            {data.invoiceStatus}
                          </td>
                          <td>
                            {dayjs(data.invoiceDate).format(REPORT_DATE_FORMAT)}
                          </td>
                          <td>
                            {dayjs(data.invoiceDueDate).format(
                              REPORT_DATE_FORMAT
                            )}
                          </td>
                          <td>{data.invoiceNumber}</td>
                          <td>{data.customerName}</td>
                          <td className="text-align-right">
                            {data.currencySymbol}{" "}
                            <FormattedNumber
                              value={data.totalAmountFcy || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                          <td className="text-align-right">
                            {data.currencySymbol}{" "}
                            <FormattedNumber
                              value={data.remainingBalanceFcy || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                          <td className="text-align-right">
                            <FormattedNumber
                              value={data.totalAmount || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                          <td className="text-align-right">
                            <FormattedNumber
                              value={data.remainingBalance || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="empty-row">
                      <td
                        colSpan={12}
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--border-color)",
                        }}
                      >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <FormattedMessage
                        id="label.total"
                        defaultMessage="Total"
                      ></FormattedMessage>
                    </td>
                    <td></td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right"></td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.totalAmount || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.remainingBalance || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div style={{ paddingLeft: "1.5rem" }}>
            <FormattedMessage
              values={{ currency: business.baseCurrency.symbol }}
              id="label.displayedBaseCurrency"
              defaultMessage="**Amount is displayed in {currency}"
            />
          </div>
        </div>
      </div>
    </ReportLayout>
  );
};

export default InvoiceDetails;
