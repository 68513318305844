import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      name
      role
      modules {
        moduleName
        allowedActions
      }
      businessName
      baseCurrencyId
      baseCurrencyName
      fiscalYear
      timezone
    }
  }
`;

const CREATE_USER = gql`
  mutation Register($input: NewUser!) {
    register(input: $input) {
      username
      name
      role
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      id
      businessId
      username
      name
      email
      phone
      mobile
      imageUrl
      isActive
      role
      roleId
      branches
    }
  }
`;

const CREATE_USER_ACCOUNT = gql`
  mutation CreateUserAccount($input: NewUserAccount!) {
    createUserAccount(input: $input) {
      id
      username
      name
      email
      phone
      mobile
      imageUrl
      roleId
      branches
    }
  }
`;

const UPDATE_USER_ACCOUNT = gql`
  mutation UpdateUserAccount($id: ID!, $input: NewUserAccount!) {
    updateUserAccount(id: $id, input: $input) {
      id
      username
      name
      email
      phone
      mobile
      imageUrl
      isActive
      roleId
      branches
    }
  }
`;

const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUserAccount($userId: ID!) {
    deleteUserAccount(userId: $userId) {
      id
      username
      name
      email
      phone
      mobile
      imageUrl
      isActive
      roleId
      branches
    }
  }
`;

const TOGGLE_ACTIVE_USER_ACCOUNT = gql`
  mutation ToggleActiveUserAccount($userId: ID!, $isActive: Boolean!) {
    toggleActiveUserAccount(userId: $userId, isActive: $isActive) {
      id
      username
      name
      email
      phone
      mobile
      imageUrl
      isActive
      roleId
      branches
    }
  }
`;

const UserMutations = {
  LOGIN,
  CREATE_USER,
  CHANGE_PASSWORD,
  CREATE_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  TOGGLE_ACTIVE_USER_ACCOUNT,
};

export default UserMutations;
