import React from "react";
import Dashboard from "./Dashboard";

const HomePage = () => {
  return (
    <>
      <div className="page-header">
        <p className="page-header-text">Home</p>
      </div>
      <div className="page-content page-content-with-padding">
        <Dashboard />
      </div>
    </>
  );
};

export default HomePage;
