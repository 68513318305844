import { gql } from "@apollo/client";

const GET_ALL_USERS = gql`
  query GetAllUser {
    listAllUser {
      id
      name
      branchId
      isActive
    }
  }
`;

const GET_USER_ACCOUNTS = gql`
  query ListUserAccount {
    listUserAccount {
      id
      username
      name
      email
      phone
      mobile
      imageUrl
      isActive
      roleId
      branches
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      branch {
        id
        name
      }
      name
      phone
      mobile
      address
      country
      city
      state {
        id
        stateNameEn
        code
      }
      township {
        id
        stateCode
        townshipNameEn
        code
      }
      isActive
    }
  }
`;
const UserQueries = {
  GET_ALL_USERS,
  GET_USER_ACCOUNTS,
  GET_USER,
};

export default UserQueries;
