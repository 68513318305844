import { Button, Col, Flex, Form, Row, Select, Space, Tooltip } from "antd";
import {
  FilterOutlined,
  PlusCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useCallback, useMemo, useState } from "react";
import PeriodDropdown from "./PeriodDropdown";
import { useOutletContext } from "react-router-dom";
import { useReadQuery } from "@apollo/client";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { REPORT_DEFAULTS } from "../config/Constants";

const comparators = [
  { label: "is in", value: "isIn" },
  { label: "is not in", value: "isNotIn" },
];

const ReportFilterBar = ({
  refetch,
  isPaginated = false,
  hasFromDate = true,
  loading,
  setCurrentPage,
  setSearchParams,
  searchParams,
  filterConfig,
  multiWarehouseIds = false,
}) => {
  const [form] = Form.useForm();
  const { allBranchesQueryRef, allWarehousesQueryRef } = useOutletContext();
  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const warehouseIdParam = searchParams.get("warehouseId");
  const reportBasisParam = searchParams.get("reportBasis");
  const period = searchParams.get("period");
  const searchCriteriaParam = searchParams.get("searchCriteria");

  const [selectedBranchId, setSelectedBranchId] = useState(
    REPORT_DEFAULTS.branchId
  );
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(
    REPORT_DEFAULTS.warehouseId
  );
  const [fromDate, setFromDate] = useState(REPORT_DEFAULTS.fromDate);
  const [toDate, setToDate] = useState(REPORT_DEFAULTS.toDate);
  const [reportBasis, setReportBasis] = useState(REPORT_DEFAULTS.reportBasis);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState(REPORT_DEFAULTS.period);

  const [moreFilterOpen, setMoreFilterOpen] = useState(false);

  const [filters, setFilters] = useState([
    {
      key: Date.now(),
      field: null,
      comparator: null,
      value: null,
      data: [],
    },
  ]);

  console.log("filters", filters);
  // Queries
  const { data: branchData } = useReadQuery(allBranchesQueryRef);
  const { data: warehouseData } = useReadQuery(allWarehousesQueryRef);
  const branches = useMemo(() => {
    const activeBranches =
      branchData?.listAllBranch?.filter((branch) => branch.isActive === true) ||
      [];

    return [{ id: 0, name: "All Branches" }, ...activeBranches];
  }, [branchData]);

  const warehouses = useMemo(() => {
    const activeWarehouses =
      warehouseData?.listAllWarehouse?.filter((warehouse) => {
        if (selectedBranchId === 0) {
          return warehouse.isActive === true;
        }
        return (
          warehouse.isActive === true && warehouse.branchId === selectedBranchId
        );
      }) || [];

    return [{ id: 0, name: "All Warehouses" }, ...activeWarehouses];
  }, [warehouseData, selectedBranchId]);

  const handleGenerateReport = useCallback(async () => {
    const existingParams = Object.fromEntries(searchParams.entries());
    const newParams = {
      ...existingParams,
      reportBasis,
      period: selectedPeriod,
    };

    if (hasFromDate) {
      newParams.fromDate = fromDate;
      newParams.toDate = toDate;
    } else {
      newParams.toDate = toDate;
    }

    if (filterConfig?.branch) {
      newParams.branchId = selectedBranchId;
    }

    if (filterConfig?.warehouse) {
      const params = new URLSearchParams();
      const warehouseIds = [];

      if (multiWarehouseIds) {
        if (selectedWarehouseId === 0) {
          warehouses.forEach((warehouse) => {
            if (
              warehouse.branchId === selectedBranchId ||
              selectedBranchId === 0
            ) {
              warehouseIds.push(warehouse.id);
            }
          });
        } else {
          warehouseIds.push(selectedWarehouseId);
        }

        warehouseIds.forEach((id) => params.append("warehouseId", id));
      } else {
        params.set("warehouseId", selectedWarehouseId);
      }

      newParams.warehouseId = params.getAll("warehouseId");
    }

    //setting searchParams for more filters (searchCriteria)
    if (filterConfig?.more?.enabled && moreFilterOpen) {
      try {
        const values = await form.validateFields();

        if (filterConfig?.more?.enabled) {
          // create searchCriteria object from filters
          const searchCriteria = filters.reduce((acc, filter) => {
            const fieldKey = `field${filter.key}`;
            const filterKey = `filter${filter.key}`;

            // get the value from the form fields
            const field = values[fieldKey];
            const filterValue = values[filterKey];

            // map field and filterValue to search criteria
            if (field !== undefined && filterValue !== undefined) {
              acc[field] = filterValue;
            }

            return acc;
          }, {});

          // convert searchCriteria to URLSearchParams
          const searchCriteriaParams = new URLSearchParams(
            searchCriteria
          ).toString();

          // add searchCriteria to newParams
          newParams.searchCriteria = searchCriteriaParams;
        }
      } catch (error) {
        return;
      }
    }
    if (isPaginated) {
      setCurrentPage(1);
    }
    setSearchParams(new URLSearchParams(newParams));
    setIsButtonDisabled(true);
    setMoreFilterOpen(false);
  }, [
    moreFilterOpen,
    filters,
    form,
    hasFromDate,
    searchParams,
    reportBasis,
    selectedPeriod,
    fromDate,
    toDate,
    selectedBranchId,
    selectedWarehouseId,
    isPaginated,
    filterConfig,
    setCurrentPage,
    setSearchParams,
    warehouses,
    multiWarehouseIds,
  ]);

  const handleBranchChange = useCallback((value) => {
    setSelectedBranchId(value);
    setIsButtonDisabled(false);
    setSelectedWarehouseId(0);
  }, []);

  const handleWarehouseChange = useCallback((value) => {
    setSelectedWarehouseId(value);
    setIsButtonDisabled(false);
  }, []);

  useMemo(() => {
    setSelectedBranchId(
      branchIdParam ? parseInt(branchIdParam, 10) : REPORT_DEFAULTS.branchId
    );
    setSelectedWarehouseId(
      warehouseIdParam
        ? parseInt(warehouseIdParam, 10)
        : REPORT_DEFAULTS.warehouseId
    );
    setFromDate(fromDateParam ? fromDateParam : REPORT_DEFAULTS.fromDate);
    setToDate(toDateParam ? toDateParam : REPORT_DEFAULTS.toDate);
    setReportBasis(reportBasisParam || REPORT_DEFAULTS.reportBasis);
    setSelectedPeriod(period || REPORT_DEFAULTS.period);

    if (searchCriteriaParam) {
      const criteria = new URLSearchParams(searchCriteriaParam);
      const newFilters = [];
      criteria.forEach((value, key) => {
        if (value.length) {
          newFilters.push({
            key: Date.now(),
            field: key,
            comparator: "isIn",
            value: value.split(",").map((item) => parseInt(item, 10)),
            data:
              filterConfig?.more?.filterFields.find((field) =>
                field.options.some((option) => option.value === key)
              )?.data || [],
          });
        }
      });

      if (newFilters) {
        setFilters(newFilters);
        const parsedFilters = newFilters.reduce((acc, filter) => {
          acc[`field${filter.key}`] = filter.field;
          acc[`comparator${filter.key}`] = filter.comparator || "isIn";
          acc[`filter${filter.key}`] = filter.value || null;
          return acc;
        }, {});

        form.setFieldsValue(parsedFilters);
      }
    }
  }, [
    form,
    filterConfig,
    searchCriteriaParam,
    branchIdParam,
    warehouseIdParam,
    fromDateParam,
    toDateParam,
    reportBasisParam,
    period,
  ]);

  const addFilter = () => {
    setFilters([...filters, { key: Date.now() }]);
  };

  const removeFilter = (key) => {
    setFilters(filters.filter((filter) => filter.key !== key));
  };

  const handleFieldChange = (key, value) => {
    const selectedField = filterConfig?.more?.filterFields.find((field) =>
      field.options.some((option) => option.value === value)
    );
    const selectedData = selectedField?.data || [];

    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === key
          ? { ...filter, field: value, data: selectedData }
          : filter
      )
    );
    form.resetFields([`filter${key}`]);
  };

  return (
    <div className="report-filter-bar">
      <Space size="large">
        <Space>
          <span>
            <FilterOutlined />
          </span>
          <span>
            <FormattedMessage id="label.filters" defaultMessage="Filters: " />
          </span>
        </Space>
        <Space>
          <PeriodDropdown
            refetch={refetch}
            isPaginated={isPaginated}
            hasFromDate={hasFromDate}
            setCurrentPage={setCurrentPage}
            setFromDate={setFromDate}
            setToDate={setToDate}
            setIsButtonDisabled={setIsButtonDisabled}
            setSelectedPeriod={setSelectedPeriod}
            selectedPeriod={selectedPeriod}
            period={period}
            fromDate={fromDate}
            toDate={toDate}
          />
          {filterConfig?.branch && (
            <Select
              className="report-filter-select"
              style={{ height: "2rem" }}
              optionFilterProp="label"
              value={selectedBranchId}
              onChange={handleBranchChange}
            >
              {branches?.map((branch) => (
                <Select.Option
                  key={branch.id}
                  value={branch.id}
                  label={branch.name}
                >
                  {branch.name}
                </Select.Option>
              ))}
            </Select>
          )}
          {filterConfig?.warehouse && (
            <Select
              className="report-filter-select"
              style={{ height: "2rem" }}
              optionFilterProp="label"
              value={selectedWarehouseId}
              onChange={handleWarehouseChange}
            >
              {warehouses?.map((warehouse) => (
                <Select.Option
                  key={warehouse.id}
                  value={warehouse.id}
                  label={warehouse.name}
                >
                  {warehouse.name}
                </Select.Option>
              ))}
            </Select>
          )}
          {filterConfig?.more?.enabled && (
            <Button
              onClick={() => setMoreFilterOpen(!moreFilterOpen)}
              icon={
                <span>
                  <PlusCircleFilled style={{ color: "var(--primary-color)" }} />
                </span>
              }
            >
              <span>More Filters</span>
            </Button>
          )}
          {!moreFilterOpen && (
            <Tooltip
              title={
                isButtonDisabled ? (
                  <FormattedMessage
                    id="button.modifyFiltersToGenerateReport"
                    defaultMessage="Modify filters to generate report"
                  />
                ) : (
                  <FormattedMessage
                    id="button.generateReportToApplyFilters"
                    defaultMessage="Generate report to apply filters"
                  />
                )
              }
            >
              <Button
                type="primary"
                onClick={() => {
                  // form.submit();
                  handleGenerateReport();
                }}
                disabled={isButtonDisabled}
                loading={loading}
              >
                <FormattedMessage
                  id="button.generateReport"
                  defaultMessage="Generate Report"
                />
              </Button>
            </Tooltip>
          )}
        </Space>
      </Space>
      <div className={`more-filters ${moreFilterOpen ? "open" : ""}`}>
        <div className="more-filters-body">
          <div style={{ width: "80%" }}>
            <Form form={form}>
              {filters?.map((filter, index) => (
                <Row gutter={16} key={filter.key}>
                  <div className="more-filters-select-addon">{index + 1}</div>
                  <Col lg={4} style={{ paddingLeft: 0 }}>
                    <Form.Item
                      name={`field${filter.key}`}
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage
                              id="label.field.required"
                              defaultMessage="Select the field"
                            />
                          ),
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        className="report-filter-select custom-filter-select"
                        placeholder="Select a field"
                        options={filterConfig?.more?.filterFields}
                        onChange={(value) =>
                          handleFieldChange(filter.key, value)
                        }
                        // onClear={() => setFilters()}
                        value={filter.field}
                      />
                    </Form.Item>
                  </Col>
                  {/* <Col lg={4}>
                    <Form.Item
                      initialValue="isIn"
                      name={`comparator${filter.key}`}
                    >
                      <Select
                        allowClear
                        className="report-filter-select"
                        placeholder="Select a comparator"
                        options={comparators}
                        // onChange={(value) => handleComparatorChange(value, index)}
                      ></Select>
                    </Form.Item>
                  </Col> */}
                  <Col lg={6}>
                    <Form.Item
                      name={`filter${filter.key}`}
                      rules={[
                        {
                          required: filter.field ? true : false,
                          message: (
                            <FormattedMessage
                              id="label.filter.required"
                              defaultMessage="Select the filter"
                            />
                          ),
                        },
                      ]}
                    >
                      <Select
                        className="report-filter-select"
                        placeholder="Select filter"
                        allowClear
                        options={filter?.data?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                        mode="multiple"
                        disabled={!filter.field}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col lg={1} className="more-filters-delete">
                    <DeleteOutlined
                      className="delete-icon"
                      onClick={() => {
                        // if (filters.length === 1) {
                        //   return;
                        // }
                        removeFilter(filter.key);
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </Form>
            <Button
              type="link"
              icon={<PlusCircleFilled />}
              style={{ padding: 0 }}
              onClick={addFilter}
            >
              Add Filter
            </Button>
          </div>
        </div>
        <div className="more-filters-footer">
          <Space>
            <Button
              className={moreFilterOpen ? "generate-report-btn" : ""}
              type="primary"
              onClick={() => {
                handleGenerateReport();
              }}
              loading={loading}
            >
              <FormattedMessage
                id="button.generateReport"
                defaultMessage="Generate Report"
              />
            </Button>
            <Button onClick={() => setMoreFilterOpen(false)}>
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ReportFilterBar;
