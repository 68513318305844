import React from "react";
import { Button, Divider, Dropdown, Flex, Space } from "antd";
import {
  CaretDownFilled,
  PrinterOutlined,
  CloseOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as CollapseOutlined } from "../assets/icons/CollapseOutlined.svg";
import { ReactComponent as ShareOutlined } from "../assets/icons/ShareOutlined.svg";
import { PeriodDropdown } from "../components";
import { FormattedMessage } from "react-intl";
import { pdf } from "@react-pdf/renderer";

const ReportHeader = ({
  onCollapseClick,
  onShareClick,
  onPrinterClick,
  onExportAsClick,
  refetch,
  isPaginated = false,
  hasFromDate = true,
  setCurrentPage,
  setFromDate,
  setToDate,
  setReportBasis,
  currentReport,
  setCollapsed,
  collapsed,
  PDFComponent,
  selectedRecord,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleNavigate = () => {
    navigate("/reports");
  };

  const handleShareClick = () => {
    const url = window.location.href;
    const newTab = window.open(url, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      alert("Please allow popups for this website");
    }
  };

  // const handleExportAsClick = async () => {
  //   if (!PDFComponent) {
  //     console.error("No PDF component provided");
  //     return;
  //   }

  //   const pdfDoc = <PDFComponent selectedRecord={selectedRecord} />;
  //   const blob = await pdf(pdfDoc).toBlob();
  //   const blobUrl = URL.createObjectURL(blob);

  //   const newTab = window.open(blobUrl, "_blank");
  //   if (newTab) {
  //     newTab.focus();
  //   } else {
  //     alert("Please allow popups for this website");
  //   }
  // };

  return (
    <div className="page-header" style={{ paddingBlock: 0 }}>
      <Flex gap="1rem" align="center">
        <Button
          icon={<CollapseOutlined />}
          onClick={() => setCollapsed(!collapsed)}
        />
        <div style={{ height: "32px", lineHeight: "normal" }}>
          <Flex
            style={{
              fontSize: "var(--small-text)",
              color: "#4c526c",
            }}
            align="center"
            gap={4}
          >
            <span>{currentReport.title}</span>
            {currentReport.parent && (
              <>
                <span>
                  <RightOutlined
                    style={{ width: 9, color: "var(--primary-color)" }}
                  />{" "}
                </span>
                <span>{currentReport.parent?.label}</span>
              </>
            )}
          </Flex>
          <div style={{ fontSize: 15, marginTop: "2.5px" }}>
            <span>{currentReport.label}</span>
          </div>
        </div>
      </Flex>
      <div>
        <Space>
          <Button
            icon={<ShareOutlined style={{ height: "1.3rem" }} />}
            onClick={handleShareClick}
          ></Button>
          <Divider />
          {/* <Button
            icon={<PrinterOutlined />}
            type="primary"
            onClick={onPrinterClick}
          />
          <Dropdown
            trigger="click"
            menu={{
              items: [{ key: "1", label: "PDF" }],
              selectable: true,
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "8px",
                height: "2rem",
                alignItems: "center",
                border: "1px solid var(--border-color)",
                paddingInline: "0.5rem",
                cursor: "pointer",
                borderRadius: "0.3rem",
                justifyContent: "space-between",
              }}
              onClick={onExportAsClick}
            >
              <FormattedMessage
                id="button.exportAs"
                defaultMessage="Export As"
              />
              <CaretDownFilled style={{ width: "0.6rem", height: "0.6rem" }} />
            </div>
          </Dropdown> */}
          <Button
            icon={<CloseOutlined style={{ color: "red" }} />}
            type="text"
            onClick={handleNavigate}
          />
        </Space>
      </div>
    </div>
  );
};

export default ReportHeader;
