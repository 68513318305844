/* eslint-disable react/style-prop-object */
import React, { useMemo } from "react";
import { Spin, Flex, Empty } from "antd";
import { ReportQueries } from "../../../graphql";
import { useQuery, useReadQuery } from "@apollo/client";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { openErrorNotification } from "../../../utils/Notification";
import { useOutletContext, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";
import dayjs from "dayjs";

const { GET_INVENTORY_VALUATION } = ReportQueries;

const InventoryValuationByItem = () => {
  const { notiApi, business, allWarehousesQueryRef } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const reportBasisParam = searchParams.get("reportBasis");
  const productNameParam = searchParams.get("productName");
  const skuParam = searchParams.get("sku");
  const productIdParam = searchParams.get("productId");
  const productTypeParam = searchParams.get("productType");
  const warehouseIdParam = searchParams.get("warehouseId");

  const fromDate = fromDateParam
    ? moment(fromDateParam).utc()
    : REPORT_DEFAULTS.fromDate;
  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const reportBasis = reportBasisParam ? reportBasisParam : "Accrual";
  const productName = productNameParam || "";
  const sku = skuParam || "";
  const productId = productIdParam || 0;
  const productType = productTypeParam || "";
  const warehouseId = warehouseIdParam ? parseInt(warehouseIdParam) : 0;

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_INVENTORY_VALUATION, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: fromDate.format(DATE_STRING_FORMAT),
      endDate: toDate.format(DATE_STRING_FORMAT),
      warehouseId: warehouseId,
      productId,
      productType,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });
  const queryData = useMemo(() => data?.getInventoryValuation || [], [data]);

  const groupData = (details) => {
    const grouped = details.reduce((acc, detail) => {
      const key = `${dayjs(detail.transactionDate).format(
        REPORT_DATE_FORMAT
      )}|${detail.transactionDescription}`;
      if (!acc[key]) {
        acc[key] = {
          transactionDate: detail.transactionDate,
          transactionDescription: detail.transactionDescription,
          rows: [],
        };
      }
      acc[key].rows.push(detail);
      return acc;
    }, {});
    return Object.values(grouped);
  };

  const groupedData = useMemo(
    () => groupData(queryData?.details || []),
    [queryData]
  );

  const { data: warehouseData } = useReadQuery(allWarehousesQueryRef);

  const warehouse = useMemo(() => {
    return warehouseData?.listAllWarehouse?.find((w) => w.id === warehouseId);
  }, [warehouseData, warehouseId]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>
              Inventory Valuation for {productName} {sku && `( ${sku} )`}
            </h3>
            {/* <span>Basis: {reportBasis}</span> */}
            {warehouse && <span>Warehouse: {warehouse?.name}</span>}
            <h5>
              From {fromDate.format(REPORT_DATE_FORMAT)} To{" "}
              {toDate.format(REPORT_DATE_FORMAT)}
            </h5>
          </div>
          {queryLoading ? (
            <Flex justify="center" align="center" style={{ height: "40vh" }}>
              <Spin size="large" />
            </Flex>
          ) : (
            <div className="fill-container table-container">
              <table className="rep-table inventory-sum-table">
                <thead>
                  <tr>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage id="label.date" defaultMessage="Date" />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.transactionDetails"
                        defaultMessage="Transaction Details"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.warehouse"
                        defaultMessage="Warehouse"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.quantity"
                        defaultMessage="Quantity"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.unitCost"
                        defaultMessage="Unit Cost"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.stockOnHand"
                        defaultMessage="stockOnHand"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.inventoryAssetValue"
                        defaultMessage="Inventory Asset Value"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{fromDate.format(REPORT_DATE_FORMAT)}</td>
                    <td colSpan={4}>
                      <i style={{ color: "var(--dark-green)" }}>
                        Opening Stock
                      </i>
                    </td>
                    <td className="text-align-right">
                      <FormattedNumber
                        value={queryData.openingStockOnHand}
                        style="decimal"
                        // minimumFractionDigits={
                        //   business.baseCurrency.decimalPlaces
                        // }
                      />
                    </td>
                    <td className="text-align-right">
                      <FormattedNumber
                        value={queryData.openingAssetValue}
                        style="decimal"
                        minimumFractionDigits={
                          business.baseCurrency.decimalPlaces
                        }
                      />
                    </td>
                  </tr>
                  {groupedData.map((group, groupIndex) => {
                    return group.rows.map((row, rowIndex) => (
                      <tr key={`${groupIndex}-${rowIndex}`}>
                        {rowIndex === 0 && (
                          <>
                            <td rowSpan={group.rows.length}>
                              {dayjs(group.transactionDate).format(
                                REPORT_DATE_FORMAT
                              )}
                            </td>
                            <td rowSpan={group.rows.length}>
                              {group.transactionDescription}
                            </td>
                          </>
                        )}
                        <td>{row.warehouseName}</td>
                        <td
                          className="text-align-right"
                          style={{ color: row.qty <= 0 ? "var(--red)" : "" }}
                        >
                          {row.qty}
                        </td>
                        <td className="text-align-right">
                          <FormattedNumber
                            value={row.unitCost}
                            style="decimal"
                            minimumFractionDigits={
                              business.baseCurrency.decimalPlaces
                            }
                          />
                        </td>
                        <td className="text-align-right">
                          <FormattedNumber
                            value={row.stockOnHand}
                            style="decimal"
                          />
                        </td>
                        <td className="text-align-right">
                          <FormattedNumber
                            value={row.assetValue}
                            style="decimal"
                            minimumFractionDigits={
                              business.baseCurrency.decimalPlaces
                            }
                          />
                        </td>
                      </tr>
                    ));
                  })}
                  <tr>
                    <td>{toDate.format(REPORT_DATE_FORMAT)}</td>
                    <td colSpan={4}>
                      <i style={{ color: "var(--red)" }}>Closing Stock</i>
                    </td>
                    <td className="text-align-right">
                      <FormattedNumber
                        value={queryData.closingStockOnHand}
                        style="decimal"
                        // minimumFractionDigits={
                        //   business.baseCurrency.decimalPlaces
                        // }
                      />
                    </td>
                    <td className="text-align-right">
                      <FormattedNumber
                        value={queryData.closingAssetValue}
                        style="decimal"
                        minimumFractionDigits={
                          business.baseCurrency.decimalPlaces
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div style={{ paddingLeft: "1.5rem" }}>
          <FormattedMessage
            values={{ currency: business.baseCurrency.symbol }}
            id="label.displayedBaseCurrency"
            defaultMessage="**Amount is displayed in {currency}"
          />
        </div>
      </div>
    </ReportLayout>
  );
};

export default InventoryValuationByItem;
