/* eslint-disable react/style-prop-object */
import React, { useMemo } from "react";
import { Spin, Flex, Empty } from "antd";
import { ReportQueries } from "../../../graphql";
import { useQuery, useReadQuery } from "@apollo/client";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { openErrorNotification } from "../../../utils/Notification";
import { useOutletContext, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";

const { GET_STOCK_SUMMARY_REPORT } = ReportQueries;

const StockSummaryReport = () => {
  const { notiApi, business, allWarehousesQueryRef } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const warehouseIdParam = searchParams.get("warehouseId");
  const reportBasisParam = searchParams.get("reportBasis");

  const fromDate = fromDateParam
    ? moment(fromDateParam).utc()
    : REPORT_DEFAULTS.fromDate;
  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const branchId = branchIdParam ? parseInt(branchIdParam) : 0;
  const warehouseId = warehouseIdParam ? parseInt(warehouseIdParam) : 0;
  const reportBasis = reportBasisParam ? reportBasisParam : "Accrual";

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_STOCK_SUMMARY_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      fromDate: fromDate.format(DATE_STRING_FORMAT),
      toDate: toDate.format(DATE_STRING_FORMAT),
      branchId,
      reportType: reportBasis,
      warehouseId,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });
  const queryData = useMemo(() => data?.getStockSummaryReport, [data]);

  const totals = useMemo(() => {
    return queryData?.reduce(
      (acc, curr) => {
        acc.openingStock += curr.openingStock || 0;
        acc.qtyIn += curr.qtyIn || 0;
        acc.qtyOut += curr.qtyOut || 0;
        acc.closingStock += curr.closingStock || 0;
        return acc;
      },
      { openingStock: 0, qtyIn: 0, qtyOut: 0, closingStock: 0 }
    );
  }, [queryData]);

  const { data: warehouseData } = useReadQuery(allWarehousesQueryRef);

  const warehouse = useMemo(() => {
    return warehouseData?.listAllWarehouse?.find((w) => w.id === warehouseId);
  }, [warehouseData, warehouseId]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          filterConfig={{
            warehouse: true,
          }}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>
              <FormattedMessage
                id="report.stockSummaryReport"
                defaultMessage="Stock Summary Report"
              />
            </h3>
            {/* <span>Basis: {reportBasis}</span> */}
            {warehouse && <span>Warehouse: {warehouse?.name}</span>}
            <h5>
              From {fromDate.format(REPORT_DATE_FORMAT)} To{" "}
              {toDate.format(REPORT_DATE_FORMAT)}
            </h5>
          </div>
          {queryLoading ? (
            <Flex justify="center" align="center" style={{ height: "40vh" }}>
              <Spin size="large" />
            </Flex>
          ) : (
            <div className="fill-container table-container">
              <table className="rep-table ">
                <thead>
                  <tr>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.productName"
                        defaultMessage="Product Name"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage id="label.sku" defaultMessage="SKU" />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.openingStock"
                        defaultMessage="Opening Stock"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.quantityIn"
                        defaultMessage="Quantity In"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.quantityOut"
                        defaultMessage="Quantity Out"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.closingStock"
                        defaultMessage="Closing Stock"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {queryData?.length > 0 ? (
                    queryData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.productName}</td>
                          <td>{data.productSku}</td>
                          <td className="text-align-right">
                            {data.openingStock}
                          </td>
                          <td className="text-align-right">{data.qtyIn}</td>
                          <td className="text-align-right">{data.qtyOut}</td>
                          <td className="text-align-right">
                            <FormattedNumber
                              value={data.closingStock || 0}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="empty-row">
                      <td
                        colSpan={9}
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--border-color)",
                        }}
                      >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <FormattedMessage
                        id="label.total"
                        defaultMessage="Total"
                      ></FormattedMessage>
                    </td>
                    <td></td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.openingStock || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.qtyIn || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.qtyOut || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.closingStock || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          <div style={{ paddingLeft: "1.5rem" }}>
            <FormattedMessage
              values={{ currency: business.baseCurrency.symbol }}
              id="label.displayedBaseCurrency"
              defaultMessage="**Amount is displayed in {currency}"
            />
          </div>
        </div>
      </div>
    </ReportLayout>
  );
};

export default StockSummaryReport;
