import { gql } from "@apollo/client";

const GET_ROLES = gql`
  query ListRole($name: String) {
    listRole(name: $name) {
      id
      name
      roleModules {
        allowedActions
        module {
          id
          businessId
          name
          actions
        }
      }
    }
  }
`;

const GET_ROLE_MODULES = gql`
  query ListRoleModule($roleId: ID) {
    listRoleModule(roleId: $roleId) {
      allowedActions
      module {
        id
        name
        actions
      }
      role {
        id
        name
      }
    }
  }
`;

const GET_MODULES = gql`
  query ListModule($name: String) {
    listModule(name: $name) {
      baseModuleName
      modules {
        id
        name
        actions
      }
    }
  }
`;

const RoleQueries = {
  GET_ROLES,
  GET_ROLE_MODULES,
  GET_MODULES,
};

export default RoleQueries;
