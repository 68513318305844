import { gql } from "@apollo/client";

const GET_PAGINATED_JOURNAL_REPORTS = gql`
  query GetJournalReport(
    $limit: Int = 10
    $after: String
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String! = "asdf"
    $branchId: Int
  ) {
    paginateJournalReport(
      limit: $limit
      after: $after
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          businessId
          branch {
            id
            name
          }
          transactionDateTime
          transactionNumber
          customer {
            id
            name
          }
          supplier {
            id
            name
          }
          referenceId
          referenceType
          accountTransactions {
            id
          }
          accountTransactions {
            id
            baseDebit
            baseCredit
            account {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const GET_ACCOUNT_TYPE_SUMMARY_REPORT = gql`
  query GetAccountTypeSummaryReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
  ) {
    getAccountTypeSummaryReport(
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
    ) {
      accountMainType
      accountSummaries {
        accountName
        accountMainType
        code
        debit
        credit
        balance
      }
    }
  }
`;

const GET_PAGINATED_ACCOUNT_TRANSACTION_REPORT = gql`
  query GetPaginatedAccountTransactionReport(
    $limit: Int = 10
    $after: String
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
    $accountIds: [Int!]
  ) {
    paginateAccountTransactionReport(
      limit: $limit
      after: $after
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
      accountIds: $accountIds
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          transactionDateTime
          description
          account {
            id
            name
          }
          branch {
            id
            name
          }
          transactionDateTime
          baseDebit
          baseCredit
          baseClosingBalance
          accountJournal {
            customer {
              name
            }
            supplier {
              name
            }
            referenceType
            transactionNumber
            transactionDetails
            referenceNumber
          }
        }
      }
    }
  }
`;

// const GET_DETAILED_GENERAL_LEDGER_REPORT = gql`
//   query GetDetailedGeneralLedgerReport(
//     $fromDate:MyDateString!
//     $toDate:MyDateString!
//     $reportType: String!
//     $branchId: Int
//   ) {
//     getDetailedGeneralLedgerReport(
//       fromDate: $fromDate
//       toDate: $toDate
//       reportType: $reportType
//       branchId: $branchId
//     ) {
//       accountId
//       accountName
//       openingBalance
//       openingBalanceDate
//       closingBalance
//       closingBalanceDate
//       transactions {
//         accountName
//         transactionDateTime
//         description
//         transactionDateTime
//         transactionNumber
//         transactionDetails
//         referenceNumber
//         transactionType
//         customerName
//         supplierName
//         debit
//         credit
//       }
//     }
//   }
// `;

const GET_GENERAL_LEDGER_REPORT = gql`
  query GetGeneralLedgerReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    getGeneralLedgerReport(
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      accountId
      accountName
      accountCode
      accountMainType
      code
      debit
      credit
      balance
      closingBalance
      openingBalance
    }
  }
`;

const GET_TRIAL_BALANCE_REPORT = gql`
  query GetTrialBalanceReport(
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    getTrialBalanceReport(
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      accountMainType
      accountName
      accountId
      accountCode
      debit
      credit
    }
  }
`;

const GET_BALANCE_SHEET_REPORT = gql`
  query GetBalanceSheetReport(
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    getBalanceSheetReport(
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      mainType
      total
      accounts {
        groupType
        total
        accounts {
          subType
          total
          accounts {
            accountName
            accountId
            amount
          }
        }
      }
    }
  }
`;

const GET_PROFIT_AND_LOSS_REPORT = gql`
  query GetProfitAndLossReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    getProfitAndLossReport(
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      grossProfit
      operatingProfit
      netProfit
      plAccountGroups {
        groupType
        total
        accounts {
          mainType
          detailType
          accountName
          accountId
          amount
        }
      }
    }
  }
`;

const GET_PAGINATED_DETAILED_GENERAL_LEDGER_REPORT = gql`
  query PaginateDetailedGeneralLedgerReport(
    $limit: Int = 10
    $after: String
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    paginateDetailedGeneralLedgerReport(
      limit: $limit
      after: $after
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      edges {
        cursor
        node {
          accountId
          accountName
          currencyId
          currencyName
          currencySymbol
          openingBalance
          openingBalanceDate
          closingBalance
          closingBalanceDate
          transactions {
            accountId
            accountName
            branchId
            transactionDateTime
            description
            debit
            credit
            transactionType
            transactionNumber
            transactionDetails
            referenceNumber
            customerName
            supplierName
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

const GET_CASH_FLOW_REPORT = gql`
  query GetCashFlowReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    getCashFlowReport(
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      beginCashBalance
      netChange
      endCashBalance
      cashAccountGroups {
        groupName
        total
        accounts {
          accountName
          accountCode
          accountID
          amount
        }
      }
    }
  }
`;

const GET_MOVEMENT_OF_EQUITY_REPORT = gql`
  query GetMovementOfEquityReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $reportType: String!
    $branchId: Int
  ) {
    getMovementOfEquityReport(
      fromDate: $fromDate
      toDate: $toDate
      reportType: $reportType
      branchId: $branchId
    ) {
      openingBalance
      netChange
      closingBalance
      accountGroups {
        groupName
        total
        accounts {
          accountName
          accountCode
          accountID
          amount
        }
      }
    }
  }
`;

const GET_SALES_BY_CUSTOMER_REPORT = gql`
  query GetSalesByCustomerReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int
  ) {
    getSalesByCustomerReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
    ) {
      CustomerId
      CustomerName
      InvoiceCount
      TotalSales
      TotalSalesWithTax
    }
  }
`;

const GET_SALES_BY_SALES_PERSON_REPORT = gql`
  query GetSalesBySalesPersonReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int
  ) {
    getSalesBySalesPersonReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
    ) {
      SalesPersonId
      SalesPersonName
      InvoiceCount
      TotalInvoiceSales
      TotalInvoiceSalesWithTax
      CreditNoteCount
      TotalCreditNoteSales
      TotalCreditNoteSalesWithTax
      TotalSales
      TotalSalesWithTax
    }
  }
`;

const GET_SALES_BY_PRODUCT_REPORT = gql`
  query GetSalesByProductReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int
  ) {
    getSalesByProductReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
    ) {
      productName
      productSku
      soldQty
      totalAmount
      totalAmountWithDiscount
      averagePrice
    }
  }
`;

const GET_INVENTORY_SUMMARY_REPORT = gql`
  query GetInventorySummaryReport($toDate: MyDateString!, $warehouseId: Int) {
    getInventorySummaryReport(toDate: $toDate, warehouseId: $warehouseId) {
      productName
      productUnit {
        name
        abbreviation
        precision
        id
      }
      transferQtyIn
      transferQtyOut
      adjustedQtyIn
      adjustedQtyOut
      productSku
      openingQty
      orderQty
      receivedQty
      saleQty
      committedQty
      currentQty
      availableStock
    }
  }
`;

const GET_PRODUCT_SALES_REPORT = gql`
  query GetProductSalesReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int
  ) {
    getProductSalesReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
    ) {
      productName
      productSku
      soldQty
      totalAmount
      totalAmountWithTax
      totalCogs
      margin
      grossProfit
    }
  }
`;

const GET_AR_AGING_SUMMARY_REPORT = gql`
  query GetARAgingSummaryReport(
    $currentDate: MyDateString!
    $branchId: Int
    $warehouseId: Int
  ) {
    getARAgingSummaryReport(
      currentDate: $currentDate
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      customerName
      customerId
      current
      int1to15
      int16to30
      int31to45
      int46plus
      invoiceCount
      currencySymbol
      total
      totalFcy
    }
  }
`;

const GET_STOCK_SUMMARY_REPORT = gql`
  query GetStockSummaryReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $warehouseId: Int
  ) {
    getStockSummaryReport(
      fromDate: $fromDate
      toDate: $toDate
      warehouseId: $warehouseId
    ) {
      productName
      productSku
      openingStock
      qtyIn
      qtyOut
      closingStock
    }
  }
`;

const GET_AP_AGING_SUMMARY_REPORT = gql`
  query GetAPAgingSummaryReport(
    $currentDate: MyDateString!
    $branchId: Int
    $warehouseId: Int
  ) {
    getAPAgingSummaryReport(
      currentDate: $currentDate
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      supplierId
      supplierName
      current
      int31to45
      int1to15
      int16to30
      int46plus
      currencySymbol
      total
      totalFcy
      billCount
    }
  }
`;

const GET_AP_AGING_DETAIL_REPORT = gql`
  query GetAPAgingDetailReport(
    $currentDate: MyDateString!
    $warehouseId: Int!
    $branchId: Int!
  ) {
    getAPAgingDetailReport(
      currentDate: $currentDate
      warehouseId: $warehouseId
      branchId: $branchId
    ) {
      interval
      amount
      balanceDue
      details {
        billId
        billDate
        billNumber
        billStatus
        supplierId
        supplierName
        age
        currencySymbol
        totalAmount
        remainingBalance
        totalAmountFcy
        remainingBalanceFcy
      }
    }
  }
`;

const GET_AR_AGING_DETAIL_REPORT = gql`
  query GetARAgingDetailReport(
    $currentDate: MyDateString!
    $warehouseId: Int!
    $branchId: Int!
  ) {
    getARAgingDetailReport(
      currentDate: $currentDate
      warehouseId: $warehouseId
      branchId: $branchId
    ) {
      interval
      amount
      balanceDue
      details {
        invoiceId
        invoiceDate
        invoiceNumber
        invoiceStatus
        customerId
        customerName
        age
        currencySymbol
        totalAmount
        remainingBalance
        totalAmountFcy
        remainingBalanceFcy
      }
    }
  }
`;

const GET_BILL_DETAIL_REPORT = gql`
  query GetBillDetailReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int!
    $warehouseId: Int!
  ) {
    getBillDetailReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      billId
      billNumber
      orderNumber
      billStatus
      billDate
      billDueDate
      totalAmount
      totalAmountFcy
      remainingBalance
      remainingBalanceFcy
      currencyId
      currencySymbol
      supplierId
      supplierName
    }
  }
`;

const GET_PURCHASE_ORDER_DETAIL_REPORT = gql`
  query GetPurchaseOrderDetailReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int!
    $warehouseId: Int!
  ) {
    getPurchaseOrderDetailReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      orderId
      orderNumber
      orderStatus
      orderDate
      expectedDeliveryDate
      orderAmount
      currencyId
      currencySymbol
      orderAmountFcy
      supplierId
      supplierName
    }
  }
`;

const GET_TOTAL_PAYABLE_RECEIVABLE = gql`
  query GetTotalPayableReceivable {
    getTotalPayableReceivable {
      totalReceivable {
        currencySymbol
        current
        total
        int1to15
        int16to30
        int31to45
        int46plus
      }
      totalPayable {
        currencySymbol
        total
        current
        int1to15
        int16to30
        int31to45
        int46plus
      }
    }
  }
`;

const GET_TOTAL_INCOME_EXPENSE = gql`
  query GetTotalIncomeExpense($filterType: String!) {
    getTotalIncomeExpense(filterType: $filterType) {
      totalIncome
      totalExpense
      incomeExpenseDetails {
        month
        incomeAmount
        expenseAmount
      }
    }
  }
`;

const GET_TOTAL_CASH_FLOW = gql`
  query GetTotalCashFlow($filterType: String!) {
    getTotalCashFlow(filterType: $filterType) {
      totalOpeningBalance
      totalIncomingAmount
      totalOutgoingAmount
      totalEndingBalance
      cashFlowDetails {
        month
        openingBalance
        incomingAmount
        outgoingAmount
        endingBalance
      }
    }
  }
`;

const GET_INVOICE_DETAIL_REPORT = gql`
  query GetSalesInvoiceDetailReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int!
    $warehouseId: Int!
  ) {
    getSalesInvoiceDetailReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      invoiceId
      invoiceNumber
      orderNumber
      invoiceStatus
      invoiceDate
      invoiceDueDate
      totalAmount
      totalAmountFcy
      remainingBalance
      remainingBalanceFcy
      currencyId
      currencySymbol
      customerId
      customerName
    }
  }
`;

const GET_SALES_ORDER_DETAIL_REPORT = gql`
  query GetSalesOrderDetailReport(
    $fromDate: MyDateString!
    $toDate: MyDateString!
    $branchId: Int!
    $warehouseId: Int!
  ) {
    getSalesOrderDetailReport(
      fromDate: $fromDate
      toDate: $toDate
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      orderId
      orderNumber
      orderStatus
      orderDate
      expectedShipmentDate
      orderAmount
      currencyId
      currencySymbol
      orderAmountFcy
      customerId
      customerName
    }
  }
`;

const GET_TOP_EXPENSE = gql`
  query GetTopExpense($filterType: String!) {
    getTopExpense(filterType: $filterType) {
      accountName
      amount
    }
  }
`;

const GET_INVENTORY_VALUATION_SUMMARY_REPORT = gql`
  query GetInventoryValuationSummaryReport(
    $currentDate: MyDateString!
    $warehouseId: Int!
  ) {
    getInventoryValuationSummaryReport(
      currentDate: $currentDate
      warehouseId: $warehouseId
    ) {
      productId
      productName
      productType
      sku
      unit
      stockOnHand
      assetValue
    }
  }
`;

const GET_INVENTORY_VALUATION = gql`
  query GetInventoryValuation(
    $startDate: MyDateString!
    $endDate: MyDateString!
    $productId: Int!
    $productType: ProductType!
    $warehouseId: Int!
  ) {
    getInventoryValuation(
      startDate: $startDate
      endDate: $endDate
      productId: $productId
      productType: $productType
      warehouseId: $warehouseId
    ) {
      openingStockOnHand
      openingAssetValue
      closingStockOnHand
      closingAssetValue
      details {
        transactionDate
        transactionDescription
        warehouseName
        qty
        unitCost
        stockOnHand
        assetValue
      }
    }
  }
`;

const GET_EXPENSE_DETAIL_REPORT = gql`
  query GetExpenseDetailReport(
    $startDate: MyDateString!
    $endDate: MyDateString!
  ) {
    getExpenseDetailReport(startDate: $startDate, endDate: $endDate) {
      ID
      expenseDate
      exchangeRate
      expenseNumber
      sequenceNo
      referenceNumber
      notes
      fcyAmount
      amount
      amountWithTax
      expenseAccount {
        id
        name
      }
      assetAccount {
        id
        name
      }
      branch {
        id
        name
      }
      currency {
        id
        decimalPlaces
        name
        symbol
      }
      supplier {
        id
        name
        supplierPaymentTerms
        supplierPaymentTermsCustomDays
        notes
        exchangeRate
        openingBalanceBranchId
        openingBalance
        isActive
        totalOutstandingPayable
        totalOutstandingCredit
        createdAt
        updatedAt
      }
      customer {
        id
        name
        openingBalanceBranchId
        openingBalance
        exchangeRate
        customerPaymentTerms
        customerPaymentTermsCustomDays
        notes
        creditLimit
        isActive
        totalOutstandingReceivable
        totalOutstandingCredit
        createdAt
        updatedAt
      }
    }
  }
`;

const GET_EXPENSE_SUMMARY_BY_CATEGORY = gql`
  query GetExpenseSummaryByCategory(
    $startDate: MyDateString!
    $endDate: MyDateString!
  ) {
    getExpenseSummaryByCategory(startDate: $startDate, endDate: $endDate) {
      totalAmount
      totalAmountWithTax
      expenseSummaryByCategoryDetail {
        accountId
        accountName
        amount
        amountWithTax
      }
    }
  }
`;

const GET_EXPENSE_BY_CATEGORY = gql`
  query GetExpenseByCategory(
    $accountId: Int!
    $startDate: MyDateString!
    $endDate: MyDateString!
  ) {
    getExpenseByCategory(
      accountId: $accountId
      startDate: $startDate
      endDate: $endDate
    ) {
      transactionDate
      transactionType
      customerName
      customerId
      supplierName
      supplierId
      amount
      amountWithTax
    }
  }
`;

const GET_PAYABLE_SUMMARY_REPORT = gql`
  query GetPayableSummaryReport(
    $startDate: MyDateString!
    $endDate: MyDateString!
    $supplierId: Int
    $branchId: Int
    $warehouseId: Int
  ) {
    getPayableSummaryReport(
      startDate: $startDate
      endDate: $endDate
      supplierId: $supplierId
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      payableDate
      payableStatus
      transactionNumber
      transactionType
      supplierId
      supplierName
      payableAmount
      payableBalance
    }
  }
`;

const GET_PAYABLE_DETAIL_REPORT = gql`
  query GetPayableDetailReport(
    $startDate: MyDateString!
    $endDate: MyDateString!
    $supplierId: Int
    $branchId: Int
    $warehouseId: Int
  ) {
    getPayableDetailReport(
      startDate: $startDate
      endDate: $endDate
      supplierId: $supplierId
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      payableDate
      payableStatus
      transactionNumber
      transactionType
      currencyId
      itemName
      itemQty
      supplierId
      supplierName
      itemPrice
      itemAmount
      itemPriceFcy
      itemAmountFcy
      currency {
        id
        decimalPlaces
        name
        symbol
      }
    }
  }
`;

const GET_RECEIVABLE_SUMMARY_REPORT = gql`
  query GetReceivableSummaryReport(
    $startDate: MyDateString!
    $endDate: MyDateString!
    $customerId: Int
    $branchId: Int
    $warehouseId: Int
  ) {
    getReceivableSummaryReport(
      startDate: $startDate
      endDate: $endDate
      customerId: $customerId
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      receivableDate
      receivableStatus
      transactionNumber
      transactionType
      customerId
      customerName
      receivableAmount
      receivableBalance
    }
  }
`;

const GET_RECEIVABLE_DETAIL_REPORT = gql`
  query GetReceivableDetailReport(
    $startDate: MyDateString!
    $endDate: MyDateString!
    $customerId: Int
    $branchId: Int
    $warehouseId: Int
  ) {
    getReceivableDetailReport(
      startDate: $startDate
      endDate: $endDate
      customerId: $customerId
      branchId: $branchId
      warehouseId: $warehouseId
    ) {
      receivableDate
      receivableStatus
      transactionNumber
      transactionType
      currencyId
      itemName
      itemQty
      customerId
      customerName
      itemPrice
      itemAmount
      itemPriceFcy
      itemAmountFcy
      currency {
        id
        decimalPlaces
        name
        symbol
      }
    }
  }
`;

const GET_WAREHOUSE_INVENTORY_REPORT = gql`
  query GetWarehouseInventoryReport($toDate: MyDateString!) {
    getWarehouseInventoryReport(toDate: $toDate) {
      warehouseId
      warehouseName
      productId
      productName
      productSku
      openingQty
      orderQty
      receivedQty
      transferQtyIn
      transferQtyOut
      adjustedQtyIn
      adjustedQtyOut
      saleQty
      committedQty
      currentQty
      availableStock
      productUnit {
        id
        name
        abbreviation
        precision
      }
    }
  }
`;

const ReportQueries = {
  GET_PAGINATED_JOURNAL_REPORTS,
  GET_ACCOUNT_TYPE_SUMMARY_REPORT,
  GET_PAGINATED_ACCOUNT_TRANSACTION_REPORT,
  // GET_DETAILED_GENERAL_LEDGER_REPORT,
  GET_PAGINATED_DETAILED_GENERAL_LEDGER_REPORT,
  GET_GENERAL_LEDGER_REPORT,
  GET_TRIAL_BALANCE_REPORT,
  GET_BALANCE_SHEET_REPORT,
  GET_PROFIT_AND_LOSS_REPORT,
  GET_CASH_FLOW_REPORT,
  GET_MOVEMENT_OF_EQUITY_REPORT,
  GET_SALES_BY_CUSTOMER_REPORT,
  GET_SALES_BY_SALES_PERSON_REPORT,
  GET_SALES_BY_PRODUCT_REPORT,
  GET_INVENTORY_SUMMARY_REPORT,
  GET_PRODUCT_SALES_REPORT,
  GET_AR_AGING_SUMMARY_REPORT,
  GET_STOCK_SUMMARY_REPORT,
  GET_AP_AGING_SUMMARY_REPORT,
  GET_AP_AGING_DETAIL_REPORT,
  GET_AR_AGING_DETAIL_REPORT,
  GET_BILL_DETAIL_REPORT,
  GET_PURCHASE_ORDER_DETAIL_REPORT,
  GET_TOTAL_PAYABLE_RECEIVABLE,
  GET_TOTAL_INCOME_EXPENSE,
  GET_TOTAL_CASH_FLOW,
  GET_INVOICE_DETAIL_REPORT,
  GET_SALES_ORDER_DETAIL_REPORT,
  GET_TOP_EXPENSE,
  GET_INVENTORY_VALUATION_SUMMARY_REPORT,
  GET_INVENTORY_VALUATION,
  GET_EXPENSE_DETAIL_REPORT,
  GET_EXPENSE_SUMMARY_BY_CATEGORY,
  GET_EXPENSE_BY_CATEGORY,
  GET_PAYABLE_SUMMARY_REPORT,
  GET_PAYABLE_DETAIL_REPORT,
  GET_RECEIVABLE_SUMMARY_REPORT,
  GET_RECEIVABLE_DETAIL_REPORT,
  GET_WAREHOUSE_INVENTORY_REPORT,
};

export default ReportQueries;
