import React from "react";
import "../App.css";
import {
  ConfigProvider,
  Form,
  Input,
  Button,
  notification,
  Divider,
} from "antd";
import { useApolloClient, useMutation } from "@apollo/client";
import { useLocation, useNavigate } from "react-router-dom";
import { UserMutations } from "../graphql";
import { openErrorNotification } from "../utils/Notification";
import Theme from "../config/Theme";
import { FormattedMessage } from "react-intl";

const { LOGIN } = UserMutations;

const LoginPage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const [api, contextHolder] = notification.useNotification();
  const client = useApolloClient();

  const [login, { loading }] = useMutation(LOGIN, {
    errorPolicy: "all",
    async onCompleted(data) {
      localStorage.setItem("token", data.login.token);
      localStorage.setItem("name", data.login.name);
      localStorage.setItem("role", data.login.role);
      localStorage.setItem("module", data.login.modules);
      localStorage.setItem("businessName", data.login.businessName);
      localStorage.setItem("baseCurrencyId", data.login.baseCurrencyId);
      localStorage.setItem("baseCurrencyName", data.login.baseCurrencyName);
      localStorage.setItem("baseCurrencySymbol", data.login.baseCurrencySymbol);
      localStorage.setItem("fiscalYear", data.login.fiscalYear);
      localStorage.setItem("timezone", data.login.timezone);
      await client.clearStore();
      navigate(from, { replace: true });
    },
    onError(err) {
      openErrorNotification(api, err.message);
    },
  });

  const onFinish = (values) => {
    login({
      variables: {
        ...values,
      },
    });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Theme.colorPrimary,
          colorInfo: Theme.colorInfo,
        },
      }}
    >
      {contextHolder}
      <div className="login-form-container">
        {/* <p className="page-header-text" style={{ margin: 0 }}>
          <FormattedMessage id="label.login" defaultMessage="Login" />
        </p> */}
        <img
          alt="logo"
          width={200}
          src={process.env.PUBLIC_URL + "/sentosa_logo.svg"}
        />
        <Divider style={{ margin: "1rem 0" }} />
        <Form
          name="login"
          className="login-form"
          labelCol={{ span: 10 }}
          layout="vertical"
          labelAlign="left"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            // initialValue="kyawthanttin"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            // initialValue="123456"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="login-btn"
            style={{ marginTop: "1rem" }}
          >
            Login
          </Button>
        </Form>
      </div>
    </ConfigProvider>
  );
};

export default LoginPage;
