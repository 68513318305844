/* eslint-disable react/style-prop-object */
import React, { useMemo } from "react";
import { Spin, Flex, Empty } from "antd";
import { ReportQueries } from "../../../graphql";
import { useQuery } from "@apollo/client";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { openErrorNotification } from "../../../utils/Notification";
import { useOutletContext, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";
import dayjs from "dayjs";

const { GET_PAYABLE_SUMMARY_REPORT } = ReportQueries;

const PayableSummary = () => {
  const { notiApi, business } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const reportBasisParam = searchParams.get("reportBasis");
  const warehouseIdParam = searchParams.get("warehouseId");

  const fromDate = fromDateParam
    ? moment(fromDateParam).utc()
    : REPORT_DEFAULTS.fromDate;
  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const branchId = branchIdParam ? parseInt(branchIdParam) : 0;
  const reportBasis = reportBasisParam ? reportBasisParam : "Accrual";
  const warehouseId = warehouseIdParam ? parseInt(warehouseIdParam) : 0;

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_PAYABLE_SUMMARY_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: fromDate.format(DATE_STRING_FORMAT),
      endDate: toDate.format(DATE_STRING_FORMAT),
      // branchId,
      // warehouseId,
      reportType: reportBasis,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });
  const queryData = useMemo(() => data?.getPayableSummaryReport, [data]);

  // Calculate totals
  const totals = useMemo(() => {
    return queryData?.reduce(
      (acc, curr) => {
        acc.payableAmount += curr.payableAmount || 0;
        acc.payableBalance += curr.payableBalance || 0;
        return acc;
      },
      {
        payableAmount: 0,
        payableBalance: 0,
      }
    );
  }, [queryData]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          // filterConfig={{
          //   branch: true,
          //   warehouse: true,
          // }}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>
              <FormattedMessage
                id="report.payableSummary"
                defaultMessage="Payable Summary"
              />
            </h3>
            {/* <span>Basis: {reportBasis}</span> */}
            <h5>
              From {fromDate.format(REPORT_DATE_FORMAT)} To{" "}
              {toDate.format(REPORT_DATE_FORMAT)}
            </h5>
          </div>
          {queryLoading ? (
            <Flex justify="center" align="center" style={{ height: "40vh" }}>
              <Spin size="large" />
            </Flex>
          ) : (
            <div className="fill-container table-container">
              <table className="rep-table">
                <thead>
                  <tr>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.status"
                        defaultMessage="Status"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage id="label.date" defaultMessage="Date" />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.transactionNumber"
                        defaultMessage="Transaction #"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.supplierName"
                        defaultMessage="Supplier Name"
                      />
                    </th>
                    <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.transactionType"
                        defaultMessage="Transaction Type"
                      />
                    </th>
                    {/* <th className="text-align-left" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.customerName"
                        defaultMessage="Customer Name"
                      />
                    </th> */}
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.total"
                        defaultMessage="Total"
                      />
                    </th>
                    <th className="text-align-right" style={{ width: "150px" }}>
                      <FormattedMessage
                        id="label.balance"
                        defaultMessage="Balance"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {queryData?.length > 0 ? (
                    queryData?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.payableStatus}</td>
                          <td>
                            {dayjs(data.payableDate).format(REPORT_DATE_FORMAT)}
                          </td>
                          <td>{data.transactionNumber}</td>
                          <td>{data.supplierName}</td>
                          <td>{data.transactionType}</td>
                          {/* <td>{data.customerName}</td> */}
                          <td className="text-align-right">
                            <FormattedNumber
                              value={data.payableAmount}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                          <td className="text-align-right">
                            <FormattedNumber
                              value={data.payableBalance}
                              style="decimal"
                              minimumFractionDigits={
                                business.baseCurrency.decimalPlaces
                              }
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="empty-row">
                      <td
                        colSpan={7}
                        style={{
                          border: "none",
                          borderBottom: "1px solid var(--border-color)",
                        }}
                      >
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={5}>
                      <b>
                        <FormattedMessage
                          id="label.total"
                          defaultMessage="Total"
                        ></FormattedMessage>
                      </b>
                    </td>

                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.payableAmount || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                    <td className="text-align-right">
                      <b>
                        <FormattedNumber
                          value={totals?.payableBalance || 0}
                          style="decimal"
                          minimumFractionDigits={
                            business.baseCurrency.decimalPlaces
                          }
                        />
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div style={{ paddingLeft: "1.5rem" }}>
          <FormattedMessage
            values={{ currency: business.baseCurrency.symbol }}
            id="label.displayedBaseCurrency"
            defaultMessage="**Amount is displayed in {currency}"
          />
        </div>
      </div>
    </ReportLayout>
  );
};

export default PayableSummary;
