import {
  Button,
  ConfigProvider,
  Input,
  notification,
  Form,
  Divider,
} from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import Theme from "../config/Theme";
import { useMutation } from "@apollo/client";
import { UserMutations } from "../graphql";
import { openErrorNotification } from "../utils/Notification";

const { CHANGE_PASSWORD } = UserMutations;

const ChangePasswordPage = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  const [api, contextHolder] = notification.useNotification();

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    errorPolicy: "all",
    onCompleted() {
      navigate(from, { replace: true });
    },
    onError(err) {
      openErrorNotification(api, err.message);
    },
  });

  const onFinish = (values) => {
    if (values.newPassword !== values.confirmPassword) {
      openErrorNotification(api, "Passwords do not match!");
      return;
    }
    changePassword({
      variables: {
        ...values,
      },
    });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: Theme.colorPrimary,
          colorInfo: Theme.colorInfo,
        },
      }}
    >
      {contextHolder}
      <div className="login-page">
        <div className="login-form-container">
          <p className="page-header-text" style={{ margin: 0 }}>
            <FormattedMessage
              id="label.changePassword"
              defaultMessage="Change Password"
            />
          </p>
          <Divider style={{ margin: "1rem 0" }} />
          <Form
            name="changePassword"
            className="login-form"
            labelCol={{ span: 10 }}
            layout="vertical"
            labelAlign="left"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              label="Old Password"
              name="oldPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your old password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: "Please enter your new password!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["newPassword"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="login-btn"
              style={{ marginTop: "1rem" }}
              loading={loading}
            >
              <FormattedMessage
                id="button.changePassword"
                defaultMessage="Change Password"
              />
            </Button>

            <Button
              className="login-btn"
              style={{ marginTop: "1rem" }}
              onClick={() =>
                navigate(from, { state: location.state, replace: true })
              }
            >
              <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
            </Button>
          </Form>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default ChangePasswordPage;
