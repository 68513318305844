import React, { useMemo, useState, useEffect, useCallback } from "react";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  theme,
} from "antd";
import { CloseOutlined, CaretRightOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { useMutation, useReadQuery } from "@apollo/client";
import "./OpeningBalances.css";
import { ReactComponent as DiamondOutlined } from "../../assets/icons/DiamondOutlined.svg";
import { ReactComponent as ArrowDownwardOutlined } from "../../assets/icons/ArrowDownwardOutlined.svg";
import { ReactComponent as ArrowUpwardOutlined } from "../../assets/icons/ArrowUpwardOutlined.svg";
import { ReactComponent as SavingsOutlined } from "../../assets/icons/SavingsOutlined.svg";
import { ReactComponent as ReceiptOutlined } from "../../assets/icons/ReceiptOutlined.svg";
import { ReactComponent as WalletOutlined } from "../../assets/icons/WalletOutlined.svg";
import { ReactComponent as PoliceOutlined } from "../../assets/icons/PoliceOutlined.svg";
import { OpeningBalanceMutations } from "../../graphql";
import {
  openErrorNotification,
  openSuccessMessage,
} from "../../utils/Notification";
import { REPORT_DATE_FORMAT } from "../../config/Constants";
import dayjs from "dayjs";
const { UPDATE_OPENING_BALANCE } = OpeningBalanceMutations;

const OpeningBalancesEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const from = location.state?.from?.pathname || "/";
  const [isBalanceTableVisible, setIsBalanceTableVisible] = useState(true);
  const [totalDebits, setTotalDebits] = useState(0);
  const [totalCredits, setTotalCredits] = useState(0);
  const [obAdjustments, setObAdjustments] = useState(0);
  const [form] = Form.useForm();
  const {
    business,
    allAccountsQueryRef,
    allBranchesQueryRef,
    msgApi,
    notiApi,
  } = useOutletContext();
  const { token } = theme.useToken();
  const record = location.state?.record;
  const selectedBranchId = location.state?.selectedBranchId;

  // Queries
  const { data: accountData } = useReadQuery(allAccountsQueryRef);
  const { data: branchData } = useReadQuery(allBranchesQueryRef);

  console.log("record", record);

  //Mutations
  const [updateOpeningBalance, { loading: updateLoading }] = useMutation(
    UPDATE_OPENING_BALANCE,
    {
      onCompleted() {
        openSuccessMessage(
          msgApi,
          <FormattedMessage
            id="openingBalances.updated"
            defaultMessage="Opening Balances Updated"
          />
        );
        navigate(from, { state: location.state, replace: true });
      },
    }
  );

  const branches = useMemo(() => {
    return branchData?.listAllBranch?.filter((b) => b.isActive === true);
  }, [branchData]);

  const accounts = useMemo(() => {
    return accountData?.listAllAccount?.filter((a) => a.isActive === true);
  }, [accountData]);

  const getAccountIcon = (mainType) => {
    switch (mainType) {
      case "Asset":
        return <DiamondOutlined />;
      case "Receivable":
        return <ArrowDownwardOutlined />;
      case "Payable":
        return <ArrowUpwardOutlined />;
      case "Expense":
        return <ReceiptOutlined />;
      case "Liability":
        return <PoliceOutlined />;
      case "Equity":
        return <SavingsOutlined />;
      case "Income":
        return <WalletOutlined />;
      default:
        return null;
    }
  };

  const accountDetailsMap = useMemo(() => {
    const map = new Map();
    record?.details?.forEach((detail) => {
      map.set(detail.account.id, {
        debit: detail.debit,
        credit: detail.credit,
      });
    });
    return map;
  }, [record?.details]);

  console.log("account details", accountDetailsMap);

  const groupedAccounts = useMemo(() => {
    if (!accounts) return [];

    const groups = {};
    const accountsPayable = [];
    const accountsReceivable = [];

    accounts.forEach((account) => {
      const { mainType, name } = account;
      if (name === "Accounts Payable") {
        accountsPayable.push(account);
      } else if (name === "Accounts Receivable") {
        accountsReceivable.push(account);
      } else {
        if (!groups[mainType]) {
          groups[mainType] = [];
        }
        groups[mainType].push(account);
      }
    });

    const mainTypeGroups = Object.keys(groups).map((key) => ({
      mainType: key,
      icon: getAccountIcon(key),
      accounts: groups[key],
    }));

    const result = [];

    if (accountsReceivable.length) {
      result.push({
        mainType: "Accounts Receivable",
        icon: getAccountIcon("Receivable"),
        accounts: accountsReceivable,
      });
    }

    if (accountsPayable.length) {
      result.push({
        mainType: "Accounts Payable",
        icon: getAccountIcon("Payable"),
        accounts: accountsPayable,
      });
    }

    return result.concat(mainTypeGroups);
  }, [accounts]);

  const updatedGroupedAccounts = useMemo(() => {
    return groupedAccounts
      .map((group) => ({
        ...group,
        accounts: group.accounts
          .filter((account) => account.detailType !== "Stock")
          .map((account) => {
            const accountDetails = accountDetailsMap.get(account.id) || {
              debit: 0,
              credit: 0,
            };
            return {
              ...account,
              debit: accountDetails.debit || null,
              credit: accountDetails.credit || null,
            };
          }),
      }))
      .filter((group) => group.accounts.length > 0);
  }, [groupedAccounts, accountDetailsMap]);

  console.log(groupedAccounts);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsBalanceTableVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    const target = document.getElementById("balance-table");
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  useMemo(() => {
    const initialValues = {
      branch: record?.branch?.id || selectedBranchId,
      migrationDate: dayjs(record?.migrationDate || business?.migrationDate),
    };
    if (record) {
      record.details?.forEach((detail) => {
        initialValues[`debit${detail.account.id}`] = detail.debit || null;
        initialValues[`credit${detail.account.id}`] = detail.credit || null;
      });
      console.log("initial values", initialValues);
    }
    form.setFieldsValue(initialValues);
  }, [record, form, business, selectedBranchId]);

  // Calculate initial totals
  useMemo(() => {
    let debitsTotal = 0;
    let creditsTotal = 0;
    console.log("UPDATED TOTALS!!!!!!!!!");
    updatedGroupedAccounts.forEach((group) => {
      group.accounts.forEach((account) => {
        const debitValue = account.debit || 0;
        const creditValue = account.credit || 0;

        debitsTotal += parseFloat(debitValue);
        creditsTotal += parseFloat(creditValue);
      });
    });

    setTotalDebits(debitsTotal);
    setTotalCredits(creditsTotal);
    setObAdjustments(creditsTotal - debitsTotal);
  }, [updatedGroupedAccounts]);

  const updateTotals = useCallback(() => {
    let debitsTotal = 0;
    let creditsTotal = 0;

    // Iterate over grouped accounts and accumulate totals
    groupedAccounts.forEach((group) => {
      group.accounts.forEach((account) => {
        // Retrieve values from the form
        const debitValue =
          parseFloat(form.getFieldValue(`debit${account.id}`)) || 0;
        const creditValue =
          parseFloat(form.getFieldValue(`credit${account.id}`)) || 0;

        // Accumulate totals
        debitsTotal += debitValue;
        creditsTotal += creditValue;
      });
    });

    setTotalDebits(debitsTotal);
    setTotalCredits(creditsTotal);
    setObAdjustments(creditsTotal - debitsTotal);
  }, [form, groupedAccounts]);

  const handleDebitBlur = (accountId) => (e) => {
    const debitValue = parseFloat(e.target.value || 0);

    if (debitValue > 0) {
      form.setFieldsValue({ [`credit${accountId}`]: null });
    }

    updateTotals();
  };

  const handleCreditBlur = (accountId) => (e) => {
    const creditValue = parseFloat(e.target.value || 0);

    if (creditValue > 0) {
      form.setFieldsValue({ [`debit${accountId}`]: null });
    }

    updateTotals();
  };

  const handleSubmit = async () => {
    try {
      console.log("Submitting form...");
      const values = await form.validateFields();
      const details = [];

      groupedAccounts.forEach((group) => {
        group.accounts.forEach((account) => {
          const debitValue = form.getFieldValue(`debit${account.id}`);
          const creditValue = form.getFieldValue(`credit${account.id}`);

          if (debitValue > 0 || creditValue > 0) {
            details.push({
              accountId: account.id,
              debit: debitValue ? parseFloat(debitValue) : 0,
              credit: creditValue ? parseFloat(creditValue) : 0,
            });
          }
        });
      });

      const input = {
        branchId: values.branch,
        migrationDate: values.migrationDate, //temp
        details,
      };

      console.log("Form values:", input);
      await updateOpeningBalance({ variables: { input: input } });
      console.log("Mutation completed");
    } catch (err) {
      openErrorNotification(notiApi, err.message);
    }
  };
  console.log("updated group accounts", updatedGroupedAccounts);
  return (
    <>
      <div className="page-header">
        <p className="page-header-text">Opening Balances Edit</p>
        <Button
          icon={<CloseOutlined />}
          type="text"
          onClick={() =>
            navigate(from, { state: location.state, replace: true })
          }
        />
      </div>
      <div className="ob-page-content page-content-with-padding">
        <div className="ob-page-form-wrapper">
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              label={
                <FormattedMessage id="label.branch" defaultMessage="Branch" />
              }
              name="branch"
              labelAlign="left"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 7 }}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="label.branch.required"
                      defaultMessage="Select the Branch"
                    />
                  ),
                },
              ]}
            >
              <Select showSearch optionFilterProp="label" disabled>
                {branches?.map((branch) => (
                  <Select.Option
                    key={branch.id}
                    value={branch.id}
                    label={branch.name}
                  >
                    {branch.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage
                  id="label.migrationDate"
                  defaultMessage="Migration Date"
                />
              }
              name="migrationDate"
              labelAlign="left"
              labelCol={{ span: 3 }}
              wrapperCol={{ span: 7 }}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="label.migrationDate.required"
                      defaultMessage="Select the Migration Date"
                    />
                  ),
                },
              ]}
            >
              <DatePicker format={REPORT_DATE_FORMAT} allowClear={false} />
            </Form.Item>
            <Divider />
            <Collapse
              // size="large"
              style={{
                background: token.colorBgContainer,
              }}
              className="custom-collapse"
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition="end"
            >
              {updatedGroupedAccounts.map((group, index) => (
                <Collapse.Panel
                  header={
                    <Space>
                      <Flex align="center">{group.icon}</Flex>
                      <h4 style={{ margin: 0 }}>{group.mainType}</h4>
                    </Space>
                  }
                  key={index + 1}
                >
                  <Row
                    className="account-value-row"
                    style={{ marginBottom: "1.5rem" }}
                  >
                    <Col span={9} className="account-value-box">
                      <label>ACCOUNTS</label>
                    </Col>
                    <Col
                      span={4}
                      className="account-value-box"
                      style={{ textAlign: "center" }}
                    >
                      <label>AVAILABLE BALANCE</label>
                    </Col>
                    <Col
                      span={5}
                      className="account-value-box text-align-right"
                    >
                      <label>DEBIT ({business?.baseCurrency?.symbol})</label>
                    </Col>
                    <Col
                      span={5}
                      className="account-value-box text-align-right"
                    >
                      <label className="">
                        CREDIT ({business?.baseCurrency?.symbol})
                      </label>
                    </Col>
                  </Row>
                  {group.accounts.map((account) => (
                    <Row key={account.id}>
                      <Col span={9} className="account-value-box">
                        <p key={account.id} style={{ marginTop: 0 }}>
                          {account.name}
                        </p>
                      </Col>
                      <Col
                        span={4}
                        className="account-value-box"
                        style={{ textAlign: "center" }}
                      >
                        {account.debit || account.credit ? (
                          account.debit > 0 ? (
                            <div>
                              <FormattedNumber
                                value={account.debit || 0}
                                style="decimal"
                                minimumFractionDigits={
                                  business?.currency?.decimalPlaces
                                }
                              />{" "}
                              <span className="dr-cr-tag">Dr</span>
                            </div>
                          ) : (
                            <div>
                              <FormattedNumber
                                value={account.credit || 0}
                                style="decimal"
                                minimumFractionDigits={
                                  business?.currency?.decimalPlaces
                                }
                              />{" "}
                              <span className="dr-cr-tag">Cr</span>
                            </div>
                          )
                        ) : (
                          "-"
                        )}
                      </Col>
                      <Col span={5} className="account-value-box">
                        <Form.Item
                          name={`debit${account.id}`}
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.resolve();
                                } else if (isNaN(value) || value.length > 20) {
                                  return Promise.reject(
                                    intl.formatMessage({
                                      id: "validation.invalidInput",
                                      defaultMessage: "Invalid Input",
                                    })
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            style={{ textAlign: "right" }}
                            onBlur={handleDebitBlur(account.id)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5} className="account-value-box">
                        <Form.Item
                          name={`credit${account.id}`}
                          rules={[
                            () => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.resolve();
                                } else if (isNaN(value) || value.length > 20) {
                                  return Promise.reject(
                                    intl.formatMessage({
                                      id: "validation.invalidInput",
                                      defaultMessage: "Invalid Input",
                                    })
                                  );
                                } else {
                                  return Promise.resolve();
                                }
                              },
                            }),
                          ]}
                        >
                          <Input
                            style={{ textAlign: "right" }}
                            onBlur={handleCreditBlur(account.id)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </Collapse.Panel>
              ))}
            </Collapse>
            <Row className="new-manual-journal-table-footer">
              {/* <Col span={1}></Col> */}
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <table
                  cellSpacing="0"
                  border="0"
                  id="balance-table"
                  style={{
                    background: "rgba(245, 157, 0, 0.10)",
                    width: "100%",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{ verticalAlign: "middle", width: "30%" }}
                        className="text-align-right"
                      >
                        <FormattedMessage
                          id="label.total"
                          defaultMessage="Total"
                        />
                      </td>
                      <td
                        className="text-align-right"
                        style={{
                          paddingTop: "0.5rem",
                          width: "18%",
                        }}
                      >
                        {totalDebits}
                      </td>
                      <td
                        className="text-align-right"
                        style={{
                          paddingTop: "0.5rem",
                          width: "15%",
                          paddingRight: "1.2rem",
                        }}
                      >
                        {totalCredits}
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-align-right"
                        style={{ paddingTop: "0.5rem", color: "var(--red)" }}
                      >
                        <FormattedMessage
                          id="label.openingBalanceAdjustments"
                          defaultMessage="Opening Balance Adjustments"
                        />
                      </td>
                      <td
                        className="text-align-right"
                        style={{
                          paddingTop: "0.5rem",
                          color: "var(--red)",
                        }}
                      >
                        {obAdjustments > 0 && obAdjustments}
                      </td>
                      <td
                        className="text-align-right"
                        style={{
                          paddingTop: "0.5rem",
                          color: "var(--red)",
                          paddingRight: "1.2rem",
                        }}
                      >
                        {obAdjustments <= 0 && -obAdjustments}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-right">
                        <div
                          style={{
                            fontSize: "var(--small-text)",
                            opacity: "70%",
                          }}
                        >
                          This account will hold the difference in credit and
                          debit.
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <Divider style={{ margin: 0, marginTop: "0.8rem" }} />
                      </td>
                    </tr>
                    <tr>
                      <td
                        className="text-align-right"
                        style={{ paddingTop: "0.5rem" }}
                      >
                        <b>
                          <FormattedMessage
                            id="label.totalAmount"
                            defaultMessage="Total Amount"
                          />
                        </b>
                      </td>
                      <td
                        className="text-align-right"
                        style={{ paddingTop: "0.5rem" }}
                      >
                        <b>
                          <FormattedNumber
                            value={
                              totalCredits > totalDebits
                                ? totalCredits
                                : totalDebits || 0
                            }
                            style="decimal"
                            minimumFractionDigits={
                              business?.currency?.decimalPlaces
                            }
                          />
                        </b>
                      </td>
                      <td
                        className="text-align-right"
                        style={{ paddingTop: "0.5rem", paddingRight: "1.2rem" }}
                      >
                        <b>
                          <FormattedNumber
                            value={
                              totalCredits > totalDebits
                                ? totalCredits
                                : totalDebits || 0
                            }
                            style="decimal"
                            minimumFractionDigits={
                              business?.currency?.decimalPlaces
                            }
                          />
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-align-right">
                        <div
                          style={{
                            fontSize: "var(--small-text)",
                            opacity: "70%",
                          }}
                        >
                          <b>Opening Balance Adjustment account</b>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
            <div className="page-actions-bar page-actions-bar-margin ob-actions-bar">
              <Button
                loading={updateLoading}
                type="primary"
                htmlType="submit"
                className="page-actions-btn"
              >
                <FormattedMessage id="button.save" defaultMessage="Save" />
              </Button>
              <Button
                loading={updateLoading}
                className="page-actions-btn"
                onClick={() =>
                  navigate(from, { state: location.state, replace: true })
                }
              >
                <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
              </Button>
              {!isBalanceTableVisible && (
                <Flex className="ob-total-floater">
                  <span style={{ wordBreak: "keep-all", color: "var(--red)" }}>
                    Opening Balance Adjustments:{" "}
                  </span>
                  <span style={{ marginRight: "1rem" }}>
                    <FormattedNumber
                      value={
                        obAdjustments > 0 ? obAdjustments : -obAdjustments || 0
                      }
                      style="decimal"
                      minimumFractionDigits={business?.currency?.decimalPlaces}
                    />{" "}
                    <span className="dr-cr-tag">
                      {obAdjustments > 0 ? "Dr" : "Cr"}
                    </span>
                  </span>
                  <span style={{ wordBreak: "keep-all" }}>TOTAL AMOUNT: </span>
                  <span>
                    <FormattedNumber
                      value={
                        totalCredits > totalDebits
                          ? totalCredits
                          : totalDebits || 0
                      }
                      style="decimal"
                      minimumFractionDigits={business?.currency?.decimalPlaces}
                    />
                  </span>
                </Flex>
              )}
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default OpeningBalancesEdit;
