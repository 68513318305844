import React from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

const MenuItemWithPlus = ({ label, path }) => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="menu-item-add-new">
      {label}
      <PlusCircleFilled
        onClick={(event) => {
          event.stopPropagation();
          navigate(path, {
            state: {
              ...location.state,
              from: { pathname: location.pathname },
            },
          });
        }}
      />
    </div>
  );
};

export default MenuItemWithPlus;
