import React, { useEffect, useMemo, useState } from "react";
import { HistoryOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Form,
  Button,
  DatePicker,
  Flex,
  Input,
  Skeleton,
  Timeline,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import {
  openErrorNotification,
  openSuccessMessage,
} from "../../utils/Notification";
import { useOutletContext } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  BusinessMutations,
  BusinessQueries,
  HistoryQueries,
} from "../../graphql";
import { REPORT_DATE_FORMAT } from "../../config/Constants";
import DOMPurify from "dompurify";
import { ReactComponent as FileHistoryOutlined } from "../../assets/icons/FileHistoryOutlined.svg";

const { UPDATE_TRANSACTION_LOCKING } = BusinessMutations;
const { GET_TRANSACTION_LOCKING_RECORD } = BusinessQueries;
const { GET_HISTORIES } = HistoryQueries;

const TransactionLocking = () => {
  const [formRef] = Form.useForm();
  const [hisColumnOpen, setHisColumnOpen] = useState(false);
  const { notiApi, msgApi, business, refetchBusiness } = useOutletContext();

  // Queries
  const {
    data: hisData,
    loading: hisLoading,
    refetch: hisRefetch,
  } = useQuery(GET_TRANSACTION_LOCKING_RECORD, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError: (err) => openErrorNotification(notiApi, err.message),
    skip: !hisColumnOpen,
  });

  //Mutations
  const [updateTransactionLocking, { loading: updateLoading }] = useMutation(
    UPDATE_TRANSACTION_LOCKING,
    {
      async onCompleted() {
        await refetchBusiness();
        openSuccessMessage(
          msgApi,
          <FormattedMessage
            id="transactionLocking.updated"
            defaultMessage="Transaction Locking Updated"
          />
        );
      },
    }
  );

  useEffect(() => {
    if (hisColumnOpen) {
      hisRefetch();
    }
  }, [hisColumnOpen, hisRefetch]);

  const histories = useMemo(
    () => hisData?.listTransactionLockingRecord || [],
    [hisData]
  );

  const loading = updateLoading;

  const handleSave = async () => {
    try {
      const values = await formRef.validateFields();
      const input = {
        ...values,
      };
      // console.log("Field values:", values);
      await updateTransactionLocking({
        variables: { input },
      });
    } catch (err) {
      openErrorNotification(notiApi, err.message);
    }
  };

  useMemo(() => {
    // console.log(data);
    const profile = business;
    if (profile) {
      formRef.setFieldsValue({
        salesTransactionLockDate: dayjs(profile.salesTransactionLockDate),
        purchaseTransactionLockDate: dayjs(profile.purchaseTransactionLockDate),
        bankingTransactionLockDate: dayjs(profile.bankingTransactionLockDate),
        accountantTransactionLockDate: dayjs(
          profile.accountantTransactionLockDate
        ),
      });
    }
  }, [formRef, business]);

  return (
    <div style={{ position: "relative" }}>
      <div className="page-header">
        <Flex align="center" gap={"1rem"} jus>
          <p className="page-header-text">
            <FormattedMessage
              id="menu.transactionLocking"
              defaultMessage="Transaction Locking"
            />
          </p>
        </Flex>
        <div>
          <Button
            type="text"
            icon={<HistoryOutlined />}
            onClick={setHisColumnOpen}
          >
            <span>
              <FormattedMessage
                id="button.lockingHistory"
                defaultMessage="Locking History"
              />
            </span>
          </Button>
        </div>
      </div>
      <div className="page-content page-content-with-padding page-content-with-form-buttons">
        <Form form={formRef} onFinish={handleSave}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="label.bankingLockDate"
                    defaultMessage="Banking Lock Date"
                  />
                }
                name="bankingTransactionLockDate"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="label.date.required"
                        defaultMessage="Select the Date"
                      />
                    ),
                  },
                ]}
              >
                <DatePicker format={REPORT_DATE_FORMAT}></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="label.salesLockDate"
                    defaultMessage="Sales Lock Date"
                  />
                }
                name="salesTransactionLockDate"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="label.date.required"
                        defaultMessage="Select the Date"
                      />
                    ),
                  },
                ]}
              >
                <DatePicker format={REPORT_DATE_FORMAT}></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="label.purchaseLockDate"
                    defaultMessage="Purchase Lock Date"
                  />
                }
                name="purchaseTransactionLockDate"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="label.date.required"
                        defaultMessage="Select the Date"
                      />
                    ),
                  },
                ]}
              >
                <DatePicker format={REPORT_DATE_FORMAT}></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage
                    id="label.accountantLockDate"
                    defaultMessage="Accountant Lock Date"
                  />
                }
                name="accountantTransactionLockDate"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="label.date.required"
                        defaultMessage="Select the Date"
                      />
                    ),
                  },
                ]}
              >
                <DatePicker format={REPORT_DATE_FORMAT}></DatePicker>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage id="label.reason" defaultMessage="Reason" />
                }
                name="reason"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 6 }}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id="label.reason.required"
                        defaultMessage="Enter the Reason"
                      />
                    ),
                  },
                ]}
              >
                <Input.TextArea maxLength={1000} rows="4"></Input.TextArea>
              </Form.Item>
            </Col>
          </Row>

          {/* <br/>
          <Row>
            <FormattedMessage id="message.accountingDiscrepanciesDetected" defaultMessage="Accounting Discrepancies Detected. Please press reconcile button." />
          </Row>
          <Row>
            <Button
              loading={loading}
              type="primary"
              className="page-actions-btn"
            >
              <FormattedMessage id="button.reconcile" defaultMessage="Reconcile" />
            </Button>
          </Row> */}
          <div className="page-actions-bar page-actions-bar-margin">
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="page-actions-btn"
            >
              <FormattedMessage id="button.save" defaultMessage="Save" />
            </Button>
          </div>
        </Form>
      </div>
      <div className={`cmt-column ${hisColumnOpen ? "open" : ""}`}>
        <div className="cmt-column-header">
          <div className="title">Locking History</div>
          <Button
            icon={<CloseOutlined />}
            type="text"
            onClick={() => setHisColumnOpen(false)}
          />
        </div>
        <div className="cmt-column-body">
          {hisLoading ? (
            <Skeleton active />
          ) : histories.length > 0 ? (
            <div style={{ marginLeft: "0.5rem" }}>
              <Timeline>
                {histories.map((item) => (
                  <Timeline.Item
                    key={item.id}
                    dot={
                      <Flex
                        align="center"
                        justify="center"
                        className="circle-box"
                      >
                        <span style={{ textAlign: "center", paddingTop: 2 }}>
                          <FileHistoryOutlined
                            style={{
                              color: "var(--yellow)",
                              width: "14px",
                              height: "14px",
                            }}
                          />
                        </span>
                      </Flex>
                    }
                  >
                    <Flex align="center" gap="0.25rem" className="cmt-username">
                      <div
                        className="text-ellipsis"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.userName),
                        }}
                      ></div>
                      <span style={{ opacity: "70%" }}>
                        <b>•</b>
                      </span>
                      <span
                        style={{
                          fontSize: "0.688rem",
                          opacity: "70%",
                          letterSpacing: ".2px",
                          fontWeight: 500,
                        }}
                      >
                        {dayjs(item.createdAt).format(
                          REPORT_DATE_FORMAT + " h:mm A"
                        )}
                      </span>
                    </Flex>
                    <div className="cmt-box" style={{ gap: "1rem" }}>
                      {/* <Flex className="cmt-description" gap="0.5rem">
                        <div style={{ opacity: "80%" }}>Banking Lock Date:</div>
                        <div>
                          {dayjs(item.bankingTransactionLockDate).format(
                            REPORT_DATE_FORMAT
                          )}
                        </div>
                      </Flex>
                      <Flex className="cmt-description" gap="0.5rem">
                        <div style={{ opacity: "80%" }}>Sales Lock Date:</div>
                        <div>
                          {dayjs(item.salesTransactionLockDate).format(
                            REPORT_DATE_FORMAT
                          )}
                        </div>
                      </Flex>
                      <Flex className="cmt-description" gap="0.5rem">
                        <div style={{ opacity: "80%" }}>
                          Purchase Lock Date:
                        </div>
                        <div>
                          {dayjs(item.purchaseTransactionLockDate).format(
                            REPORT_DATE_FORMAT
                          )}
                        </div>
                      </Flex>
                      <Flex className="cmt-description" gap="0.5rem">
                        <div style={{ opacity: "80%" }}>Account Lock Date:</div>
                        <div>
                          {dayjs(item.accountTransactionLockDate).format(
                            REPORT_DATE_FORMAT
                          )}
                        </div>
                      </Flex> */}
                      <Flex className="cmt-description" gap="0.5rem">
                        <div style={{ opacity: "80%" }}>Reason:</div>
                        <div>{item.reason}</div>
                      </Flex>
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          ) : (
            <Flex justify="center" align="center">
              No history yet!
            </Flex>
          )}
        </div>
      </div>
    </div>
  );
};

export default TransactionLocking;
