/* eslint-disable react/style-prop-object */
import React, { useMemo } from "react";
import { Spin, Flex, Divider } from "antd";
import { useQuery, useReadQuery } from "@apollo/client";
import { openErrorNotification } from "../../../utils/Notification";
import { Link, useOutletContext, useSearchParams } from "react-router-dom";
import { FormattedMessage, FormattedNumber } from "react-intl";
import moment from "moment";
import { ReportQueries } from "../../../graphql";
import {
  DATE_STRING_FORMAT,
  REPORT_DATE_FORMAT,
  REPORT_DEFAULTS,
} from "../../../config/Constants";
import { ReportFilterBar } from "../../../components";
import ReportLayout from "../ReportLayout";

const { GET_MOVEMENT_OF_EQUITY_REPORT } = ReportQueries;

const MovementOfEquity = () => {
  const { notiApi, business, allBranchesQueryRef } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDateParam = searchParams.get("fromDate");
  const toDateParam = searchParams.get("toDate");
  const branchIdParam = searchParams.get("branchId");
  const reportBasisParam = searchParams.get("reportBasis");
  const periodParam = searchParams.get("period");

  const fromDate = fromDateParam
    ? moment(fromDateParam).utc()
    : REPORT_DEFAULTS.fromDate;
  const toDate = toDateParam
    ? moment(toDateParam).utc()
    : REPORT_DEFAULTS.toDate;
  const branchId = branchIdParam ? parseInt(branchIdParam) : 0;
  const reportBasis = reportBasisParam ? reportBasisParam : "Accrual";
  const period = periodParam || "thisMonth";

  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_MOVEMENT_OF_EQUITY_REPORT, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      fromDate: fromDate.format(DATE_STRING_FORMAT),
      toDate: toDate.format(DATE_STRING_FORMAT),
      branchId,
      reportType: reportBasis,
    },
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const queryData = useMemo(() => data, [data]);

  // !queryLoading && console.log(queryData);

  const { data: branchData } = useReadQuery(allBranchesQueryRef);

  const branch = useMemo(() => {
    return branchData?.listAllBranch?.find((w) => w.id === branchId);
  }, [branchData, branchId]);

  return (
    <ReportLayout>
      <div className="report">
        <ReportFilterBar
          refetch={refetch}
          isPaginated={false}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          filterConfig={{
            branch: true,
          }}
        />
        <div className="rep-container">
          <div className="report-header">
            <h4>{business.name}</h4>
            <h3 style={{ marginTop: "-5px" }}>Movement Of Equity</h3>
            {/* <span>Basis: {reportBasis}</span> */}
            {branch && <span>Branch: {branch?.name}</span>}
            <h5>
              From {fromDate.format(REPORT_DATE_FORMAT)} To{" "}
              {toDate.format(REPORT_DATE_FORMAT)}
            </h5>
          </div>
        </div>
        {queryLoading ? (
          <Flex justify="center" align="center" style={{ height: "40vh" }}>
            <Spin size="large" />
          </Flex>
        ) : (
          <div className="fill-container table-container">
            <table className="financial-comparison rep-table tb-comparison-table table-no-border ">
              <thead>
                <tr>
                  <th className="text-align-left" style={{ width: "420px" }}>
                    <span>
                      <FormattedMessage
                        id="report.account"
                        defaultMessage="Account"
                      />
                    </span>
                  </th>
                  <th></th>
                  <th className="text-align-right" style={{ width: "176px" }}>
                    <FormattedMessage
                      id="report.total"
                      defaultMessage="Total"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="mute-hover">
                  <td>
                    <b>
                      <FormattedMessage
                        id="report.openingBalance"
                        defaultMessage="Opening Balance"
                      />
                    </b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={
                        queryData?.getMovementOfEquityReport?.[0]
                          ?.openingBalance || 0
                      }
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="2">
                    <b>Changes in Equity</b>
                  </td>
                </tr>
                {queryData?.getMovementOfEquityReport?.[0]?.accountGroups?.[0].accounts?.map(
                  (acc) => (
                    <tr key={acc.accountName}>
                      <td style={{ paddingLeft: "4.5rem" }}>
                        <Link
                          to={{
                            pathname:
                              acc.accountName === "Current Year Earnings"
                                ? "/reports/profitAndLoss"
                                : "/reports/accountTransactions",
                            search: `?reportBasis=${reportBasis}&period=${
                              acc.accountName === "Current Year Earnings"
                                ? "thisYear"
                                : period
                            }&fromDate=${
                              fromDateParam
                                ? fromDateParam
                                : REPORT_DEFAULTS.fromDate
                            }&toDate=${
                              toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                            }&branchId=${branchId}&searchCriteria=account%3D${
                              acc.accountID
                            }`,
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {acc.accountName}
                        </Link>
                      </td>
                      <td></td>
                      <td className="text-align-right">
                        <Link
                          to={{
                            pathname:
                              acc.accountName === "Current Year Earnings"
                                ? "/reports/profitAndLoss"
                                : "/reports/accountTransactions",
                            search: `?reportBasis=${reportBasis}&period=${
                              acc.accountName === "Current Year Earnings"
                                ? "thisYear"
                                : period
                            }&fromDate=${
                              fromDateParam
                                ? fromDateParam
                                : REPORT_DEFAULTS.fromDate
                            }&toDate=${
                              toDateParam ? toDateParam : REPORT_DEFAULTS.toDate
                            }&branchId=${branchId}&searchCriteria=account%3D${
                              acc.accountID
                            }`,
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FormattedNumber
                            value={acc.amount}
                            style="decimal"
                            minimumFractionDigits={
                              business.baseCurrency.decimalPlaces
                            }
                          />
                        </Link>
                      </td>
                    </tr>
                  )
                )}
                <tr className="mute-hover">
                  <td>
                    <b>Net Changes in Equity</b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    {queryData?.getMovementOfEquityReport?.[0]
                      ?.accountGroups?.[0].total ? (
                      <FormattedNumber
                        value={
                          queryData?.getMovementOfEquityReport?.[0]
                            ?.accountGroups?.[0].total
                        }
                        style="decimal"
                        minimumFractionDigits={
                          business.baseCurrency.decimalPlaces
                        }
                      />
                    ) : (
                      "0"
                    )}
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td colSpan="3" style={{ padding: 0 }}>
                    <Divider style={{ margin: 0 }} />
                  </td>
                </tr>
                <tr className="mute-hover">
                  <td>
                    <b>
                      <FormattedMessage
                        id="report.closingBalance"
                        defaultMessage="Closing Balance"
                      />
                    </b>
                  </td>
                  <td></td>
                  <td className="text-align-right">
                    <FormattedNumber
                      value={
                        queryData?.getMovementOfEquityReport?.[0]
                          ?.closingBalance || 0
                      }
                      style="decimal"
                      minimumFractionDigits={
                        business.baseCurrency.decimalPlaces
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div style={{ paddingLeft: "1.5rem" }}>
          <FormattedMessage
            values={{ currency: business.baseCurrency.symbol }}
            id="label.displayedBaseCurrency"
            defaultMessage="**Amount is displayed in {currency}"
          />
        </div>
      </div>
    </ReportLayout>
  );
};

export default MovementOfEquity;
