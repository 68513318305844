import { gql } from "@apollo/client";

const GET_BUSINESS = gql`
  query GetBusiness {
    getBusiness {
      id
      logoUrl
      name
      contactName
      email
      phone
      mobile
      website
      about
      address
      country
      city
      state {
        id
        code
        stateNameEn
      }
      township {
        id
        code
        stateCode
        townshipNameEn
      }
      baseCurrency {
        id
        name
        symbol
        decimalPlaces
      }
      fiscalYear
      reportBasis
      salesTransactionLockDate
      purchaseTransactionLockDate
      bankingTransactionLockDate
      accountantTransactionLockDate
      timezone
      companyId
      taxId
      isTaxInclusive
      isTaxExclusive
      migrationDate
      primaryBranch {
        id
        name
      }
      isActive
    }
  }
`;

const GET_TRANSACTION_LOCKING_RECORD = gql`
  query ListTransactionLockingRecord {
    listTransactionLockingRecord {
      id
      businessId
      salesTransactionLockDate
      purchaseTransactionLockDate
      bankingTransactionLockDate
      accountantTransactionLockDate
      reason
      userId
      userName
      createdAt
    }
  }
`;

const BusinessQueries = {
  GET_BUSINESS,
  GET_TRANSACTION_LOCKING_RECORD,
};

export default BusinessQueries;
