import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "@apollo/client";
import { Flex, Spin, Table, Tabs, Tag } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { openErrorNotification } from "../utils/Notification";
import { AccountQueries } from "../graphql";
import { FormattedNumber } from "react-intl";
const { GET_ACCOUNT_JOURNAL_TRANSACTIONS } = AccountQueries;

const AccountJournalDisplay = ({
  selectedRecord,
  referenceType,
  title,
  message,
  accordion = false,
}) => {
  const { notiApi, business } = useOutletContext();
  const [collapsed, setCollapsed] = useState(true);

  //Queries
  const { data, loading, refetch } = useQuery(
    GET_ACCOUNT_JOURNAL_TRANSACTIONS,
    {
      errorPolicy: "all",
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
      variables: {
        rId: selectedRecord?.id,
        rType: referenceType,
      },
      onError(err) {
        openErrorNotification(notiApi, err.message);
      },
      skip: accordion && collapsed,
    }
  );

  useEffect(() => {
    if (!collapsed) {
      refetch();
    }
  }, [collapsed, refetch]);

  const parseData = useMemo(() => {
    return data?.getAccountJournalTransactions;
  }, [data]);

  const totalBaseDebit = useMemo(() => {
    return parseData?.reduce((total, item) => total + item.baseDebit, 0);
  }, [parseData]);

  const totalBaseCredit = useMemo(() => {
    return parseData?.reduce((total, item) => total + item.baseCredit, 0);
  }, [parseData]);

  const columns = [
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      render: (_, record) => record.account?.name,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (_, record) => record.branch?.name,
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      align: "right",
      render: (_, record) => (
        <FormattedNumber
          value={record.baseDebit}
          style="decimal"
          minimumFractionDigits={business?.baseCurrency?.decimalPlaces}
        />
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "right",
      render: (_, record) => (
        <FormattedNumber
          value={record.baseCredit}
          style="decimal"
          minimumFractionDigits={business?.baseCurrency?.decimalPlaces}
        />
      ),
    },
  ];

  return (
    <div style={{ marginBottom: "1rem" }}>
      {accordion ? (
        <div>
          <Flex
            justify="space-between"
            align="center"
            style={{
              padding: "0.625rem",
              borderBlock: "1px solid var(--border-color)",
              cursor: "pointer",
            }}
            onClick={() => setCollapsed(!collapsed)}
          >
            <span>
              <b>Display Journal</b>
            </span>
            <span style={{ fontSize: "12px" }}>
              <RightOutlined
                style={{
                  transform: `rotate(${!collapsed ? 90 : 0}deg)`,
                  transition: "0.2s",
                }}
              />
            </span>
          </Flex>
          <div className={`acc-journal-accordion ${!collapsed ? "open" : ""}`}>
            {parseData ? (
              <>
                <p>
                  <span
                    style={{
                      fontSize: "var(--small-text)",
                      opacity: "70%",
                    }}
                  >
                    Amount is displayed in your base currency{" "}
                  </span>

                  <Tag
                    color="var(--primary-color)"
                    style={{
                      fontSize: "9.75px",
                      padding: "3.25px 5.2px",
                      lineHeight: "normal",
                      fontWeight: 600,
                    }}
                  >
                    {business?.baseCurrency?.symbol}
                  </Tag>
                </p>
                <Table
                  className="journal-table"
                  columns={columns}
                  dataSource={parseData}
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="right">
                        <b><FormattedNumber
                          value={totalBaseDebit}
                          style="decimal"
                          minimumFractionDigits={business?.baseCurrency?.decimalPlaces}
                        /></b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="right">
                        <b><FormattedNumber
                          value={totalBaseCredit}
                          style="decimal"
                          minimumFractionDigits={business?.baseCurrency?.decimalPlaces}
                        /></b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  pagination={false}
                />
              </>
            ) : (
              <div style={{ height: "2rem" }}>
                No journal entries available.
              </div>
            )}
          </div>
        </div>
      ) : (
        <Tabs style={{ paddingInline: "1.5rem" }}>
          <Tabs.TabPane
            tab="Journal"
            key="journal"
            style={{ paddingTop: "1rem" }}
          >
            {loading ? (
              <Flex justify="center" align="center" style={{ height: "4rem" }}>
                <Spin />
              </Flex>
            ) : parseData?.length > 0 ? (
              <>
                <div style={{ marginBottom: "0.625rem" }}>
                  <span
                    style={{
                      fontSize: "var(--small-text)",
                      opacity: "70%",
                    }}
                  >
                    Amount is displayed in your base currency{" "}
                  </span>
                  <Tag
                    color="var(--primary-color)"
                    style={{
                      fontSize: "9.75px",
                      padding: "3.25px 5.2px",
                      lineHeight: "normal",
                      fontWeight: 600,
                    }}
                  >
                    {business?.baseCurrency?.symbol}
                  </Tag>
                </div>
                <p
                  style={{
                    marginTop: 0,
                    fontWeight: "bold",
                    fontSize: "var(--big-text)",
                  }}
                >
                  {title}
                </p>

                <Table
                  className="journal-table"
                  columns={columns}
                  dataSource={parseData}
                  summary={() => (
                    <Table.Summary.Row>
                      <Table.Summary.Cell
                        index={0}
                        colSpan={2}
                      ></Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align="right">
                        <b><FormattedNumber
                          value={totalBaseDebit}
                          style="decimal"
                          minimumFractionDigits={business?.baseCurrency?.decimalPlaces}
                        /></b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align="right">
                        <b><FormattedNumber
                          value={totalBaseCredit}
                          style="decimal"
                          minimumFractionDigits={business?.baseCurrency?.decimalPlaces}
                        /></b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  )}
                  pagination={false}
                />
              </>
            ) : selectedRecord?.currentStatus === "Draft" ||
              selectedRecord?.currentStatus === "Void" ? (
              <div style={{ height: "4rem" }}>
                Journal entries will not be available for {message} in the{" "}
                {selectedRecord?.currentStatus} state.
              </div>
            ) : (
              <div style={{ height: "4rem" }}>
                No journal entries available.
              </div>
            )}
          </Tabs.TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default AccountJournalDisplay;
