import React, { useState, useMemo } from "react";
import { Button, Table, Dropdown, Tag, Modal, Form, Input, Select } from "antd";
import { PlusOutlined, DownCircleFilled } from "@ant-design/icons";
import { useQuery, useMutation, useReadQuery } from "@apollo/client";
import {
  openErrorNotification,
  openSuccessMessage,
} from "../../utils/Notification";
import { useOutletContext } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { UserMutations, UserQueries, RoleQueries } from "../../graphql";

const { GET_USER_ACCOUNTS } = UserQueries;
const { GET_ROLES } = RoleQueries;
const {
  CREATE_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  TOGGLE_ACTIVE_USER_ACCOUNT,
} = UserMutations;

const Users = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [deleteModal, contextHolder] = Modal.useModal();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [createFormRef] = Form.useForm();
  const [editFormRef] = Form.useForm();
  const { notiApi, msgApi, allBranchesQueryRef } = useOutletContext();

  // Queries and mutations
  const { data, loading: queryLoading } = useQuery(GET_USER_ACCOUNTS, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const { data: roleData, loading: roleLoading } = useQuery(GET_ROLES, {
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError(err) {
      openErrorNotification(notiApi, err.message);
    },
  });

  const { data: branchData } = useReadQuery(allBranchesQueryRef);

  const [createUser, { loading: createLoading }] = useMutation(
    CREATE_USER_ACCOUNT,
    {
      onCompleted() {
        openSuccessMessage(
          msgApi,
          <FormattedMessage
            id="user.created"
            defaultMessage="New User Created"
          />
        );
      },
      refetchQueries: [GET_USER_ACCOUNTS],
    }
  );

  const [updateUser, { loading: updateLoading }] = useMutation(
    UPDATE_USER_ACCOUNT,
    {
      onCompleted() {
        openSuccessMessage(
          msgApi,
          <FormattedMessage id="user.updated" defaultMessage="User Updated" />
        );
      },
      refetchQueries: [GET_USER_ACCOUNTS],
    }
  );

  const [deleteUser, { loading: deleteLoading }] = useMutation(
    DELETE_USER_ACCOUNT,
    {
      onCompleted() {
        openSuccessMessage(
          msgApi,
          <FormattedMessage id="user.deleted" defaultMessage="User Deleted" />
        );
      },
      refetchQueries: [GET_USER_ACCOUNTS],
    }
  );

  const [toggleActiveUser, { loading: toggleActiveLoading }] = useMutation(
    TOGGLE_ACTIVE_USER_ACCOUNT,
    {
      onCompleted() {
        openSuccessMessage(
          msgApi,
          <FormattedMessage
            id="user.updated.status"
            defaultMessage="User Status Updated"
          />
        );
      },
      refetchQueries: [GET_USER_ACCOUNTS],
    }
  );

  const branches = useMemo(() => {
    return branchData?.listAllBranch?.filter(
      (branch) => branch.isActive === true
    );
  }, [branchData]);

  const roles = useMemo(() => {
    return roleData?.listRole?.filter((role) => role);
  }, [roleData]);

  // Derived data
  const parsedData = useMemo(() => {
    return data?.listUserAccount?.map((item) => ({
      ...item,
    }));
  }, [data]);

  const loading =
    queryLoading ||
    createLoading ||
    updateLoading ||
    deleteLoading ||
    toggleActiveLoading;

  const handleCreateModalOk = async () => {
    try {
      const values = await createFormRef.validateFields();
      const input = {
        ...values,
        branches: values.branches?.join(" | "),
      };
      console.log(input);
      await createUser({ variables: { input: input } });
      setCreateModalOpen(false);
      createFormRef.resetFields();
      setCreateModalOpen(false);
    } catch (err) {
      openErrorNotification(notiApi, err.message);
    }
  };

  const handleCreateModalCancel = () => {
    setCreateModalOpen(false);
    createFormRef.resetFields();
  };

  const handleDelete = async (record) => {
    // console.log("delete", record.id);
    const confirmed = await deleteModal.confirm({
      content: (
        <FormattedMessage
          id="confirm.delete"
          defaultMessage="Are you sure to delete?"
        />
      ),
    });
    if (confirmed) {
      try {
        await deleteUser({
          variables: {
            userId: record.id,
          },
        });
      } catch (err) {
        openErrorNotification(notiApi, err.message);
      }
    }
  };

  const handleEdit = (record) => {
    setEditRecord(record);
    // console.log("edit record", editRecord);
    // console.log("record", record);
    editFormRef.resetFields();
    editFormRef.setFieldsValue({
      id: record.id,
      name: record.name,
      username: record.username,
      email: record.email,
      roleId: record.roleId,
      branches: record.branches ? record.branches?.split(" | ") : null,
      phone: record.phone,
      mobile: record.mobile,
    });
    // console.log(record.state);

    setEditModalOpen(true);
  };

  const handleEditModalOk = async () => {
    try {
      const values = await editFormRef.validateFields();
      const input = {
        ...values,
        branches: values.branches?.join(" | "),
        password: "",
      };
      // console.log("Field values:", values);
      await updateUser({
        variables: { id: editRecord.id, input },
      });

      setEditModalOpen(false);
      editFormRef.resetFields();
    } catch (err) {
      openErrorNotification(notiApi, err.message);
    }
  };

  const handleEditModalCancel = () => {
    setEditModalOpen(false);
  };

  const handleToggleActive = async (record) => {
    try {
      await toggleActiveUser({
        variables: { userId: record.id, isActive: !record.isActive },
      });
    } catch (err) {
      openErrorNotification(notiApi, err.message);
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="label.username" defaultMessage="Username" />,
      dataIndex: "username",
      key: "username",
      width: "23%",
      render: (text, record) => (
        <>
          {text}
          {!record.isActive ? (
            <Tag className="active-status">
              <FormattedMessage id="label.inactive" defaultMessage="inactive" />
            </Tag>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      title: <FormattedMessage id="label.name" defaultMessage="Name" />,
      dataIndex: "name",
      key: "name",
    },
    {
      title: <FormattedMessage id="user.role" defaultMessage="Role" />,
      dataIndex: "role",
      key: "role",
    },
    {
      title: <FormattedMessage id="user.email" defaultMessage="Email" />,
      dataIndex: "email",
      key: "email",
    },
    {
      title: <FormattedMessage id="label.phone" defaultMessage="Phone" />,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: <FormattedMessage id="label.branches" defaultMessage="Branches" />,
      dataIndex: "branches",
      key: "branches",
    },
    {
      title: "",
      dataIndex: "action",
      render: (_, record) =>
        hoveredRow === record.id ? (
          <Dropdown
            loading={loading}
            trigger="click"
            key={record.key}
            menu={{
              onClick: ({ key }) => {
                if (key === "1") handleEdit(record);
                else if (key === "2") handleToggleActive(record);
                else if (key === "3") handleDelete(record);
              },
              items: [
                {
                  label: (
                    <FormattedMessage id="button.edit" defaultMessage="Edit" />
                  ),
                  key: "1",
                },
                {
                  label: !record.isActive ? (
                    <FormattedMessage
                      id="button.markActive"
                      defaultMessage="Mark As Active"
                    />
                  ) : (
                    <FormattedMessage
                      id="button.markInactive"
                      defaultMessage="Mark As Inactive"
                    />
                  ),
                  key: "2",
                },
                {
                  label: (
                    <FormattedMessage
                      id="button.delete"
                      defaultMessage="Delete"
                    />
                  ),
                  key: "3",
                },
              ],
            }}
          >
            <DownCircleFilled className="action-icon" />
          </Dropdown>
        ) : (
          <div className="action-placeholder"></div>
        ),
    },
  ];

  const createForm = (
    <Form form={createFormRef} onFinish={handleCreateModalOk}>
      <Form.Item
        label={
          <FormattedMessage id="label.username" defaultMessage="Username" />
        }
        name="username"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="username.required"
                defaultMessage="Enter the Username"
              />
            ),
          },
        ]}
      >
        <Input maxLength={100}></Input>
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage id="label.password" defaultMessage="Password" />
        }
        name="password"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="password.required"
                defaultMessage="Enter the Password"
              />
            ),
          },
        ]}
      >
        <Input.Password maxLength={100}></Input.Password>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.name" defaultMessage="Name" />}
        name="name"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="name.required"
                defaultMessage="Enter the Name"
              />
            ),
          },
        ]}
      >
        <Input maxLength={100}></Input>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.email" defaultMessage="Email" />}
        name="email"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Input maxLength={200}></Input>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.role" defaultMessage="Role" />}
        name="roleId"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Select loading={roleLoading} optionFilterProp="label" allowClear>
          {roles?.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.branch" defaultMessage="Branch" />}
        name="branches"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Select
          loading={loading}
          optionFilterProp="label"
          mode="multiple"
          maxTagCount="responsive"
          allowClear
        >
          {branches?.map((branch) => (
            <Select.Option
              key={branch.id}
              value={branch.name}
              label={branch.name}
            >
              {branch.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.phone" defaultMessage="Phone" />}
        name="phone"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Input maxLength={20}></Input>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.mobile" defaultMessage="Mobile" />}
        name="mobile"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Input maxLength={20}></Input>
      </Form.Item>
    </Form>
  );

  const editForm = (
    <Form form={editFormRef} onFinish={handleEditModalOk}>
      <Form.Item
        label={
          <FormattedMessage id="label.username" defaultMessage="Username" />
        }
        name="username"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="username.required"
                defaultMessage="Enter the Username"
              />
            ),
          },
        ]}
      >
        <Input maxLength={100}></Input>
      </Form.Item>

      <Form.Item
        label={<FormattedMessage id="label.name" defaultMessage="Name" />}
        name="name"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage
                id="name.required"
                defaultMessage="Enter the Name"
              />
            ),
          },
        ]}
      >
        <Input maxLength={100}></Input>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.email" defaultMessage="Email" />}
        name="email"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Input maxLength={200}></Input>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.role" defaultMessage="Role" />}
        name="roleId"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Select loading={roleLoading} optionFilterProp="label" allowClear>
          {roles?.map((role) => (
            <Select.Option key={role.id} value={role.id}>
              {role.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.branch" defaultMessage="Branch" />}
        name="branches"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Select
          loading={loading}
          optionFilterProp="label"
          mode="multiple"
          maxTagCount="responsive"
          allowClear
        >
          {branches?.map((branch) => (
            <Select.Option
              key={branch.id}
              value={branch.name}
              label={branch.name}
            >
              {branch.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.phone" defaultMessage="Phone" />}
        name="phone"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Input maxLength={20}></Input>
      </Form.Item>
      <Form.Item
        label={<FormattedMessage id="label.mobile" defaultMessage="Mobile" />}
        name="mobile"
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 13 }}
      >
        <Input maxLength={20}></Input>
      </Form.Item>
    </Form>
  );

  return (
    <>
      {contextHolder}
      <Modal
        width="40rem"
        title={<FormattedMessage id="user.new" defaultMessage="New User" />}
        okText={<FormattedMessage id="button.save" defaultMessage="Save" />}
        cancelText={
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        }
        open={createModalOpen}
        onCancel={handleCreateModalCancel}
        onOk={createFormRef.submit}
      >
        {createForm}
      </Modal>
      <Modal
        width="40rem"
        title={<FormattedMessage id="user.edit" defaultMessage="Edit User" />}
        okText={<FormattedMessage id="button.save" defaultMessage="Save" />}
        cancelText={
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        }
        open={editModalOpen}
        onCancel={handleEditModalCancel}
        onOk={editFormRef.submit}
      >
        {editForm}
      </Modal>
      <div className="page-header page-header-with-button">
        <p className="page-header-text">
          <FormattedMessage id="menu.users" defaultMessage="Users" />
        </p>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => setCreateModalOpen(true)}
        >
          <span>
            <FormattedMessage id="user.new" defaultMessage="New User" />
          </span>
        </Button>
      </div>
      <div className="page-content">
        <Table
          className="main-table"
          loading={loading}
          columns={columns}
          dataSource={parsedData?.map((item) => ({ ...item, key: item.id }))}
          pagination={false}
          onRow={(record) => ({
            key: record.id,
            onMouseEnter: () => setHoveredRow(record.id),
            onMouseLeave: () => setHoveredRow(null),
          })}
        ></Table>
      </div>
    </>
  );
};

export default Users;
