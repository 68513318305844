import React, { useState } from "react";
import {
  FilePdfOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileWordOutlined,
  FileUnknownOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { Button, Modal, Upload } from "antd";
import "./AttachFiles.css";
import { FormattedMessage } from "react-intl";
// import { Document, Page } from "@react-pdf/renderer";
import { openErrorNotification } from "../utils/Notification";
import { useOutletContext } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { FileMutations } from "../graphql";
const { CREATE_ATTACHMENT, DELETE_ATTACHMENT } = FileMutations;

const AttachFiles = ({ files, referenceType, referenceId }) => {
  const { notiApi, msgApi } = useOutletContext();
  const [deleteModal, contextHolder] = Modal.useModal();

  const [createFile, { loading: createLoading }] = useMutation(
    CREATE_ATTACHMENT,
    {
      onCompleted() {},
    }
  );

  const [deleteFile, { loading: deleteLoading }] = useMutation(
    DELETE_ATTACHMENT,
    {
      onCompleted() {},
    }
  );

  const extractFilename = (url) => {
    return url?.substring(url?.lastIndexOf("/") + 1);
  };

  const initializeFileList = (files) => {
    return files?.map((file) => ({
      ...file,
      name: extractFilename(file?.documentUrl),
    }));
  };

  const [customFileList, setCustomFileList] = useState(
    files ? initializeFileList(files) : []
  );

  const getFileIcon = (fileExtension) => {
    console.log("exx", fileExtension);
    switch (fileExtension?.toLowerCase()) {
      case "pdf":
        return <FilePdfOutlined />;
      case "xlsx":
      case "xls":
        return <FileExcelOutlined />;
      case "doc":
      case "docx":
        return <FileWordOutlined />;
      case "png":
      case "jpg":
      case "jpeg":
        return <FileImageOutlined />;
      default:
        return <FileUnknownOutlined />;
    }
  };

  const getFileExtension = (url) => {
    return url?.split(".").pop();
  };

  const getFileType = (fileUrl) => {
    const extension = getFileExtension(fileUrl)?.toLowerCase();
    const imageExtensions = ["png", "jpg", "jpeg", "gif", "bmp", "tiff"];
    const documentExtensions = ["doc", "docx", "xls", "xlsx", "txt"];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else if (documentExtensions.includes(extension)) {
      return "document";
    } else {
      return "unknown";
    }
  };

  const beforeUpload = (file) => {
    const allowedTypes = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf",
    ];

    // if (!allowedTypes.includes(file.type)) {
    //   openErrorNotification(
    //     notiApi,
    //     "You can only upload MS Word, Excel, or PDF files!"
    //   );
    //   return false;
    // }

    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      openErrorNotification(notiApi, "File size must be smaller than 5MB!");
      return false;
    }
    return true;
  };

  const handleUpload = async (options) => {
    const { file, onSuccess, onError } = options;
    try {
      const { data } = await createFile({
        variables: { file, referenceType, referenceId },
      });
      console.log("file", file);
      const newFile = {
        uid: file.uid,
        name: file.name,
        status: "done",
        id: data?.createAttachment?.id,
        documentUrl: data?.createAttachment?.documentUrl,
      };

      const updatedFileList = [...customFileList, newFile];
      setCustomFileList(updatedFileList);

      msgApi.success(`Attachment Uploaded`);
      onSuccess(null, file);
    } catch (error) {
      openErrorNotification(notiApi, `File upload failed: ${error.message}`);
      onError(error);
    }
  };

  const handleDelete = async (id) => {
    console.log("id", id);
    const confirmed = await deleteModal.confirm({
      content: (
        <FormattedMessage
          id="confirm.delete"
          defaultMessage="Are you sure to delete?"
        />
      ),
    });

    if (confirmed) {
      try {
        await deleteFile({ variables: { documentId: id } });

        const updatedFileList = customFileList.filter((item) => item.id !== id);
        setCustomFileList(updatedFileList);

        msgApi.success(`Attachment Removed`);
      } catch (error) {
        openErrorNotification(
          notiApi,
          `Failed to remove attachment: ${error.message}`
        );
      }
    }
  };

  return (
    <>
      <div>
        <div>
          <Upload accept=".xlsx">
            <Button
              type="text"
              icon={
                <ImportOutlined style={{ color: "var(--primary-color)" }} />
              }
            >
              <span>Import Products</span>
            </Button>
          </Upload>
        </div>
      </div>
    </>
  );
};

export default AttachFiles;
